import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { connect, reset } from 'react-redux';
import { Button, ButtonRow } from 'merchant-portal-components';
import _ from 'lodash';
import * as actions from '../../actions';
import { Collapse, colors } from '@material-ui/core';
import ChevronUpIcon from '../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../components/Icon/ChevronDownIcon';
import RegularHoursContainer from './HoursDetails/RegularHours/RegularHoursContainer';
import SpecialHoursContainer from './HoursDetails/SpecialHours/SpecialHoursContainer';
import FilterButton from '../../../../../components/FilterModal/FilterButton';
import LocationFilterModalSingleSelect from '../../../../../components/FilterModal/LocationFilterModalSingleSelect';
const Card = styled.div`
  background-color: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  /* color: ${(props) => (props.disabled ? '#191818' : 'inherit')}; */
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
  padding: 20px 60px 20px 50px;
`;
const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;
export const DividerWrraper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;
export const Divider = styled.hr`
  width: 90%;
  border-top: 2px solid #e1e1e1;
`;
const Title = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
  color: ${(props) => (props.disabled ? '#191818' : 'inherit')};
  font-weight: 600;
  font-size: 21px;
`;
const ButtonRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px 150px;
  grid-gap: 20px;
  margin: 25px 0;
  width: 100%;
`;
const CopyHoursWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: ${(props) => props.margin || '15px 0'};
  gap: 15px;
`;
const CancelButtonWrapper = styled.span`
  display: flex;
  align-items: center;
`;
const CancelButton = styled.span`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #2440ae;
  &:hover {
    color: #2f4fcc;
  }
`;
const CopyButton = styled.button`
  font-family: Montserrat;
  width: 400px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  background-color: #2440ae;
  border: none;
  border-radius: 28px;
  &:hover {
    background-color: #2f4fcc;
  }
`;

const HoursContainer = ({
  businessId,
  regularHours,
  specialHours,
  formatedSpecialHours,
  getRegularHours,
  putRegularHours,
  getSpecialHours,
  postSpecialHour,
  patchSpecialHour,
  deleteSelectedSpecialHours,
  deactivateSection,
  copiedRegularHours,
  copiedSpecialHours,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBusinessForRegular, setSelectedBusinessForRegular] =
    useState();
  const [selectedBusinessForSpecial, setSelectedBusinessForSpecial] =
    useState();

  const [copyType, setCopyType] = useState('regular');
  const [isExpanded, setIsExpanded] = useState(false);
  const [regularHoursForm, setRegularHoursForm] = useState([]);
  const [specialHoursForm, setSpecialHoursForm] = useState([]);
  const updateHours = useCallback(() => {
    setRegularHoursForm(regularHours);
    setSpecialHoursForm(_.orderBy(specialHours, ['id'], ['asc']));
  }, [regularHours, specialHours, formatedSpecialHours]);
  useEffect(() => {
    updateHours();
  }, [updateHours]);
  const handleUpdateRegularHours = () => {
    putRegularHours(businessId, regularHoursForm);
  };
  const handleCopyLocationHoursToOrdering = () => {
    let newRegularHours = [];
    regularHoursForm.forEach((item) => {
      const modifiedItem = {
        ...item,
        order_start_time: item.open_time,
        order_end_time: item.close_time,
      };
      newRegularHours.push(modifiedItem);
    });
    setRegularHoursForm(newRegularHours);
  };
  const handleCancelHoursChange = () => {
    setRegularHoursForm(regularHours);
  };

  const handleOpenCopyModalForRegular = (isOpen) => {
    setCopyType('regular');
    setIsOpen(isOpen);
  };
  const handleGetRegularHours = (business) => {
    setSelectedBusinessForRegular(business);
    getRegularHours(business.id);
  };
  const handleCopyRegularHours = () => {
    let modifiedCopiedHours = [];
    copiedRegularHours.forEach((item) => {
      const newItem = { ...item, business_id: businessId };
      modifiedCopiedHours.push(newItem);
    });
    setRegularHoursForm(modifiedCopiedHours);
  };
  // Special
  const handleOpenCopyModalForSpecial = (isOpen) => {
    setCopyType('special');
    setIsOpen(isOpen);
  };
  const handleGetSepcialHours = (business) => {
    setSelectedBusinessForSpecial(business);
    getSpecialHours(business.id, true);
  };
  const handleCopySpecialHours = () => {
    let newSpecialHours = [];
    copiedSpecialHours.forEach((item) => {
      const hours = { ...item };
      delete hours.business_id;
      delete hours.id;
      newSpecialHours.push(hours);
    });
    postSpecialHour(businessId, newSpecialHours);
    getSpecialHours(businessId);
  };
  return (
    <Fragment>
      <Card disabled={deactivateSection}>
        <TitleAndCollapseButton>
          <Title
            style={{ textTransform: 'capitalize' }}
            disabled={deactivateSection}
          >
            Hours
          </Title>
          {!isExpanded ? (
            <ChevronDownIcon
              disabled={businessId && !deactivateSection ? false : true}
              style={{
                cursor: 'pointer',
                opacity: businessId && !deactivateSection ? 'none' : '0.5',
              }}
              onClick={() => {
                if (!deactivateSection) {
                  setIsExpanded(true);
                }
              }}
            />
          ) : (
            <ChevronUpIcon
              disabled={businessId && !deactivateSection ? false : true}
              style={{
                cursor: 'pointer',
                opacity: businessId && !deactivateSection ? 'none' : '0.5',
              }}
              onClick={() => {
                if (!deactivateSection) {
                  setIsExpanded(false);
                }
              }}
            />
          )}
        </TitleAndCollapseButton>
        <Collapse in={isExpanded}>
          <CopyHoursWrapper>
            <FilterButton
              message={
                selectedBusinessForRegular?.display_name
                  ? `Copy Regular Hours from \n (${selectedBusinessForRegular?.display_name})`
                  : 'Select a Business to Copy Hours '
              }
              height='62px'
              width='400px'
              setIsOpen={handleOpenCopyModalForRegular}
              colorStyle={true}
            />
            <Button
              disabled={!selectedBusinessForRegular?.id}
              onClick={handleCopyRegularHours}
            >
              Copy Regular Hours
            </Button>
          </CopyHoursWrapper>
          <RegularHoursContainer
            businessId={businessId}
            regularHoursForm={regularHoursForm}
            setRegularHoursForm={setRegularHoursForm}
          />
          <ButtonRowWrapper>
            <CancelButtonWrapper>
              <CancelButton secondary onClick={handleCancelHoursChange}>
                Cancel
              </CancelButton>
            </CancelButtonWrapper>
            <CopyButton onClick={handleCopyLocationHoursToOrdering}>
              Copy Location Hours to Ordering Hours
            </CopyButton>
            <Button onClick={handleUpdateRegularHours}>Save</Button>
          </ButtonRowWrapper>
          <DividerWrraper>
            <Divider />
          </DividerWrraper>
          {/* <CopyHoursWrapper margin="0 0 15px 0">
            <FilterButton
              message={
                selectedBusinessForSpecial?.display_name
                  ? `Copy Special Hours from \n (${selectedBusinessForSpecial?.display_name})`
                  : "Select a Business to Copy Hours "
              }
              height="62px"
              width="400px"
              setIsOpen={handleOpenCopyModalForSpecial}
            />
            <Button
              disabled={!selectedBusinessForSpecial?.id}
              onClick={handleCopySpecialHours}
            >
              Copy Special Hours
            </Button>
          </CopyHoursWrapper> */}
          <SpecialHoursContainer
            businessId={businessId}
            specialHoursForm={specialHoursForm}
            setSpecialHoursForm={setSpecialHoursForm}
            formatedSpecialHours={formatedSpecialHours}
            postSpecialHour={postSpecialHour}
            patchSpecialHour={patchSpecialHour}
            deleteSelectedSpecialHours={deleteSelectedSpecialHours}
          />
        </Collapse>
      </Card>
      {isOpen && (
        <LocationFilterModalSingleSelect
          approveButtonName='Select Business'
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          initialBusiness={
            copyType === 'regular'
              ? selectedBusinessForRegular
              : selectedBusinessForSpecial
          }
          handleSave={
            copyType === 'regular'
              ? handleGetRegularHours
              : handleGetSepcialHours
          }
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    regularHours: state.businessLocationDetails.rHours,
    specialHours: state.businessLocationDetails.sHours,
    formatedSpecialHours: state.businessLocationDetails.specialHours,
    copiedRegularHours: state.businessLocationDetails.copiedRegularHours,
    copiedSpecialHours: state.businessLocationDetails.copiedSpecialHours,
  };
};
export default connect(mapStateToProps, actions)(HoursContainer);
