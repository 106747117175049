import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { paginate } from './paginate';
import { Button } from 'merchant-portal-components';
import { Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { formatLocations } from '../../utils/childLocations/formatLocations';
import * as actions from '../../modules/BusinessLocationDetails/LocationListManager/actions';
import { updateBusinessInformation } from '../../modules/BusinessLocationDetails/LocationDetailsManager/actions';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
} from './filterModalStyles';

const initiallRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const FilterLocationModal = ({
  locationList,
  requestParams,
  filteredLocations,
  setFilteredLocations,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  requestingGetBusinessLocationsOfMerchant,
  updateLocationRequestParams,
  formField,
  emptyIsEqualToAll,
  isEmptyAccepted,
}) => {
  const locationListIDs = locationList.map((item) => item.id);
  // filter is because of user (roles) that have a limited access to locations.
  const permittedFilteredLocations = filteredLocations.filter((item) =>
    locationListIDs.includes(item)
  );
  const initialActiveBusinesses = permittedFilteredLocations || [];
  const [originalLocationList, setOriginalLocationList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [businessListLength, setBusinessListLength] = useState([]);
  const [activeBusinesses, setActiveBusinesses] = useState(
    initialActiveBusinesses
  );
  const [searchString, setSearchString] = useState('');
  // update original location list
  const updateOriginalLocationList = useCallback(() => {
    setOriginalLocationList(formatLocations(locationList));
    if (permittedFilteredLocations?.length === 0 && emptyIsEqualToAll) {
      const allLocations = locationList.map((location) => location.id);
      setActiveBusinesses(allLocations);
    } else {
      setActiveBusinesses(permittedFilteredLocations);
    }
  }, [locationList, filteredLocations]);
  useEffect(() => {
    updateOriginalLocationList();
  }, [updateOriginalLocationList]);
  // filtering Selected List
  const filterBusinessList = useCallback(() => {
    let filteredList = originalLocationList;
    if (searchString) {
      filteredList = filteredList?.filter((item) =>
        item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    activeBusinesses,
    JSON.stringify(originalLocationList),
  ]);
  useEffect(() => {
    filterBusinessList();
  }, [filterBusinessList]);
  useEffect(() => {
    const filteredList = originalLocationList.filter((item) =>
      item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
    );
    const paginatedList = paginate(filteredList, 1, requestParams.page_size);
    updateLocationRequestParams({ ...requestParams, page_number: 1 });
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [searchString]);
  ///
  useEffect(() => {
    updateLocationRequestParams(initiallRequestParams);
    return () => {
      updateLocationRequestParams(initiallRequestParams);
    };
  }, []);
  const handleAssignBusinesses = (e, id) => {
    let newActiveBusinesses = [...activeBusinesses];
    let isActive = activeBusinesses.includes(id);
    if (isActive) {
      newActiveBusinesses = activeBusinesses.filter(
        (currentId) => currentId !== id
      );
    } else {
      newActiveBusinesses.push(id);
    }
    setActiveBusinesses(newActiveBusinesses);
  };
  const handleAssignAllBusinesses = (e) => {
    let isActive = e.target.checked;
    let newActiveBusinesses = [];
    if (isActive) {
      businessList.map((business) => {
        newActiveBusinesses.push(business.id);
      });
    }
    setActiveBusinesses(newActiveBusinesses);
  };
  const handleAddBusinesses = () => {
    const isAllLocationSelected =
      originalLocationList.length === activeBusinesses.length;
    let newSelectedBusinesses =
      isAllLocationSelected && emptyIsEqualToAll ? [] : activeBusinesses;
    if (formField) {
      setFilteredLocations(formField, newSelectedBusinesses);
    } else {
      setFilteredLocations(newSelectedBusinesses);
    }
    setIsOpen(false);
  };
  const handleReset = () => {
    const allLocations = locationList.map((location) => location.id);
    if (initialActiveBusinesses.length === 0 && emptyIsEqualToAll) {
      setActiveBusinesses(allLocations.sort());
    } else {
      setActiveBusinesses(initialActiveBusinesses.sort());
    }
  };
  const isSaveDisabled = () => {
    let isDisabled = true;
    let isEqual = _.isEqual(
      initialActiveBusinesses.sort(),
      activeBusinesses.sort()
    );
    if (!isEqual) {
      if (activeBusinesses.length > 0) {
        isDisabled = false;
      }
      if (activeBusinesses.length === 0 && isEmptyAccepted) {
        isDisabled = false;
      }
    } else {
      if (activeBusinesses.length === 0 && isEmptyAccepted) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Locations</SectionTitle>

            <LocationsCounter>
              {`${activeBusinesses.length} locations selected`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>

        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {`${businessListLength} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <RowWrapper>
          <TH>
            <Checkbox
              checked={businessList.length === activeBusinesses.length}
              onChange={handleAssignAllBusinesses}
              color='primary'
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </TH>
          <TH>Select All Locations</TH>
        </RowWrapper>
        {businessList.map((businessLocation, index) => (
          <RowWrapper
            key={index}
            hoverBGColor='#f1f1fa'
            onClick={(e) => handleAssignBusinesses(e, businessLocation.id)}
          >
            <TD bgColor='yellow'>
              <Checkbox
                checked={activeBusinesses.includes(businessLocation.id)}
                onChange={(e) => handleAssignBusinesses(e, businessLocation.id)}
                color='primary'
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            </TD>
            <TD isAssigned={activeBusinesses.includes(businessLocation.id)}>
              {businessLocation.business_name}
            </TD>
          </RowWrapper>
        ))}

        <PaginationWrapper>
          <PaginationControl
            response={{
              ...businessList,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: businessListLength,
            }}
            requestParams={requestParams}
            type='locationFiltering'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleAddBusinesses} disabled={isSaveDisabled()}>
              Save
            </Button>
          </Right>
        </ButtonWrapper>
        {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    requestingGetBusinessLocationsOfMerchant:
      state.businessLocationsList.requestingGetBusinessLocationsOfMerchant,
    locationList: state.businessLocationsList.businessLocationsOfMerchant,
    requestParams: state.businessLocationsList.requestParams,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  updateBusinessInformation,
})(FilterLocationModal);
