import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'merchant-portal-components';
import logo from '../../../public/img/logo-symbol.png';
import _nav from './_nav';
import { Footer } from 'merchant-portal-components';
import { withFirebase } from '../Firebase';
import PortalReload from '../../components/PortalReload';
import filterNav from './utils/filterNav';
import PromoteTierUpgrade from './PromoteTierUpgrade/PromoteTierUpgrade';
import { refreshSession } from '../Account/actions';
import { getMissingCombosPOSMapping } from './actions';
import PortalProblemsModal from './PortalProblemsModal';
import ringBell from '../../../public/audio/ringing_bell.mp3';
import { orderManagementClients } from '../../utils/clientCustomizations';
const LayourWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isSideMenuExtended ? '290px 1fr' : '50px 1fr'};
  grid-template-rows: 56px 1fr;
  height: 100vh;
`;
const AppBarWrapper = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1;
  /* background-color: blue; */
`;
const SideMenuWrapper = styled.div`
  grid-column: 1;
  grid-row: 2;
  /* background-color: yellow; */
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
  grid-column: 2;
  grid-row: 2;
  /* background-color: pink; */
  overflow-x: hidden;
`;

const StyledNavLink = styled(NavLink)`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 4px;
  font-weight: 600;
  height: 60px;
  align-items: center;
  font-size: 16px;
  color: ${(props) =>
    props.is_selected == 'true' ? props.theme.blue : props.theme.darkBlue};
  text-decoration: none !important;
  &:hover {
    color: ${(props) => props.theme.darkBlue};
    background-color: ${(props) => props.theme.paleGray};
    transition: ${(props) =>
      !props.sortDisabled ? 'all 0.3s cubic-bezier(.25,.8,.25,1)' : 'none'};
  }
  padding-top: 15px;
  padding-bottom: 15px;
`;

const NavItemWithChildren = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 1fr 15px;
  grid-gap: 4px;
  font-weight: 600;
  height: 60px;
  align-items: center;
  font-size: 16px;
  text-decoration: none !important;
  color: ${(props) =>
    props.is_selected == 'true' ? props.theme.blue : props.theme.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme.paleGray};
    transition: ${(props) =>
      !props.sortDisabled ? 'all 0.3s cubic-bezier(.25,.8,.25,1)' : 'none'};
  }
`;

const NavItemChild = styled(NavLink)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  font-weight: 600;
  padding: 9px 0px 0px 70px;
  height: 40px;
  font-size: 16px;
  text-decoration: none !important;
  color: ${(props) =>
    props.is_selected == 'true' ? props.theme.blue : props.theme.darkBlue};
  &:hover {
    color: ${(props) => props.theme.darkBlue};
    background-color: ${(props) => props.theme.paleGray};
    transition: ${(props) =>
      !props.sortDisabled ? 'all 0.3s cubic-bezier(.25,.8,.25,1)' : 'none'};
  }
`;

const Signout = styled.div`
  color: ${(props) => props.theme.white} !important;
  cursor: pointer;
`;

const NameAndLogo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 20px;
`;

const CustomIcon = styled(Icon)`
  font-size: 22px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 7px 14px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 2vh;
  &:hover {
    background: #f7f7fa;
  }
`;

const DisplayName = styled.div`
  color: ${(props) => props.theme.white};
  cursor: default;
`;
const InfoTag = styled.span`
  margin-top: 3px;
  background-color: #e6ebff;
  color: #2440ae;
  padding: 2px 3px;
  font-size: 12px;
  line-height: 16px;
  max-width: 40px;
  max-height: 20px;
  margin-left: 4px;
  border-radius: 4px;
`;

const SmoothLogo = styled.img`
  width: 26px;
`;

const MenuChrevronIcon = styled.i`
  font-size: 8px;
`;
const SignoutWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0.2;
    }
  `;
}
function ringingBellEffect() {
  return keyframes`
      0% { transform: rotate(0); }
      1% { transform: rotate(30deg); }
      3% { transform: rotate(-28deg); }
      5% { transform: rotate(34deg); }
      7% { transform: rotate(-32deg); }
      9% { transform: rotate(30deg); }
      11% { transform: rotate(-28deg); }
      13% { transform: rotate(26deg); }
      15% { transform: rotate(-24deg); }
      17% { transform: rotate(22deg); }
      19% { transform: rotate(-20deg); }
      21% { transform: rotate(18deg); }
      23% { transform: rotate(-16deg); }
      25% { transform: rotate(14deg); }
      27% { transform: rotate(-12deg); }
      29% { transform: rotate(10deg); }
      31% { transform: rotate(-8deg); }
      33% { transform: rotate(6deg); }
      35% { transform: rotate(-4deg); }
      37% { transform: rotate(2deg); }
      39% { transform: rotate(-1deg); }
      41% { transform: rotate(1deg); }
      43% { transform: rotate(0); }
      100% { transform: rotate(0); }
  `;
}

const PortalProblemIcon = styled.i`
  margin-right: 12px;
  color: #ffd43b;
  font-size: 22px;
  /* animation: ${blinkingEffect} 2s linear infinite; */
  animation: ${ringingBellEffect} 4s 1s ease-in-out infinite;
  &:hover {
    animation: none;
    cursor: pointer;
  }
`;
const PortalHealthIcon = styled.i`
  color: #00d062;
  margin-right: 12px;
  background: linear-gradient(#fff, #fff) no-repeat;
  background-position: 54% 50%;
  background-size: 64% 48%;
  font-size: 24px;
  &:hover {
    animation: none;
    cursor: pointer;
  }
`;

const drawerWidth = 290;

const styles = (theme) => {
  return {
    root: {
      display: 'flex',
      height: 56,
      minHeight: 56,
      top: '60px',
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.tertiary.main,
      height: 56,
      minHeight: 56,
      boxShadow: 'none',
      '@media print': {
        display: 'none',
      },
    },
    appBar_reload: {
      top: 75,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.tertiary.main,
      height: 56,
      minHeight: 56,
      boxShadow: 'none',
      '@media print': {
        display: 'none',
      },
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '@media print': {
        display: 'none',
      },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '@media print': {
        display: 'none',
      },
    },

    drawerOpen: {
      width: drawerWidth,
      top: 56,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '@media print': {
        display: 'none',
      },
    },
    drawerOpen_reload: {
      width: drawerWidth,
      top: 131,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '@media print': {
        display: 'none',
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 50,
      top: 56,
      '@media print': {
        display: 'none',
      },
    },
    drawerClose_reload: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 50,
      top: 131,
      '@media print': {
        display: 'none',
      },
    },
    toolbar: {
      height: 56,
      minHeight: 56,
      '@media print': {
        display: 'none',
      },
    },
    toolbar_reload: {
      height: 131,
      minHeight: 131,
      '@media print': {
        display: 'none',
      },
    },
    menuButton: {
      display: 'flex',
      alignItems: 'flex-end',
      margin: '60px 20px 0px',
      minHeight: 56,
      position: 'relative',
      height: '100%',
      top: '-60px',
    },
    toolbarLeft: {
      display: 'flex',
      alignItems: 'flex-end',
      margin: '60px 20px 0px',
      minHeight: 56,
      position: 'relative',
      height: '100%',
      top: '-60px',
    },
    toolbarLeft_reload: {
      display: 'flex',
      alignItems: 'flex-end',
      margin: '60px 20px 0px',
      minHeight: 56,
      position: 'relative',
      height: '100%',
      top: '-130px',
    },
    content: {
      // flexGrow: 1,
      width: `100%`,
    },
  };
};
const intervalMilisecond = 60 * 60 * 1000;
class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.refreshSessionIntervals = null;

    this.state = {
      isOpen: true,
      navItemOpened: false,
      nav: _nav,
      portalReloadIsVisible: false,
      isPortalProblemModalOpen: false,
      posrtalHasProblem: false,
      posrtalHasPOSMappingProblem: false,
      playAlarm: false,
    };
    this.audio = new Audio(ringBell);
  }

  componentDidMount() {
    this.audio.removeEventListener('ended', () =>
      this.setState({ playAlarm: false })
    );
    const filteredNav = filterNav(
      this.props.appIdentifier,
      this.props.appConfig
    );
    this.setState({ nav: filteredNav });
    // this.updateNav();
    const { firebase } = this.props;
    this.refreshSessionIntervals = setInterval(() => {
      this.props.refreshSession();
    }, intervalMilisecond);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.appIdentifier !== this.props.appIdentifier) {
      const filteredNav = filterNav(
        this.props.appIdentifier,
        this.props.appConfig
      );
      this.setState({ nav: filteredNav });
      // this.updateNav();
    }
    if (prevProps.token !== this.props.token) {
      if (
        (this.props.token && !this.props.sessionErrors) ||
        prevProps.sessionErrors !== this.props.sessionErrors
      ) {
        this.refreshSessionIntervals = setInterval(() => {
          this.props.refreshSession();
        }, intervalMilisecond);
      } else clearInterval(this.refreshSessionIntervals);
    }
    if (
      prevProps.missingComboItemPOSMappings !==
        this.props.missingComboItemPOSMappings ||
      prevProps.missingComboPOSMappings !==
        this.props.missingComboPOSMappings ||
      prevProps.missingItemPOSMappings !== this.props.missingItemPOSMappings ||
      prevProps.missingProductPOSMappings !==
        this.props.missingProductPOSMappings
    ) {
      if (
        this.props.missingComboItemPOSMappings?.length > 0 ||
        this.props.missingComboPOSMappings?.length > 0 ||
        this.props.missingItemPOSMappings?.length > 0 ||
        this.props.missingProductPOSMappings?.length > 0
      ) {
        const triggerMissingAlarm = Boolean(
          localStorage.getItem('missingAlarm')
        );
        if (triggerMissingAlarm) {
          const role = localStorage.getItem('role');
          if (['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(role)) {
            // this.setState({ isPortalProblemModalOpen: true });
          }
          this.setState({ playAlarm: true });
          // this.audio.play();
          localStorage.removeItem('missingAlarm');
        }
        this.setState({
          posrtalHasProblem: true,
          posrtalHasPOSMappingProblem: true,
        });
      } else {
        this.setState({
          posrtalHasProblem: false,
          posrtalHasPOSMappingProblem: false,
        });
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.refreshSessionIntervals);
    this.audio.removeEventListener('ended', () =>
      this.setState({ playAlarm: false })
    );
  }
  handleDrawerOpen = () => {
    this.setState({ isOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ isOpen: false });
  };

  toggleNavItemOpened = (navItemOpened) => {
    if (this.state.navItemOpened === navItemOpened) {
      this.setState({ navItemOpened: false, isOpen: true });
    } else {
      this.setState({ navItemOpened, isOpen: true });
    }
  };
  handleCloseProblemModal = () => {
    this.setState({ isPortalProblemModalOpen: false });
  };
  renderSubMenu = (childItem, index, appConfig) => {
    if (childItem.disabled) {
      return (
        <NavItemChild
          key={index}
          style={{ color: 'grey' }}
          is_selected={(
            childItem.url == this.props.locationPathName
          ).toString()}
          to={this.props.locationPathName}
          onClick={() => this.props.setPromoteTierUpgradeModalIsVisible(true)}
        >
          {childItem.name}
          <i
            style={{
              fontSize: '12px',
              marginTop: '7px',
              textAlign: 'right',
            }}
            className='fal fa-lock'
          ></i>
        </NavItemChild>
      );
    } else {
      return (
        <>
          <NavItemChild
            key={index}
            is_selected={(
              childItem.url == this.props.locationPathName
            ).toString()}
            to={childItem.url}
          >
            <span>{childItem.name}</span>
            {childItem.name.toLowerCase() === 'option price' && (
              <Tooltip
                style={{
                  marginLeft: '5px',
                  marginTop: '4px',
                  display: 'inline',
                }}
                title='Add prices to option items if you want to charge extra for product customizations.'
                placement='bottom-left'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            )}
            {childItem.name.toLowerCase() === 'offer management' && (
              <InfoTag>New!</InfoTag>
            )}
          </NavItemChild>
        </>
      );
    }
  };

  render() {
    const {
      missingComboItemPOSMappings,
      missingComboPOSMappings,
      missingItemPOSMappings,
      missingProductPOSMappings,
    } = this.props;
    const { posrtalHasPOSMappingProblem } = this.state;
    const { classes, appIdentifier, appConfig } = this.props;
    const { nav } = this.state;
    return (
      <LayourWrapper isSideMenuExtended={this.state.isOpen}>
        <AppBarWrapper>
          <AppBar
            position='fixed'
            className={classNames(
              this.state.portalReloadIsVisible
                ? classes.appBar_reload
                : classes.appBar,
              {
                [classes.appBarShift]: this.state.isOpen,
              }
            )}
          >
            <Toolbar disableGutters={true} className={classes.toolbar}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 20px',
                  width: '100%',
                }}
              >
                <NameAndLogo>
                  <SmoothLogo src={logo} />
                  <DisplayName>
                    {localStorage.getItem('displayName')}
                  </DisplayName>
                </NameAndLogo>
                <SignoutWrapper
                  style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  {this.state.posrtalHasProblem ? (
                    <PortalProblemIcon
                      className='fa fa-bell'
                      onClick={() =>
                        this.setState({ isPortalProblemModalOpen: true })
                      }
                    ></PortalProblemIcon>
                  ) : (
                    <PortalHealthIcon className='fas fa-shield-check'></PortalHealthIcon>
                  )}
                  <Signout onClick={this.props.handleSignoutClick}>
                    Sign Out
                  </Signout>
                </SignoutWrapper>
              </div>
            </Toolbar>
          </AppBar>
        </AppBarWrapper>
        <SideMenuWrapper>
          <Drawer
            variant='permanent'
            className={classNames(classes.drawer, {
              [this.state.portalReloadIsVisible
                ? classes.drawerOpen_reload
                : classes.drawerOpen]: this.state.isOpen,
              [this.state.portalReloadIsVisible
                ? classes.drawerClose_reload
                : classes.drawerClose]: !this.state.isOpen,
            })}
            classes={{
              paper: classNames({
                [this.state.portalReloadIsVisible
                  ? classes.drawerOpen_reload
                  : classes.drawerOpen]: this.state.isOpen,
                [this.state.portalReloadIsVisible
                  ? classes.drawerClose_reload
                  : classes.drawerClose]: !this.state.isOpen,
              }),
            }}
            open={this.state.isOpen}
          >
            <Divider style={{ marginTop: '-1px' }} />
            {nav.items.map((mainItem, index) => {
              if (
                mainItem.authorization.includes(localStorage.getItem('role'))
              ) {
                if (mainItem.children && this.state.isOpen) {
                  return (
                    <div key={index}>
                      <NavItemWithChildren
                        onClick={() => this.toggleNavItemOpened(mainItem.name)}
                        is_selected={isMainItemSelected(
                          mainItem,
                          this.props.locationPathName
                        ).toString()}
                      >
                        <Icon
                          style={{ fontSize: '24px', textAlign: 'center' }}
                          className={mainItem.icon}
                        />
                        <div>{mainItem.name}</div>
                        <MenuChrevronIcon
                          className={
                            this.state.navItemOpened == mainItem.name
                              ? 'fa fa-chevron-down'
                              : 'fa fa-chevron-right'
                          }
                        />
                      </NavItemWithChildren>
                      <Collapse in={this.state.navItemOpened === mainItem.name}>
                        {mainItem.children.map((childItem, index) => {
                          return this.renderSubMenu(
                            childItem,
                            index,
                            appConfig
                          );
                        })}
                      </Collapse>
                    </div>
                  );
                }
                if (mainItem.children) {
                  return (
                    <NavItemWithChildren
                      key={index}
                      onClick={() => this.toggleNavItemOpened(mainItem.name)}
                      is_selected={isMainItemSelected(
                        mainItem,
                        this.props.locationPathName
                      ).toString()}
                    >
                      <Icon
                        style={{ fontSize: '24px', textAlign: 'center' }}
                        className={mainItem.icon}
                      />
                      <div>{mainItem.name}</div>
                      <MenuChrevronIcon
                        className={
                          this.state.navItemOpened == mainItem.name
                            ? 'fa fa-chevron-down'
                            : 'fa fa-chevron-right'
                        }
                      />
                    </NavItemWithChildren>
                  );
                } else {
                  if (
                    !orderManagementClients.includes(appIdentifier) &&
                    mainItem.name === 'Order Management'
                  ) {
                    return null;
                  } else {
                    return (
                      <StyledNavLink
                        key={index}
                        to={mainItem.url}
                        is_selected={isMainItemSelected(
                          mainItem,
                          this.props.locationPathName
                        ).toString()}
                      >
                        <Icon
                          style={{ fontSize: '24px', textAlign: 'center' }}
                          className={mainItem.icon}
                        />
                        <div>
                          <span>{mainItem.name}</span>
                          {mainItem.name.toLowerCase() ===
                            'order management' && <InfoTag>Beta</InfoTag>}
                        </div>
                      </StyledNavLink>
                    );
                  }
                }
              }
            })}
            <div
              style={{
                justifyContent: this.state.isOpen ? 'flex-end' : 'center',
              }}
              className={
                this.state.portalReloadIsVisible
                  ? classes.toolbarLeft_reload
                  : classes.toolbarLeft
              }
            >
              {this.state.isOpen ? (
                <CustomIcon
                  onClick={this.handleDrawerClose}
                  className='far fa-chevron-left'
                />
              ) : (
                <CustomIcon
                  onClick={this.handleDrawerOpen}
                  className='fal fa-bars'
                />
              )}
            </div>
          </Drawer>
        </SideMenuWrapper>
        <ContentWrapper>
          <div style={{ minHeight: '100vh', paddingBottom: '30px' }}>
            <div
            // className={
            //   this.state.portalReloadIsVisible
            //     ? classes.toolbar_reload
            //     : classes.toolbar
            // }
            />
            {this.props.children}
          </div>
          {this.props.promoteTierUpgradeModalIsVisible && (
            <PromoteTierUpgrade
              setPromoteTierUpgradeModalIsVisible={
                this.props.setPromoteTierUpgradeModalIsVisible
              }
            />
          )}
          <Footer />
        </ContentWrapper>
        {this.state.isPortalProblemModalOpen && (
          <PortalProblemsModal
            isOpen={this.state.isPortalProblemModalOpen}
            handleCloseModal={this.handleCloseProblemModal}
            missingPOSMappings={
              posrtalHasPOSMappingProblem && {
                missingComboItemPOSMappings: missingComboItemPOSMappings,
                missingComboPOSMappings: missingComboPOSMappings,
                missingItemPOSMappings: missingItemPOSMappings,
                missingProductPOSMappings: missingProductPOSMappings,
              }
            }
            history={this.props.history}
            getMissingCombosPOSMapping={this.props.getMissingCombosPOSMapping}
          />
        )}
      </LayourWrapper>
    );
  }
}

const isMainItemSelected = (mainItem, locationPathName) => {
  let isSelected = false;
  if (mainItem.url == locationPathName) {
    isSelected = true;
  }
  if (mainItem.children) {
    mainItem.children.forEach((childItem) => {
      if (
        childItem.authorization.includes(localStorage.getItem('role')) &&
        childItem.url == '/' + locationPathName.split('/').splice(1)[0]
      ) {
        isSelected = true;
      }
    });
  }

  if (mainItem.notOnSidebar) {
    mainItem.notOnSidebar.forEach((childItem) => {
      if (
        childItem.authorization.includes(localStorage.getItem('role')) &&
        childItem.url == '/' + locationPathName.split('/').splice(1)[0]
      ) {
        isSelected = true;
      }
    });
  }

  return (
    isSelected || mainItem.url == '/' + locationPathName.split('/').splice(1)[0]
  );
};

MiniDrawer = withFirebase(MiniDrawer);
const mapStateToProps = (state) => ({
  token: state.accountReducer.token,
  sessionErrors: state.accountReducer.sessionErrors,
  missingComboItemPOSMappings: state.fullReducer.missingComboItemPOSMappings,
  missingComboPOSMappings: state.fullReducer.missingComboPOSMappings,
  missingItemPOSMappings: state.fullReducer.missingItemPOSMappings,
  missingProductPOSMappings: state.fullReducer.missingProductPOSMappings,
});
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, { refreshSession, getMissingCombosPOSMapping })(
    MiniDrawer
  )
);
