import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../components/Icon';

export const InfoIcon=(props)=>{
    return(
        <>
            <div>
           <Tooltip
              style={{ marginRight: 'auto'  }}
              title="The results on each page are sorted into 'Active' and 'Inactive' for your convenience. Click the buttons to switch views. Navigate to the next page for more results. The number of items you see depends on the selected page size, which you can adjust in the 'Results Per Page' drop down."
            >
              <span> 
                <TooltipIcon  style={{color:'#2440AE'}} marginLeft />
              </span>
            </Tooltip>
            </div>
        </>
    )
}