import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './rolesActions';
import RolesAndPermissionsIndex from './components/RolesAndPermissionsIndex';
import { LoadingBlueLarge } from 'merchant-portal-components';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';

class RolesAndPermissionsContainer extends Component {
  componentDidMount() {
    track.page('app-settings-roles-and-permissions');
    this.props.getBusinessAccounts();
  }
  render() {
    const { getBusinessAcountsIsRequesting, accounts } =
      this.props.rolesReducer;

    const authUser = this.props.authUser;
    const isModal = this.props.isModal;

    const businesses = this.props.businesses;
    const {
      postResendInvitation,
      updateUser,
      patchUpdateRole,
      toggleCreateUserModal,
    } = this.props;

    return (
      <Fragment>
        <BreadCrumb pageName='Portal Users' />
        {getBusinessAcountsIsRequesting ? (
          <LoadingBlueLarge />
        ) : (
          <RolesAndPermissionsIndex
            accounts={accounts}
            businesses={businesses}
            postResendInvitation={postResendInvitation}
            updateUser={updateUser}
            patchUpdateRole={patchUpdateRole}
            authUser={authUser}
            isModal={isModal}
            toggleCreateUserModal={toggleCreateUserModal}
            appConfig={this.props.appConfig}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rolesReducer: state.rolesReducer,
  businesses: state.businessLocationsList.businessLocationsOfMerchant,
  authUser: state.accountReducer,
  isModal: state.rolesReducer.isModal,
  appConfig: state.accountReducer.appMetaData,
});

export default connect(mapStateToProps, actions)(RolesAndPermissionsContainer);
