import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import PermissionsIndex from './PermissionsIndex';
import RolesIndex from './RolesIndex';
import { getPagesWithPermissions } from '../configs/pagesConfig';
import { ROLE_LOYALTY_ADMIN } from '../../../utils/rolesRepository';

const ComponentCardWrapper = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  background-color: white;
  padding: 0;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Roles = (props) => {
  const {
    accounts,
    businesses,
    postResendInvitation,
    updateUser,
    patchUpdateRole,
    authUser,
    isModal,
    toggleCreateUserModal,
    appConfig,
  } = props;

  const modifiedRoles = useMemo(() => {
    const notRoyaltyAdmin =
      !appConfig?.configuration.is_only_enabled_for_loyalty &&
      !appConfig?.configuration.is_generic_loyalty_enabled;
    const role = localStorage.getItem('role');
    if (notRoyaltyAdmin) {
      return getPagesWithPermissions('NO_LOYALTY');
    } else if (
      appConfig?.configuration.is_only_enabled_for_loyalty &&
      role === ROLE_LOYALTY_ADMIN
    ) {
      return getPagesWithPermissions('ONLY_LOYALTY');
    }
    return getPagesWithPermissions();
  }, [
    appConfig?.configuration.is_only_enabled_for_loyalty,
    appConfig?.configuration.is_generic_loyalty_enabled,
  ]);
  return (
    <Fragment>
      <ComponentCardWrapper>
        <PermissionsIndex accounts={accounts} roles={modifiedRoles} />
      </ComponentCardWrapper>
      <ComponentCardWrapper>
        <RolesIndex
          accounts={accounts}
          roles={modifiedRoles}
          businesses={businesses}
          postResendInvitation={postResendInvitation}
          updateUser={updateUser}
          patchUpdateRole={patchUpdateRole}
          authUser={authUser}
          isModal={isModal}
          toggleCreateUserModal={toggleCreateUserModal}
        />
      </ComponentCardWrapper>
    </Fragment>
  );
};
export default Roles;
