import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import Card from '../../../components/Card/Card';
import MainStepper from './components/stepper';
import { hoursFormatter } from '../utils/helper.js';
import { comboAvailableAllTime } from '../utils/helper.js';
import { isProductAvailableAllTimeFunction } from '../utils/helper.js';
import * as Yup from 'yup';
import _ from 'lodash';
const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const InfoImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7.5%;
`;

const LocationSegmentGrid = styled.div`
  display: grid;
  width: 42.5%;
  grid-gap: 50px;
  margin-top: 50px;
`;

const HrLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2.5em 0;
  padding: 0;
`;
const offerSchema = Yup.object().shape({
  conditionValue: Yup.array()
    .of(
      Yup.object().shape({
        dayOfWeek: Yup.string(),
        reward: Yup.object()
          .shape({
            type: Yup.string().required('Required'),
            amount: Yup.number()
              .typeError('Required')
              .required('Required')
              .when('type', {
                is: 'discount',
                then: (schema) =>
                  schema
                    .min(0.1, 'Must be greater than 0%')
                    .max(100, 'No greater than 100%'),
              }),
          })
          .required('Required'),
      })
    )
    .min(1, 'Required'),
  offer_details: Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long! Max 50 Characters')
      .required('Required'),
    subtitle: Yup.string()
      .min(2, 'Too Short!')
      .max(64, 'Too Long! Max 64 Characters')
      .required('Required'),
    label: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long! Max 50 Characters')
      .required('Required'),
    description: Yup.string()
      .max(254, 'Too Long! Max 254 Characters')
      .nullable(true),
    image_url: Yup.string().required('Required'),
    template_name: Yup.string().required('Required'),
    never_expires: Yup.bool(),
    start_date: Yup.string().required('Required'),
    end_date: Yup.string()
      .ensure()
      .when('never_expires', {
        is: (never_expires) => never_expires === false,
        then: Yup.string().required('Required'),
      }),
  }),
  customerList: Yup.array()
    .ensure()
    .when('hasAssignedCustomer', {
      is: true,
      then: Yup.array().min(1, 'Required'),
    }),
  segments: Yup.array()
    .ensure()
    .when('hasAssignedSegments', {
      is: true,
      then: Yup.array().min(1, 'Required'),
    }),
});
const OfferDetails = ({
  handleSubmit,
  isRequesting,
  offerAvailability,
  editingOfferEngine,
  isPointsEnabled,
  customerList,
  segmentList,
  businessList,
  initialValues,
  offerEngineSource,
  conditionValue,
  offerEngineProducts,
  offerPromoCode,
  createOffer,
  getPromos,
  updatePromoRequestParams,
  requestParamsPromo,
  customerData,
  history,
  allProductVariants,
  getProductsVariants,
  currentAssignedCustomers,
  orderTypesList,
  loyaltyTiers,
}) => {
  const [sortedConditionValue, setSortedConditionValue] = useState([]);
  const [isFormReset, setIsFormReset] = useState(false);
  const [initValue, setInitValue] = useState();
  useEffect(() => {
    if (isFormReset) {
      setIsFormReset(false);
    }
  }, [isFormReset]);
  useEffect(() => {
    const newInitValue = {
      ...initialValues,
      offerIsofferAvailableAllTime: isProductAvailableAllTimeFunction(
        initialValues.offer_details.availability
      ),
      offerAvailabilities: hoursFormatter(
        initialValues.offer_details.availability
      ),
      order_types: initialValues?.offer_details?.order_types || [],
      conditionValue: initialValues.offer_details.conditions,
      offerEngineProducts: offerEngineProducts,
      offerPromoCode: offerPromoCode ? offerPromoCode : [],
      hasAssignedSegments:
        initialValues.offer_details.segments.length > 0 ? true : false,
      segments: initialValues.offer_details.segments,
      hasAssignedCustomer: currentAssignedCustomers.length > 0 ? true : false,
      customerList: currentAssignedCustomers?.map(
        (customer) => customer.customer_id
      ),
      currentAssignedCustomers: currentAssignedCustomers?.map(
        (customer) => customer.customer_id
      ),
    };
    setInitValue(newInitValue);
  }, []);
  return (
    <Formik
      initialValues={initValue}
      enableReinitialize={true}
      validationSchema={offerSchema}
    >
      {({
        values,
        handleReset,
        handleChange,
        handleBlur,
        dirty,
        errors,
        touched,
        setFieldValue,
      }) => (
        <>
          {Object.keys(values).length > 0 && (
            <MainStepper
              createOffer={createOffer}
              values={values}
              setFieldValue={setFieldValue}
              initialValues={editingOfferEngine}
              offerAvailabilities={values.offerAvailabilities}
              handleChange={handleChange}
              handleBlur={handleBlur}
              customerList={customerList}
              segmentList={segmentList}
              businessList={businessList}
              offerEngineSource={offerEngineSource}
              offerEngineProducts={offerEngineProducts}
              dirty={dirty}
              errors={errors}
              touched={touched}
              conditionValue={conditionValue}
              offerPromoCode={offerPromoCode}
              getPromos={getPromos}
              updatePromoRequestParams={updatePromoRequestParams}
              requestParamsPromo={requestParamsPromo}
              history={history}
              allProductVariants={allProductVariants}
              orderTypesList={orderTypesList}
              loyaltyTiers={loyaltyTiers}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default OfferDetails;
