import React, { Fragment, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { Button, ButtonRow } from "merchant-portal-components";
import Card from "../../../../../components/Card/Card";
import {
  RenderTextField,
  required,
  RenderSelect,
  RenderToggleSwitch,
  RenderDateTimePicker,
} from "../../../../../components/FormElements/FormikElements";
import { HrLine, StyledLabel } from "merchant-portal-components";
import RewardOptionsForm from "./RewardOptionsForm";
import RecipientsForm from "./RecipientsForm";
import ConfirmCancelModal from "../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal";
import { cancelCoupon } from "../../actions";

const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;
const FormWrapper = styled(Card)`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 200px;
  grid-gap: 20px;
  padding: 20px;
`;

const FieldWrapper = styled.div`
  grid-column: span ${(props) => props.col};
`;

const CouponForm = (props) => {
  const {
    coupons,
    allBusinesses,
    programType,
    appIdentifier,
    allSegments,
    selectedSegments,
    setSelectedSegments,
    customersFromSelectedSegments,
    selectedRecipientsDetails,
    requestingGetCustomerDetail,
    handleRemoveAllRecipient,
    handleAddAllRecipient,
    handleAddRecipient,
    initialValues,
    handleRemoveRecipient,
    filterCustomers,
    handleSubmitCoupon,
    isEditingCustomer,
    setIsEditingCustomers,
    cancelCoupon,
    queryString,
  } = props;

  const form = useRef();

  useEffect(() => {
    if (form.current) {
      form.current.getFormikActions().validateForm();
    }
  });

  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        ref={form}
      >
        {({
          values,
          handleReset,
          setFieldValue,
          errors,
          isSubmitting,
          dirty,
        }) => (
          <FormWrapper>
            <Form>
              <ContentWrapper>
                <FieldWrapper col={4}>
                  <Field
                    name="coupon_details.is_active"
                    label="Active"
                    component={RenderToggleSwitch}
                    onChange={(e) => {
                      setFieldValue(
                        "coupon_details.is_active",
                        e.target.checked
                      );
                      // setIsEditingCustomers(true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper col={2}>
                  <Field
                    name="coupon_details.label"
                    component={RenderTextField}
                    label="Coupon Name *"
                    validate={required}
                    onChange={(e) => {
                      // setIsEditingCustomers(true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper col={2}>
                  <Field
                    name="coupon_details.coupon_code"
                    component={RenderTextField}
                    label="Coupon Code *"
                    validate={required}
                    onChange={(e) => {
                      // setIsEditingCustomers(true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper col={4}>
                  <Field
                    name="coupon_details.description"
                    label={
                      appIdentifier != "toppers"
                        ? "Coupon Description *"
                        : "Coupon Description"
                    }
                    component={RenderTextField}
                    rows={3}
                    validate={() => {
                      if (
                        appIdentifier != "toppers" &&
                        !values.coupon_details.description
                      )
                        return "Required";
                    }}
                    type="text"
                    onChange={(e) => {
                      // setIsEditingCustomers(true);
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper col={4}>
                  <HrLine />
                </FieldWrapper>

                <RewardOptionsForm
                  values={values}
                  programType={programType}
                  setFieldValue={setFieldValue}
                  allBusinesses={allBusinesses}
                  setIsEditingCustomers={setIsEditingCustomers}
                />

                <RecipientsForm
                  values={values}
                  setFieldValue={setFieldValue}
                  allSegments={allSegments}
                  searchLoading={coupons.searchLoading}
                  requestingGetCustomerDetail={
                    coupons.requestingGetCustomerDetail
                  }
                  handleCustomerSearchChange={props.handleCustomerSearchChange}
                  handleAddRecipient={handleAddRecipient}
                  handleAddAllRecipient={handleAddAllRecipient}
                  selectedSegments={selectedSegments}
                  setSelectedSegments={setSelectedSegments}
                  customersFromSelectedSegments={customersFromSelectedSegments}
                  selectedRecipientsDetails={selectedRecipientsDetails}
                  handleRemoveRecipient={handleRemoveRecipient}
                  handleRemoveAllRecipient={handleRemoveAllRecipient}
                  // requestingGetCustomerDetail={requestingGetCustomerDetail}
                  filterCustomers={filterCustomers}
                  setIsEditingCustomers={setIsEditingCustomers}
                  queryString={queryString}
                />
              </ContentWrapper>
              <ButtonRow
                justifyContent="space-between"
                style={{
                  margin: "24px 40px 24px 40px",
                  width: "inherit",
                }}
              >
                <Button
                  style={{ outline: "none" }}
                  secondary
                  onClick={() => {
                    isEditingCustomer
                      ? setConfirmCancelModalIsVisible(true)
                      : setConfirmCancelModalIsVisible(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  style={{ outline: "none" }}
                  disabled={
                    (!isEditingCustomer && !dirty) || Object.keys(errors).length
                  }
                  onClick={() => {
                    handleSubmitCoupon(values);
                  }}
                >
                  {"Save"}
                </Button>
              </ButtonRow>
            </Form>
            {confirmCancelModalIsVisible && (
              <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                  handleReset();
                  setConfirmCancelModalIsVisible(false);
                  cancelCoupon();
                }}
              />
            )}
          </FormWrapper>
        )}
      </Formik>
    </Fragment>
  );
};

export default CouponForm;
