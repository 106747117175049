import React, { Component } from 'react';
import { Select } from 'merchant-portal-components';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const PaginationRow = styled.div`
  display: grid;
  justify-content: flex-end;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 60px;
  font-size: 16px;
  margin: 8px 15px 8px 0px;
`;

const DropDownWrapper = styled.div`
  display: grid;
  grid-template: 40px / auto auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
`;

const ChevronWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 60px;
  align-items: center;
`;

const Chevron = styled.i`
  cursor: pointer;
  color: ${(props) => (props.disabled ? 'lightgray' : 'dimgray')};
`;

export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.updatePagination = this.updatePagination.bind(this);
  }

  updatePagination(value) {
    this.props.updateRequestParams(
      {
        ...this.props.requestParams,
        page_size: value,
        page_number: 1,
      },
      this.props.type
    );
  }

  updatePageNumber(number) {
    this.props.updateRequestParams(
      {
        ...this.props.requestParams,
        page_number: number,
      },
      this.props.type
    );
  }

  changePage(action) {
    const currentPageNumber = this.props.requestParams.page_number;
    let nextPageNumber =
      action === 'next' ? currentPageNumber + 1 : currentPageNumber - 1;
    this.updatePageNumber(nextPageNumber);
  }

  lastNumberOnPage() {
    const { page_number, page_size, count } = this.props.response;
    const number = page_number * page_size;
    return number > count ? count : number;
  }

  handleNextClick = (handleNextPageClick) => {
    if (handleNextPageClick) {
      handleNextPageClick();
    } else {
      this.changePage('next');
    }
  };

  handlePrevClick = (handlePreviousPageClick) => {
    if (handlePreviousPageClick) {
      handlePreviousPageClick();
    } else {
      this.changePage('prev');
    }
  };

  render() {
    const { page_number, page_size, count } = this.props.response;
    const isFirstPage = page_number == 1;
    const isLastPage = page_number * page_size >= count;

    const pageIntervalOptions = this.props.pageIntervalOptions || [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
      { value: 500, label: '500' },
    ];

    return (
      <PaginationRow>
        <DropDownWrapper>
          <div>Results Per Page</div>
          <div style={{ width: '90px' }}>
            {!this.props.enableCustomResultSelection ? (
              <Select
                pagination
                small
                isSearchable={false}
                options={pageIntervalOptions}
                value={pageIntervalOptions.filter(
                  ({ value }) => value == this.props.requestParams.page_size
                )}
                onChange={(option) => this.updatePagination(option.value)}
                isDisabled={this.props.isResultsPerPageDisabled}
              />
            ) : (
              <TextField
                defaultValue='10'
                type='number'
                min='1'
                onChange={(e) => this.updatePagination(e.target.value)}
              />
            )}
          </div>
        </DropDownWrapper>
        <div>
          {(page_number - 1) * page_size + 1}-{this.lastNumberOnPage()}{' '}
          {count && 'of ' + count}
        </div>
        <ChevronWrapper>
          {isFirstPage ? (
            <Chevron className='fa fa-chevron-left' disabled />
          ) : (
            <Chevron
              className='fa fa-chevron-left'
              //onClick={() => this.changePage('prev')}
              onClick={() =>
                this.handlePrevClick(this.props.handlePreviousPageClick)
              }
            />
          )}
          {isLastPage ? (
            <Chevron className='fa fa-chevron-right' disabled />
          ) : (
            <Chevron
              className='fa fa-chevron-right'
              // onClick={() => this.changePage('next')}
              onClick={() =>
                this.handleNextClick(this.props.handleNextPageClick)
              }
            />
          )}
        </ChevronWrapper>
      </PaginationRow>
    );
  }
}

Pagination.propTypes = {
  response: PropTypes.object.isRequired,
  requestParams: PropTypes.object.isRequired,
};

export default Pagination;
