import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import Moment from 'moment';
import {
  RenderTextField,
  RenderToggleSwitch,
  required,
  isPriceValidation,
} from '../../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from 'merchant-portal-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import MenuItem from '@material-ui/core/MenuItem';
import { Collapse } from '@material-ui/core';
import ConfirmCancelModal from '../../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';

const Card = styled.div`
  background-color: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  color: ${(props) => (props.disabled ? '#191818' : 'inherit')};
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
  padding-right: 20px;
`;
export const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 30px 20px 40px;
  margin: 10px;
`;
const FormWrapper = styled.div`
  padding: 20px 48px 20px 48px;
  display: grid;
  grid-gap: 20px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  align-items: center;
`;

const Headline = styled.p`
  font-size: 17px;
  font-weight: 600;
`;

const OrderSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 100px;
  grid-gap: 20px;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
  opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
  /* margin: 40px 0 0 48px; */
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
`;

const BusinessInformationForm = ({
  initialValues,
  currencyList,
  handleSubmit,
  externalIdIsVisible,
  is_delivery_enabled,
  deactivateSection,
  isBusinessAdminRole,
}) => {
  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <Card disabled={deactivateSection}>
      <TitleAndCollapseButton>
        <Title disabled={deactivateSection}>Business Information</Title>
        {!isExpanded ? (
          <ChevronDownIcon
            disabled={!deactivateSection ? false : 'true'}
            style={{
              opacity: !deactivateSection ? 'none' : '0.5',
            }}
            onClick={() => {
              setIsExpanded(true);
            }}
          />
        ) : (
          <ChevronUpIcon
            // disabled={dirty}
            onClick={() => {
              setIsExpanded(false);
            }}
          />
        )}
      </TitleAndCollapseButton>
      <Collapse in={isExpanded}>
        <Formik initialValues={initialValues} enableReinitialize={true}>
          {({
            values,
            isValid,
            touched,
            setFieldValue,
            dirty,
            handleReset,
          }) => (
            <FormWrapper>
              <FieldWrapper>
                <Field
                  disabled={deactivateSection}
                  name='businessInformation.business_name'
                  component={RenderTextField}
                  label='Internal Location Name *'
                  validate={required}
                />
              </FieldWrapper>
              {externalIdIsVisible && (
                <GridWrapper>
                  <Field
                    disabled={deactivateSection}
                    name='businessInformation.external_id'
                    component={RenderTextField}
                    label='External ID *'
                    validate={required}
                  />
                </GridWrapper>
              )}
              <Headline>Order Settings</Headline>
              <OrderSettingsWrapper>
                <Field
                  disabled={deactivateSection}
                  component={RenderTextField}
                  style={{ position: 'relative', top: '-4px' }}
                  type='text'
                  name='businessInformation.currency'
                  label='Currency *'
                  select
                  variant='outlined'
                  margin='normal'
                  validate={required}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {currencyList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icon className='fal fa-dollar-sign' />
                      </InputAdornment>
                    ),
                  }}
                  disabled={deactivateSection}
                  name='businessInformation.transaction_amount_limit'
                  component={RenderTextField}
                  label='Maximum Order Amount *'
                  validate={isPriceValidation}
                />
              </OrderSettingsWrapper>
              <Field
                disabled={deactivateSection}
                name='businessInformation.is_order_ahead_enabled'
                component={RenderToggleSwitch}
                label={
                  values.businessInformation.is_order_ahead_enabled
                    ? 'Order Ahead Enabled'
                    : 'Order Ahead Disabled'
                }
                onChange={(e) =>
                  setFieldValue(
                    'businessInformation.is_order_ahead_enabled',
                    e.target.checked
                  )
                }
              />
              {is_delivery_enabled ? (
                <GridWrapper>
                  <Field
                    disabled={deactivateSection}
                    name='businessInformation.is_delivery_enabled'
                    component={RenderToggleSwitch}
                    label={
                      values.businessInformation.is_delivery_enabled
                        ? 'Delivery Enabled'
                        : 'Delivery Disabled'
                    }
                    onChange={(e) =>
                      setFieldValue(
                        'businessInformation.is_delivery_enabled',
                        e.target.checked
                      )
                    }
                  />
                  <div>
                    {values.businessInformation.is_delivery_enabled
                      ? null
                      : 'Only Pick-up will be available for this Location'}
                  </div>
                </GridWrapper>
              ) : (
                <div />
              )}
              <Field
                disabled={deactivateSection}
                name='businessInformation.is_active'
                component={RenderToggleSwitch}
                label={
                  values.businessInformation.is_active
                    ? 'Business Location Active'
                    : 'Business Location Inactive'
                }
                onChange={(e) =>
                  setFieldValue(
                    'businessInformation.is_active',
                    e.target.checked
                  )
                }
              />
              {/* <Field
                    name="businessInformation.is_visible"
                    component={RenderToggleSwitch}
                    label= {values.businessInformation.is_visible ? 
                                  "Business Location Visible" : "Business Location Invisible"}
                    onChange={e => setFieldValue('businessInformation.is_visible', e.target.checked)}
                /> */}
              <ButtonRow
                justifyContent='space-between'
                style={{
                  margin: '24px 40px 24px 40px',
                  width: 'inherit',
                }}
              >
                <Button
                  secondary
                  onClick={() => {
                    dirty ? setConfirmCancelModalIsVisible(true) : '';
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    !dirty ||
                    !isValid ||
                    deactivateSection ||
                    isBusinessAdminRole
                  }
                  onClick={() => {
                    if (!externalIdIsVisible) {
                      delete values.businessInformation.external_id;
                    }
                    handleSubmit(values);
                  }}
                >
                  Save
                </Button>
              </ButtonRow>
              {confirmCancelModalIsVisible && (
                <ConfirmCancelModal
                  onCancel={() => setConfirmCancelModalIsVisible(false)}
                  onConfirm={() => {
                    handleReset();
                    setConfirmCancelModalIsVisible(false);
                  }}
                />
              )}
            </FormWrapper>
          )}
        </Formik>
      </Collapse>
    </Card>
  );
};

export default BusinessInformationForm;
