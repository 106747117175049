import axios from 'axios';
import {
  IS_SIGNING_IN,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  FETCH_MESSAGE,
  BUSINESS_INFO,
  USER_ROLE,
  RESET_PASSWORD_FORM,
  ERROR_MESSAGE,
  PROMPT_REAUTH,
  SIGN_IN_FAILURE,
  PASSWORD_RESET_REQUEST_SENT,
} from './types';

import { createAlert } from '../Alert/actions';
import api from '../../api';
import Moment from 'moment';
import { onAppLoad } from '../Full/actions';
import { isTemperoarySummaryReportBannerDisplayed } from '../SummaryReport/summaryReportActions';
import { isTemperoarySettlementBannerDisplayed } from '../Settlements/actions';

export function authUser(token) {
  return { type: AUTH_USER, token };
}

export function signinUser({ email, password }) {
  const login_credentials = {
    username: email,
    password: password,
  };

  return function (dispatch) {
    dispatch({
      type: IS_SIGNING_IN,
      loginFormEmail: email,
      loginFormPassword: null,
      //reinitialize the formik form
      //by sending null and on error a string.
    });
    return axios
      .post(`${ROOT_URL}/login`, login_credentials)
      .then((response) => {
        if (response.data.result) {
          isTemperoarySummaryReportBannerDisplayed(true);
          isTemperoarySettlementBannerDisplayed(true);
          localStorage.setItem('token', response.data.data.session_token);
          localStorage.setItem('displayName', response.data.data.display_name);
          localStorage.setItem('role', response.data.data.role);
          localStorage.setItem('missingAlarm', 'true');
          localStorage.setItem(
            'appIdentifier',
            response.data.data.app_identifier
          );
          localStorage.setItem(
            'is_tiers_enabled',
            response.data.data.app_detail.configuration.is_tiers_enabled
          );
          localStorage.setItem(
            'appMetaData',
            JSON.stringify(response.data.data.app_detail)
          );
          // const createdAt = Moment();
          // localStorage.setItem('createdAt', createdAt);
          localStorage.setItem('username', email);
          localStorage.removeItem('reauthenticate');
          dispatch(authUser(response.data.data.session_token));
          dispatch({
            type: USER_ROLE,
            response: response.data.data.role,
          });
          dispatch({
            type: BUSINESS_INFO,
            response: response.data.data,
            username: email,
          });
          dispatch(onAppLoad());
          dispatch({
            type: 'UPDATE_REFRESH_SESSION_ERRORS',
            sessionErrors: false,
          });
          // dispatch(
          //   createAlert({
          //     type: 'error',
          //     message:
          //       'Due to an issue with Amazon Web Services, push notifications are not being sent out. We will provide an updated message once regular service has resumed.',
          //     alertDuration: 30000,
          //   })
          // );
          api.get(`/firebase-token`).then((response) => {
            localStorage.setItem('firebase_token', response.data.data.token);
          });
        } else {
          dispatch({
            type: SIGN_IN_FAILURE,
          });
          dispatch(resetPasswordForm());
          dispatch({
            type: ERROR_MESSAGE,
            message: 'Invalid Credentials',
          });
          dispatch(authError('Bad Login Info'));
        }
      })
      .catch((err) => {
        dispatch({
          type: SIGN_IN_FAILURE,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: err.response.data.errors.message,
          })
        );

        dispatch(resetPasswordForm());
        dispatch(authError('Bad Login Info'));
      });
  };
}

export function promptReauth() {
  return function (dispatch) {
    dispatch({
      type: PROMPT_REAUTH,
    });
  };
}
export function refreshSession() {
  return function (dispatch) {
    return api
      .post(`/refresh-session`)
      .then((response) => {
        dispatch({
          type: 'UPDATE_REFRESH_SESSION_ERRORS',
          sessionErrors: false,
        });
      })
      .catch((errors) => {
        const newSessionError = errors ? true : false;
        dispatch({
          type: 'UPDATE_REFRESH_SESSION_ERRORS',
          sessionErrors: newSessionError,
        });
      });
  };
}
export function signupUser({ email, password }) {
  return function (dispatch) {
    return axios
      .post(`${ROOT_URL}/signup`, { email, password })
      .then((response) => {
        dispatch({ type: AUTH_USER });
        localStorage.setItem('token', response.data.token);
      })
      .catch((response) => dispatch(authError(response.data.errors.message)));
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

export function resetPasswordForm() {
  return {
    type: RESET_PASSWORD_FORM,
  };
}

export function requestUserPasswordReset(payload) {
  return function (dispatch) {
    return axios
      .post(`${ROOT_URL}/forgot-password`, payload)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message:
              'Email sent! If this is a valid account, you will receive an email shortly',
          })
        );
        dispatch({
          type: PASSWORD_RESET_REQUEST_SENT,
          key: 'resetPasswordRequestSent',
          value: true,
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          dispatch(
            createAlert({
              type: 'error',
              message: err?.response?.data?.errors?.message || 'The username must be an email',
            })
          );
        } else {
          dispatch(
            createAlert({
              type: 'error',
              message: err.response.data.errors.message,
            })
          );
        }
      });
  };
}

export function resetUserPassword(data) {
  return function (dispatch) {
    return axios
      .post(`${ROOT_URL}/reset-password`, data)
      .then((response) => {
        dispatch(createAlert({ type: 'success', message: 'Success!' }));
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: err.response.data.errors.message,
          })
        )
      );
  };
}

export function validateChangePasswordFields(values) {
  const errors = {};
  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Confirmation password does not match';
  } else if (values.password.length < 8) {
    errors.password = 'Password should contain 8 characters at least';
  } else if (!/[0-9*@!#%&()^~{}]+/.test(values.password)) {
    errors.password = 'Password should contain 1 digit or symbol at least';
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = 'Password should contain 1 upper case character';
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = 'Password should contain 1 lower case character';
  }
  return errors;
}

export function signoutUser() {
  return function (dispatch) {
    localStorage.clear();
    dispatch({ type: 'TRANSFER_TO_LOCATION_LIST' });
    return api.post('/logout').then((response) => {
      dispatch({ type: UNAUTH_USER });
    });
  };
  return { type: UNAUTH_USER };
}

export function softSignoutUser() {
  return function (dispatch) {
    dispatch({ type: UNAUTH_USER });
  };
}

export function fetchMessage() {
  return function (dispatch) {
    axios
      .get(ROOT_URL, {
        headers: { authorization: localStorage.getItem('token') },
      })
      .then((response) => {
        dispatch({
          type: FETCH_MESSAGE,
          payload: response.data.message,
        });
      });
  };
}
