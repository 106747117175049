import React, { Fragment, useEffect } from 'react';
import { ErrorMessage } from 'merchant-portal-components';
import {
  RenderTextField,
  required,
  RenderSelect,
  requiredSelect,
  isAlphabetValidation,
  isValidPostalCode,
  isLatLng,
} from '../../../../../../components/FormElements/FormikElements';
import { Autocomplete } from '@material-ui/lab';
import { Field } from 'formik';
import { FieldWrapper } from './styles';
import { searchAddress } from './helper';

const AddressForm = ({
  setSelectedAddress,
  selectedAddress,
  isEditing,
  setIsEditing,
  setFieldValue,
  countries,
  states,
  values,
  errors,
  touched,
  form,
  // searchBox,
}) => {
  useEffect(() => {
    let input = document.getElementById('address-input');
    let searchBox = new window.google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: ['ca', 'usa'] },
    });
    // setSearchBox(searchBox);
    // setLocalForm(values);
    if (values?.address) {
      input.value = `${values?.address?.line1}`;
      setFieldValue('address', values?.address);
    }
    let searchBoxListener = searchBox.addListener('place_changed', () => {
      let place = searchBox.getPlace();
      if (!place) {
        setFieldValue('address', null);
        return;
      }
      const searchedAddress = searchAddress(place, states, countries);
      input.value = searchedAddress && searchedAddress.line1;
      if (searchedAddress) {
        setFieldValue('address', searchedAddress);
        form.current.getFormikActions().validateForm();
      }
    });
    return () => {
      window.google.maps.event.removeListener(searchBoxListener);
    };
  }, []);
  return (
    <Fragment>
      <FieldWrapper grid='1fr 100px'>
        <Field
          required
          id='address-input'
          label='Street Address'
          name='address.line1'
          component={RenderTextField}
          inputProps={{
            autocomplete: 'off',
            form: {
              autocomplete: 'off',
            },
          }}
          error={touched?.address?.line1 && errors?.address?.line1}

          //   validate={required}
        />
      </FieldWrapper>
      <FieldWrapper grid='1fr 100px'>
        <Field
          name='address.line2'
          label='Unit, Suite, etc.'
          component={RenderTextField}
          // onChange={(e) => {
          //   setSelectedAddress({
          //     address: { ...selectedAddress.address, line2: e.target.value },
          //   });
          //   setIsEditing(true);
          // }}
        />
      </FieldWrapper>
      <FieldWrapper grid='1fr 100px'>
        <Field
          name='address.line3'
          label='Neighbourhood'
          component={RenderTextField}
          // onChange={(e) => {
          //   setSelectedAddress({
          //     address: { ...selectedAddress.address, line3: e.target.value },
          //   });
          //   setIsEditing(true);
          // }}
        />
      </FieldWrapper>
      <FieldWrapper grid='1fr 100px'>
        <Field
          required
          name='address.city'
          label='City'
          component={RenderTextField}
          error={touched.address?.city && errors.address?.city}
          // onChange={(e) => {
          //   setSelectedAddress({
          //     address: { ...selectedAddress.address, city: e.target.value },
          //   });
          //   setIsEditing(true);
          // }}
          //   validate={isAlphabetValidation}
        />
      </FieldWrapper>
      <FieldWrapper grid='1fr 1fr 1fr 100px'>
        <Field
          required
          name='address.country'
          attachedLabel='Country/Region *'
          component={RenderSelect}
          style={{ position: 'relative', top: '-4px' }}
          options={countries ? countries : []}
          getOptionValue={(country) => country.abbreviation}
          getOptionLabel={(country) => country.name}
          valueKey='abbreviation'
          // onChange={(e) => {
          //   setFieldValue('address.country', e.abbreviation);
          // }}
          // error={touched?.address?.country && errors?.address?.country}
          // placeholder='Country/Region'
          //
          //   validate={required}
        />
        <Field
          required
          name='address.state'
          attachedLabel='Province/State *'
          isDisabled={!values.address.country}
          component={RenderSelect}
          style={{ position: 'relative', top: '-4px' }}
          options={
            states
              ? states.filter(
                  (state) => state.country == values.address.country
                )
              : []
          }
          getOptionValue={(state) => state.abbreviation}
          getOptionLabel={(state) => state.name}
          valueKey='abbreviation'
          // onChange={(e) => {
          //   setFieldValue('address.state', e.abbreviation);
          //   // setSelectedAddress({
          //   //   address: { ...selectedAddress.address, state: e.abbreviation },
          //   // });
          //   // setIsEditing(true);
          // }}
          // placeholder='Province/State'
          // error={isEditing && errors.address && errors.address.state}
          //   validate={required}
        />
        <Field
          required
          name='address.zip'
          label='Postal/Zip Code'
          component={RenderTextField}
          error={isEditing && errors.address && errors.address.zip}
          // onChange={(e) => {
          //   setSelectedAddress({
          //     address: { ...selectedAddress.address, zip: e.target.value },
          //   });
          //   setIsEditing(true);
          // }}
          //   validate={isValidPostalCode(values.address.country)}
        />
      </FieldWrapper>
      <FieldWrapper grid='1fr 1fr 1fr 100px'>
        <Field
          required
          name='address.latitude'
          label='Latitude'
          component={RenderTextField}
          error={touched?.address?.latitude && errors?.address?.latitude}
          // onChange={(e) => {
          //   setSelectedAddress({
          //     address: { ...selectedAddress.address, latitude: e.target.value },
          //   });
          //   setIsEditing(true);
          // }}
          //   validate={isLatLng}
        />
        <Field
          required
          name='address.longitude'
          label='Longitude'
          error={touched?.address?.longitude && errors.address?.longitude}
          component={RenderTextField}
          // onChange={(e) => {
          //   setSelectedAddress({
          //     address: {
          //       ...selectedAddress.address,
          //       longitude: e.target.value,
          //     },
          //   });
          //   setIsEditing(true);
          // }}
          //   validate={isLatLng}
        />
      </FieldWrapper>
    </Fragment>
  );
};

export default AddressForm;
