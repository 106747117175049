import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import * as actions from '../offerActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import OffersList from './OffersList';
import { Button } from 'merchant-portal-components';
import { sort } from '../../../../utils/sort/sort';
import ReactGA from 'react-ga';

let OffersListContainer = ({
  offersReducer,
  toggleOfferAvailability,
  initiateCreateOffer,
  initiateEditOffer,
  meta,
  updateRequestParams,
  updateOffersRequestParams,
  getOffers,
  updateSortedOffersList,
  searchString,
  setSearchString,
}) => {
  const { allOffers, requestingGetOffers, requestParams } = offersReducer;
  useEffect(() => {
    getOffers();
    track.page('engagement-offers-list');
    ReactGA.pageview(window.location.pathname, undefined, 'Offer List');
  }, []);

  const handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (allOffers) {
      const result = sort(allOffers, { ...sortArgs, sortOrder: `${order}` });
      updateOffersRequestParams({
        page_number: 1,
        page_size: requestParams.page_size,
      });
      updateSortedOffersList(result, sortOrder);
    }
  };

  const handleOffersSearch = (searchString) => {
    updateOffersRequestParams({
      page_number: 1,
      page_size: requestParams.page_size,
    });
    setSearchString(searchString);
  };

  const sliceOffers = (offers) => {
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;
    let slicedOffers = offers.slice(indexOfFirst, indexOfLast);
    if (searchString) {
      slicedOffers = offers
        .filter(
          (offer) =>
            offer.offer_details?.title
              .toLowerCase()
              .indexOf(searchString?.toLowerCase()) > -1
        )
        .slice(indexOfFirst, indexOfLast);
    }
    return slicedOffers;
    // return offers
    //   .filter(
    //     (offer) =>
    //       offer.offer_details.title
    //         .toLowerCase()
    //         .indexOf(searchString.toLowerCase()) > -1
    //   )
    //   .slice(indexOfFirst, indexOfLast);
  };

  const [offersActiveList, setOffersActiveList] = useState(
    sliceOffers(allOffers)
  );

  useEffect(() => {
    setOffersActiveList(sliceOffers(allOffers));
  }, [requestParams, allOffers, searchString]);

  const allOffersLength = allOffers.filter(
    (allOffers) =>
      allOffers.offer_details?.title
        .toLowerCase()
        .indexOf(searchString.toLowerCase()) > -1
  ).length;
  const handleClearSearch = () => setSearchString('');
  return (
    <Fragment>
      <BreadCrumb />
      <OffersList
        initiateEditOffer={initiateEditOffer}
        initiateCreateOffer={initiateCreateOffer}
        meta={meta}
        handleOffersSearch={handleOffersSearch}
        requestParams={requestParams}
        updateRequestParams={updateRequestParams}
        offersActiveList={offersActiveList}
        requestingGetOffers={requestingGetOffers}
        toggleOfferAvailability={toggleOfferAvailability}
        allOffersLength={allOffersLength}
        handleSort={handleSort}
        searchString={searchString}
        handleClearSearch={handleClearSearch}
      />
    </Fragment>
  );
};

// const initialRequestParams = {
//   page_number: 1,
//   page_size: '1000'
// };

// const initialRequestParams = {
//   page_number: 1,
//   page_size: 25,
//   searchString: ''
// };

// OffersListContainer = fetchOnParamChange(
//   OffersListContainer,
//   actions.getOffers,
//   initialRequestParams
// );

const mapStateToProps = (state) => ({
  offersReducer: state.offersReducer,
  meta: state.offersReducer.meta,
});

export default connect(mapStateToProps, actions)(OffersListContainer);
