import {
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  SEND_REWARDS_MODAL_IS_OPEN,
  SEND_CREDITS_MODAL_IS_OPEN,
  POST_SEND_REWARDS_REQUEST,
  POST_SEND_REWARDS_SUCCESS,
  POST_SEND_REWARDS_FAILURE,
  POST_SEND_CREDITS_REQUEST,
  POST_SEND_CREDITS_SUCCESS,
  POST_SEND_CREDITS_FAILURE,
  ACCOUNT_ACTIVATION_CONFIRM_MODAL_TOGGLE_VISIBILITY,
  PATCH_ACCOUNT_IS_ACTIVE_REQUEST,
  PATCH_ACCOUNT_IS_ACTIVE_SUCCESS,
  ACCOUNT_EDIT_MODAL_TOGGLE_VISIBILITY,
  PATCH_ACCOUNT_INFORMATION_REQUEST,
  PATCH_ACCOUNT_INFORMATION_SUCCESS,
  PATCH_ACCOUNT_INFORMATION_FAILURE,
  GET_CUSTOMER_LOYALTY_INFORMATION_REQUEST,
  GET_CUSTOMER_LOYALTY_INFORMATION_SUCCESS,
  GET_CUSTOMER_GLOBAL_CREDITS_REQUEST,
  GET_CUSTOMER_GLOBAL_CREDITS_SUCCESS,
  RESET_CUSTOMER_DETAILS_REDUCER_STATE,
  GET_CUSTOMER_AUDIT,
} from './customerTypes';

const defaultState = {
  sendLoyaltyModalIsOpen: false,
  SendCreditsModalIsOpen: false,
  postSendCreditsIsRequesting: false,
  postSendLoyaltyIsRequesting: false,
  getCustomerDetailsIsRequesting: true,
  customerDetails: null,
  activationConfirmModalIsVisible: false,
  patchAcountIsActiveIsRequesting: false,
  accountInformationEditModalIsVisible: false,
  patchAccountInformationIsRequesting: false,
  getCustomerLoyaltyInformationIsRequesting: true,
  loyaltyTier: null,
  loyaltyPoints: null,
  loyaltyPointsAccumulation: [],
  getCustomerGlobalCreditsIsRequesting: true,
  customerGlobalCredits: [],
  currentCreditsBalance: 0,
  lifetimeCreditsBalance: 0,
  redirectPage: '',
  customerOrders: [],
  requestParams: {
    page_number: 1,
    page_size: '25',
    has_next_page: false,
    count: 25,
  },
  customerAudits: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'GET_CUSTOMER_ORDERS_SUCCESS':
      return {
        ...state,
        customerOrders: action.customerOrders,
        requestParams: action.requestParams,
      };
    case 'REDIRECT_USER_TO_RELATED_PAGE':
      return { ...state, redirectPage: action.redirectPage };
    case GET_CUSTOMER_DETAILS_REQUEST:
      return { ...state, getCustomerDetailsIsRequesting: true };
    case GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        getCustomerDetailsIsRequesting: false,
        customerDetails: action.customerDetails,
      };
    case SEND_REWARDS_MODAL_IS_OPEN:
      return {
        ...state,
        sendLoyaltyModalIsOpen: action.sendLoyaltyModalIsOpen,
      };
    case SEND_CREDITS_MODAL_IS_OPEN:
      return {
        ...state,
        SendCreditsModalIsOpen: action.SendCreditsModalIsOpen,
      };
    case POST_SEND_CREDITS_REQUEST:
      return { ...state, postSendCreditsIsRequesting: true };
    case POST_SEND_CREDITS_SUCCESS:
      return {
        ...state,
        postSendCreditsIsRequesting: false,
        SendCreditsModalIsOpen: false,
      };
    case POST_SEND_CREDITS_FAILURE:
      return { ...state, postSendCreditsIsRequesting: false };
    case POST_SEND_REWARDS_REQUEST:
      return { ...state, postSendLoyaltyIsRequesting: true };
    case POST_SEND_REWARDS_SUCCESS:
      return {
        ...state,
        postSendLoyaltyIsRequesting: false,
        sendLoyaltyModalIsOpen: false,
      };
    case POST_SEND_REWARDS_FAILURE:
      return { ...state, postSendLoyaltyIsRequesting: false };
    case ACCOUNT_ACTIVATION_CONFIRM_MODAL_TOGGLE_VISIBILITY:
      return {
        ...state,
        activationConfirmModalIsVisible: !state.activationConfirmModalIsVisible,
      };
    case PATCH_ACCOUNT_IS_ACTIVE_REQUEST:
      return { ...state, patchAcountIsActiveIsRequesting: true };
    case PATCH_ACCOUNT_IS_ACTIVE_SUCCESS:
      return {
        ...state,
        patchAcountIsActiveIsRequesting: false,
        activationConfirmModalIsVisible: false,
      };
    case ACCOUNT_EDIT_MODAL_TOGGLE_VISIBILITY:
      return {
        ...state,
        accountInformationEditModalIsVisible:
          !state.accountInformationEditModalIsVisible,
      };
    case PATCH_ACCOUNT_INFORMATION_REQUEST:
      return {
        ...state,
        patchAccountInformationIsRequesting: true,
      };
    case PATCH_ACCOUNT_INFORMATION_SUCCESS:
      return {
        ...state,
        patchAccountInformationIsRequesting: false,
        accountInformationEditModalIsVisible: false,
      };
    case PATCH_ACCOUNT_INFORMATION_FAILURE:
      return {
        ...state,
        patchAccountInformationIsRequesting: false,
      };
    case GET_CUSTOMER_LOYALTY_INFORMATION_REQUEST:
      return { ...state, getCustomerLoyaltyInformationIsRequesting: true };
    case GET_CUSTOMER_LOYALTY_INFORMATION_SUCCESS:
      return {
        ...state,
        getCustomerLoyaltyInformationIsRequesting: false,
        loyaltyTier: action.loyaltyTier,
        loyaltyPoints: action.loyaltyPoints,
        loyaltyPointsAccumulation: action.loyaltyPointsAccumulation,
      };
    case GET_CUSTOMER_GLOBAL_CREDITS_REQUEST:
      return { ...state, getCustomerGlobalCreditsIsRequesting: true };
    case GET_CUSTOMER_GLOBAL_CREDITS_SUCCESS:
      return {
        ...state,
        getCustomerGlobalCreditsIsRequesting: false,
        customerGlobalCredits: action.customerGlobalCredits,
        currentCreditsBalance: action.currentCreditsBalance,
        lifetimeCreditsBalance: action.lifetimeCreditsBalance,
      };
    case RESET_CUSTOMER_DETAILS_REDUCER_STATE:
      return defaultState;

    case GET_CUSTOMER_AUDIT:
      return {
        ...state,
        customerAudits: action.customerAudits,
        requestParams: action.requestParams,
      };
    case 'UPDATE_AUDIT_HISTORY_REQUEST_PARAMS':
      return {
        ...state,
        customerAudits: action.requestParams,
      };
    default:
      return state;
  }
}
