import styled from 'styled-components';

export const SectionWrapper = styled.div``;
export const SectionTitle = styled.div`
  height: 26px;
  margin: 0 5px 40px 0;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
`;
export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 50px;
  margin: ${(props) => props.margin || 0};
`;

export const InfoTitle = styled.div`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: ${(props) => props.align || 'left'};
  margin: ${(props) => props.margin || 0};
`;
export const InfoDesc = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.25px;
  text-align: ${(props) => props.align || 'left'};
  margin: 7px 0;
  text-transform: ${(props) => (props.isCapital ? 'capitalize' : 'none')};
  grid-column: ${(props) => props.position || 'auto'};
  white-space: pre-line;
`;
export const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1.5fr 0.5fr 0.5fr;
  grid-gap: 50px;
  margin: ${(props) => props.margin || 0};
`;
export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin: 40px 0;
`;
export const TableWrapper = styled.div`
  margin: ${(props) => props.margin || 0};
  width: ${(props) => props.width || '100%'};
`;
export const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: ${(props) =>
    props.hasBottomBorder ? '1px solid #e0e0e0' : 'none'};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
`;
export const DividerLine = styled.hr`
  margin: 33px 0;
  border: solid 1px #d8d8d8;
  opacity: 0.4;
`;

// Modal
export const ModalWrapper = styled.div`
  padding: 24px;
`;
export const ModalTitle = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  color: #191818;
  margin-bottom: 24px;
`;
export const CancelButton = styled.span`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #2440ae;
  cursor: pointer;
`;
export const ButtonRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  align-items: center;
  margin-top: 24px;
`;
