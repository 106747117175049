import api from '../../api';
import { createAlert } from '../Alert/actions';
import { getProducts } from '../MenuManagement/Products/productsActions';
import {
  GET_SEGMENTS_REQUEST,
  GET_SEGMENTS_SUCCESS,
  GET_SEGMENTS_FAILURE,
  GET_MAILCHIMP_LISTS_REQUEST,
  GET_MAILCHIMP_LISTS_SUCCESS,
  NO_MAILCHIMP_LIST,
  INITIATE_CREATE_SEGMENT,
  CREATE_SEGMENT_REQUEST,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_FAILURE,
  INITIATE_EDIT_SEGMENT,
  CANCEL_EDIT_SEGMENT,
  EDIT_SEGMENT_FAILURE,
  DELETE_SEGMENT_REQUEST,
  DELETE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_FAILURE,
  UPDATE_SELECTED_LOCATIONS,
  RESET_LOCATIONS,
  TARGET_AUDIENCE_PREVIEW_REQUEST,
  TARGET_AUDIENCE_PREVIEW_SUCCESS,
  TARGET_AUDIENCE_PREVIEW_FAILURE,
  SET_LOCATIONS,
  INITIATE_CLONE_SEGMENT,
  UPDATE_SEGMENTS_REQUEST_PARAMS,
  UPDATE_SORTED_SEGMENTS_LIST,
} from './segmentationTypes';

export function getSegments() {
  return function (dispatch) {
    dispatch({ type: GET_SEGMENTS_REQUEST });

    api.get(`/segments`).then(
      (response) => {
        dispatch({
          type: GET_SEGMENTS_SUCCESS,
          response: response.data,
        });
      },
      (error) => {
        dispatch({
          type: GET_SEGMENTS_FAILURE,
          error,
        });
      }
    );
  };
}

export function getMailChimpLists() {
  return function (dispatch, getState) {
    if (
      getState().accountReducer.appMetaData.configuration.integrations.email
    ) {
      dispatch({ type: GET_MAILCHIMP_LISTS_REQUEST });
      api.get(`/segments/mailchimp-lists`).then(
        (response) => {
          dispatch({
            type: GET_MAILCHIMP_LISTS_SUCCESS,
            mailchimpLists: response.data,
          });
        },
        (error) => {
          dispatch({ type: NO_MAILCHIMP_LIST });
        }
      );
    } else {
      dispatch({ type: NO_MAILCHIMP_LIST });
    }
  };
}

export function initiateCreateSegment() {
  return function (dispatch) {
    dispatch({ type: INITIATE_CREATE_SEGMENT });
  };
}

export function createSegment(segment) {
  return function (dispatch) {
    dispatch({ type: CREATE_SEGMENT_REQUEST });
    api
      .post('/segments', createSegmentWithoutNullValuesAndUserGroup(segment))
      .then(
        (response) => {
          dispatch({
            type: CREATE_SEGMENT_SUCCESS,
            response: response.data,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Segment successfully created.',
            })
          );
        },
        (error) => {
          dispatch({
            type: CREATE_SEGMENT_FAILURE,
            error,
          });
          dispatch(
            createAlert({
              type: 'error',
              message: error?.response?.data?.errors?.message || 'An error occurred trying to create segment.',
            })
          );
        }
      );
  };
}

export function cloneSegment(segment) {
  return function (dispatch) {
    dispatch({ type: CREATE_SEGMENT_REQUEST });
    api
      .post('/segments', editSegmentWithoutNullValuesAndUserGroup(segment))
      .then(
        (response) => {
          dispatch({
            type: CREATE_SEGMENT_SUCCESS,
            response: response.data,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Segment successfully created.',
            })
          );
        },
        (error) => {
          dispatch({
            type: CREATE_SEGMENT_FAILURE,
            error,
          });
          dispatch(
            createAlert({
              type: 'error',
              message: error?.response?.data?.errors?.message || 'An error occurred trying to create segment.',
            })
          );
        }
      );
  };
}

export function editSegment(segment) {
  return function (dispatch) {
    let segmentPatchData = {
      ...segment,
    };
    if (typeof segmentPatchData.isAvailable !== 'undefined') {
      delete segmentPatchData.isAvailable;
    }
    if (typeof segmentPatchData.minutesLeft !== 'undefined') {
      delete segmentPatchData.minutesLeft;
    }
    if (segmentPatchData.mailchimp) {
      if (segmentPatchData.mailchimp.last_sync_date !== undefined) {
        delete segmentPatchData.mailchimp.last_sync_date;
      }
      if (
        segmentPatchData.mailchimp.details &&
        segmentPatchData.mailchimp.details.phone !== undefined
      ) {
        delete segmentPatchData.mailchimp.details.phone;
      }
    }
    dispatch({ type: CREATE_SEGMENT_REQUEST });
    api
      .patch(
        `/segments/${segmentPatchData.segment_id}`,
        editSegmentWithoutNullValuesAndUserGroup(segmentPatchData)
      )
      .then(
        (response) => {
          dispatch({
            type: CREATE_SEGMENT_SUCCESS,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Segment successfully updated.',
            })
          );
        },
        (error) => {
          dispatch({ type: CREATE_SEGMENT_FAILURE, error });
          dispatch(
            createAlert({
              type: 'error',
              message: error?.response?.data?.errors?.message || 'An error occurred trying to update segment.',
            })
          );
        }
      );
  };
}

export function initiateEditSegment(segment) {
  return function (dispatch) {
    dispatch({ type: INITIATE_EDIT_SEGMENT, payload: segment });
  };
}

export function initiateCloneSegment(segment) {
  return function (dispatch) {
    dispatch({ type: INITIATE_CLONE_SEGMENT, payload: segment });
  };
}

export function cancelEditSegment() {
  return function (dispatch) {
    dispatch({ type: CANCEL_EDIT_SEGMENT });
  };
}

export function deleteSegment(segment_id) {
  return function (dispatch) {
    dispatch({ type: DELETE_SEGMENT_REQUEST, payload: segment_id });
    return api.delete(`/segments/${segment_id}`).then(
      (response) => {
        dispatch({ type: DELETE_SEGMENT_SUCCESS, payload: segment_id });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Segment successfully deleted.',
          })
        );
      },

      (error) => {
        dispatch({
          type: DELETE_SEGMENT_FAILURE,
          error,
        });
      }
    );
  };
}

const transformFilterRules = (filterRules) => {
  return {
    ...filterRules,
    rules: filterRules.rules.map((rule) => {
      if (rule.operator === 'is/was' || rule.operator === 'is_was') {
        return {
          ...rule,
          operator: 'is_was',
          values: rule.values.map((value) => {
            if (value.type === 'date') {
              return {
                ...value,
                type: 'relative_date',
                value: parseInt(value.value),
              };
            } else {
              return value;
            }
          }),
        };
      }
      return rule;
    }),
  };
};

export const createSegmentWithoutNullValuesAndUserGroup = (segment) => {
  if (segment.audience.zone === 'all_users') {
    segment = { ...segment, audience: null };
  }
  if (!segment.filters.filter_json || !segment.filters.filter_rules) {
    segment = { ...segment, filters: null };
  } else {
    segment = {
      ...segment,
      filters: {
        ...segment.filters,
        filter_rules: transformFilterRules(segment.filters.filter_rules),
      },
    };
  }

  if (
    segment.mailchimp.external_segment_id != 'nosync' &&
    segment.mailchimp.external_segment_id != 'new'
  ) {
    segment = {
      ...segment,
      integration: 'mailchimp',
      mailchimp: {
        external_segment_id: segment.mailchimp.external_segment_id,
        sync_frequency: segment.mailchimp.sync_frequency,
      },
    };
  }
  if (segment.mailchimp.external_segment_id == 'nosync') {
    const { mailchimp, ...noMailchimpSegment } = segment;
    segment = { ...noMailchimpSegment, integration: 'none' };
  }
  return Object.keys(segment).reduce((newObject, key) => {
    if (key !== 'user_group' && segment[key] !== null) {
      return {
        ...newObject,
        [key]: segment[key],
      };
    }
    return newObject;
  }, {});
};

export const editSegmentWithoutNullValuesAndUserGroup = (segment) => {
  if (segment.audience.zone === 'all_users') {
    segment = {
      ...segment,
      audience: null,
    };
  }
  if (!segment.filters.filter_json || !segment.filters.filter_rules) {
    segment = {
      ...segment,
      filters: null,
    };
  } else {
    segment = {
      ...segment,
      filters: {
        ...segment.filters,
        filter_rules: transformFilterRules(segment.filters.filter_rules),
      },
    };
  }
  if (segment.integration == 'none') {
    const { mailchimp, ...noMailchimpSegment } = segment;
    segment = { ...noMailchimpSegment, integration: 'none' };
  } else {
    if (segment.mailchimp && segment.mailchimp.external_segment_id !== 'new') {
      if (segment.mailchimp.external_segment_id === 'nosync') {
        const { mailchimp, ...noMailchimpSegment } = segment;
        segment = { ...noMailchimpSegment, integration: 'none' };
      } else {
        segment = {
          ...segment,
          integration: 'mailchimp',
          mailchimp: {
            external_segment_id: segment.mailchimp.external_segment_id,
            sync_frequency: segment.mailchimp.sync_frequency,
          },
        };
      }
    }
  }
  return Object.keys(segment).reduce((newObject, key) => {
    if (
      key !== 'user_group' &&
      key !== 'segment_id' &&
      key !== 'app_id' &&
      key !== 'mailchimp_details' &&
      key !== 'created_at' &&
      key !== 'updated_at'
      // key !== 'filters'
      // segment[key] !== null
    ) {
      return {
        ...newObject,
        [key]: segment[key],
      };
    }
    return newObject;
  }, {});
};

export function updateSelectedLocations(selectedLocations) {
  return {
    type: UPDATE_SELECTED_LOCATIONS,
    selectedLocations,
  };
}

export function setLocations(allLocations) {
  return {
    type: SET_LOCATIONS,
    allLocations,
  };
}

export function resetLocations(allLocations) {
  return {
    type: RESET_LOCATIONS,
    allLocations,
  };
}

export function updateSegmentsRequestParams(requestParams) {
  return {
    type: 'UPDATE_SEGMENTS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedSegmentsList(segmentsList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_SEGMENTS_LIST',
    segmentsList: segmentsList,
    sortOrder: sortOrder,
  };
}

export function fetchTargetAudienceData(audience, filters) {
  return function (dispatch) {
    // dispatch({ type: TARGET_AUDIENCE_PREVIEW_REQUEST });
    // let targetAudience = {};
    // if (audience.shape_json !== null) {
    //   targetAudience = { audience };
    // } else {
    //   targetAudience = { audience: null };
    // }
    // if (filters.filter_rules !== null && filters.filter_rules != undefined) {
    //   targetAudience = {
    //     ...targetAudience,
    //     filters: { filter_rules: transformFilterRules(filters.filter_rules) }
    //   };
    // } else {
    //   targetAudience = { ...targetAudience, filters: null };
    // }
    // api.post('/segments/preview', targetAudience).then(
    //   response => {
    //     dispatch({
    //       type: TARGET_AUDIENCE_PREVIEW_SUCCESS,
    //       payload: response.data
    //     });
    //   },
    //   error => {
    //     dispatch({
    //       type: TARGET_AUDIENCE_PREVIEW_FAILURE,
    //       error
    //     });
    //   }
    // );
  };
}
