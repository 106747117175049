import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../variantsActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { Button } from 'merchant-portal-components';
import VariantList from './components/VariantList';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import ReactGA from 'react-ga';
import { NoItemsFound } from '../../NoItemsFound';
import { InfoIcon } from '../../InfoIcon';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 37px 0;
`;
const MessageWrapper = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const TextSearch = styled.div``;
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  margin: 0 37px 37px 37px;
`;
const StyledTab = styled.div`
  margin: 10px;
  margin-left: 40px;
  display: flex;
  z-index: 55;
`;
const StyledSpanCD = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  background-color: #2440ae;
  color: white;
  padding-left: ${(props) => (props.isSD == true ? '5px' : '3.2px')};
  padding-right: ${(props) => (props.isSD == true ? '5px' : '3.2px')};
  padding-top: ${(props) => (props.isSD == true ? '2px' : '3.2px')};
  padding-bottom: ${(props) => (props.isSD == true ? '2px' : '3.2px')};
  font-size: 13.5px;
`;

const StyledNmbrSpan = styled.span`
  display: block;
  margin-top: 20%;
`;
const BtnTxtContnr = styled.div`
  display: inline-flex;
`;
const TxtContr = styled.div`
  margin-top: 6px;
  font-size: 18px;
  margin-right: 6px;
  color: #6f6f76;
`;
const StyledTabBtn = styled.button`
  z-index: 5;
  width: 160px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive == false ? 'lightblue' : '#2440ae'};
  color: ${(props) => (props.isActive == false ? '#000' : '#fff')};
  background-color: ${(props) =>
    props.isActive == false ? '#fff' : '#E6EBFF'};
  color: ${(props) => (props.isActive == false ? '#DCDCDC' : '#2440ae')};
  font-weight: ${(props) => (props.isActive == false ? '' : '')};
  padding: 10px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
`;
let VariantListPage = ({
  allVariants,
  requestParams,
  isGetVariantRequest,
  changeComponentType,
  toggleIsActive,
  isVariantAvailable,
  setEditVariant,
  variantIsSaving,
  getVariants,
  searchString,
  setSearchString,
}) => {
  const [showActiveData, setShowActiveData] = useState(true);
  const [showOnlyWhenNotSearched, setShowOnlyWhenNotSearched] = useState(true);
  const handleSearch = (e) => {
    setShowOnlyWhenNotSearched(false);
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };
  const handleClearSearch = () => {
    setTimeout(() => {
      setShowOnlyWhenNotSearched(true);
    }, 2000);
    setSearchString('');
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname, undefined, 'Variant List');
  }, []);
  return (
    <Fragment>
      <BreadCrumb />
      <Wrapper>
        <TopDiv>
          <TextSearch>
            <TextField
              type='text'
              style={{ width: '100%' }}
              variant='outlined'
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon className='fal fa-search' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start'>
                    <Icon
                      className='fas fa-times-circle'
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder='Search variants'
            />
          </TextSearch>
          <Button
            style={{ width: '100%' }}
            onClick={() => changeComponentType('create')}
          >
            Create Variant
          </Button>
        </TopDiv>
        {showOnlyWhenNotSearched && (
          <StyledTab>
            <div>
              <StyledTabBtn
                isActive={showActiveData}
                onClick={() => {
                  setShowActiveData(true);
                }}
              >
                <BtnTxtContnr>
                  <TxtContr>Active</TxtContr> &nbsp;{' '}
                  <StyledSpanCD
                    isSD={
                      allVariants.filter((ech) => ech.is_active == true)
                        .length < 10
                    }
                  >
                    <StyledNmbrSpan>
                      {
                        allVariants.filter((ech) => ech.is_active == true)
                          .length
                      }
                    </StyledNmbrSpan>
                  </StyledSpanCD>
                </BtnTxtContnr>
              </StyledTabBtn>
            </div>
            <div>
              <StyledTabBtn
                isActive={!showActiveData}
                onClick={() => {
                  setShowActiveData(false);
                }}
              >
                <BtnTxtContnr>
                  <TxtContr>Inactive</TxtContr> &nbsp;{' '}
                  <StyledSpanCD
                    isSD={
                      !(
                        allVariants.filter((ech) => ech.is_active == false)
                          .length >= 10
                      )
                    }
                  >
                    <StyledNmbrSpan>
                      {
                        allVariants.filter((ech) => ech.is_active == false)
                          .length
                      }
                    </StyledNmbrSpan>
                  </StyledSpanCD>
                </BtnTxtContnr>
              </StyledTabBtn>
            </div>
            <InfoIcon />
          </StyledTab>
        )}
        {!isGetVariantRequest && allVariants.length > 0 ? (
          <VariantList
            showOnlyWhenNotSearched={showOnlyWhenNotSearched}
            variantIsSaving={variantIsSaving}
            allVariants={
              showOnlyWhenNotSearched
                ? allVariants.filter((each) => each.is_active == showActiveData)
                : allVariants
            }
            requestParams={requestParams}
            isGetVariantRequest={isGetVariantRequest}
            toggleIsActive={toggleIsActive}
            setEditVariant={setEditVariant}
            isVariantAvailable={isVariantAvailable}
            searchString={searchString}
            getVariants={getVariants}
            showActiveData={showActiveData}
          />
        ) : allVariants.length === 0 && !isGetVariantRequest ? (
          <MessageWrapper>
            <NoItemsFound itemType={'variants'} />
          </MessageWrapper>
        ) : (
          <LoadingBlueLarge />
        )}
      </Wrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  allVariants: state.variantsReducer.allVariants,
  requestParams: state.variantsReducer.requestParams,
  isGetVariantRequest: state.variantsReducer.isGetVariantRequest,
  isVariantAvailable: state.variantsReducer.isVariantAvailable,
  variantIsSaving: state.variantsReducer.variantIsSaving,
});
export default connect(mapStateToProps, actions)(VariantListPage);
