import api from '../../../api';
import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
  TOGGLE_OPTION_IS_ACTIVE_SUCCESS,
  CREATE_OPTION_SUCCESS,
  LOAD_CREATE_OPTION_PAGE,
  LOAD_EDIT_OPTION_PAGE,
  LOAD_LIST_OPTION_PAGE,
  EDIT_OPTION_SUCCESS,
  RESET_OPTION_REDUCER,
  UPDATE_SORTED_OPTIONS_LIST,
} from './optionTypes';

import { createAlert } from '../../Alert/actions';
import 'babel-polyfill';
import { getPurePayload, getSearchUrl } from '../../../utils/purePayload';

export function getOptions(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    dispatch({ type: GET_OPTIONS_REQUEST });
    const urlwithOutSearchString = `/menu/options?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`;
    const url = getSearchUrl(
      urlwithOutSearchString,
      requestParams.search_string,
      3
    );
    return api.get(url).then(
      (response) => {
        const hasNextPage = response.data.meta.has_next_page;
        dispatch({
          type: GET_OPTIONS_SUCCESS,
          options: response.data.data,
          meta: response.data.meta,
          hasNextPage: hasNextPage,
        });
      },
      (error) => {
        dispatch({ type: GET_OPTIONS_FAILURE, error });
      }
    );
  };
}
const formatItems = (items) => {
  if (items && items.length) {
    return items
      .map((item) => ({
        id: item.id,
        parent_id: item.parent_id,
        name: item.name,
        image_url: item.image_url,
        is_active: item.is_active,
        // is_default: item.is_default,
        is_available: item.is_available,
        maximum_pick: item.maximum_pick,
        position: item.position,
        user_description: item.user_description,
        count_as: item.count_as,
        sub_items:
          item.sub_items && item.sub_items.length
            ? item.sub_items
                .map((subItem) => ({
                  id: subItem.id,
                  parent_id: subItem.parent_id,
                  name: subItem.name,
                  is_active: subItem.is_active,
                  // is_default: subItem.is_default,
                  is_available: subItem.is_available,
                  maximum_pick: subItem.maximum_pick,
                  position: subItem.position,
                  user_description: subItem.user_description,
                  count_as: subItem.count_as,
                }))
                .sort((a, b) => {
                  return a.position - b.position;
                })
            : null,
      }))
      .sort((a, b) => {
        return a.position - b.position;
      });
  } else return null;
};
export function toggleOptionIsActive(optionObject) {
  return function (dispatch) {
    api
      .patch(`/menu/options/${optionObject.id}`, {
        is_active: !optionObject.is_active,
      })
      .then((response) => {
        dispatch({
          type: TOGGLE_OPTION_IS_ACTIVE_SUCCESS,
          optionId: optionObject.id,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Option updated successfully',
          })
        );
      });
  };
}

export function createOptions(options) {
  return function (dispatch) {
    if (!validateOptionItem(options)) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            'Combination of items and/or sub items are not within the options min and max range',
        })
      );
      return;
    }
    const parentOption = options[0];
    api
      .post(`/menu/options`, {
        included_pick: parseInt(parentOption.included_pick),
        is_active: parentOption.is_active,
        maximum_pick: parseInt(parentOption.maximum_pick),
        minimum_pick: parseInt(parentOption.minimum_pick),
        name: parentOption.name,
        position: 1,
      })
      .then((parentOptionResponse) => {
        if (parentOption.items) {
          parentOption.items.forEach((item, itemIndex) => {
            let sub_items = item.sub_items
              ? item.sub_items.map((subItem, subItemIndex) => {
                  return {
                    name: subItem.name,
                    image_url: subItem.image_url,
                    is_active: subItem.is_active,
                    // is_default: subItem.is_default,
                    is_available: subItem.is_available,
                    maximum_pick: parseInt(subItem.maximum_pick),
                    position: subItemIndex + 1,
                    user_description: null,
                    count_as: parseFloat(subItem.count_as),
                    // parent_id: itemResponse.data.data.id
                  };
                })
              : null;

            let itemBody = {
              name: item.name,
              image_url: item.image_url,
              is_active: item.is_active,
              // is_default: item.is_default,
              is_available: item.is_available,
              maximum_pick: parseInt(item.maximum_pick),
              position: itemIndex + 1,
              user_description: item.user_description,
              count_as: parseFloat(item.count_as),
            };
            if (sub_items) {
              itemBody = { ...itemBody, sub_items };
              // delete itemBody.maximum_pick;
              delete itemBody.count_as;
            }
            const optionPurePayload = getPurePayload(itemBody);
            api.post(
              `/menu/options/${parentOptionResponse.data.data.id}/items`,
              optionPurePayload
            );
            // .then(itemResponse => {
            //   if (item.sub_items) {
            //     item.sub_items.forEach((subItem, subItemIndex) => {
            //       api.post(
            //         `/menu/options/${parentOptionResponse.data.data.id}/items`,
            //         {
            //           name: subItem.name,
            //           image_url: subItem.image_url,
            //           is_active: subItem.is_active,
            //           is_available: subItem.is_available,
            //           maximum_pick: parseInt(subItem.maximum_pick),
            //           position: subItemIndex + 1,
            //           user_description: null,
            //           count_as: parseInt(subItem.count_as),
            //           parent_id: itemResponse.data.data.id
            //         }
            //       );
            //     });
            //   }
            // });
          });
          dispatch({ type: CREATE_OPTION_SUCCESS });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Option created successfully',
            })
          );
          dispatch(
            getOptions({ searchString: '', page_number: 1, page_size: 100 })
          );
        } else {
          options.slice(1).forEach((option, index) => {
            api
              .post(`/menu/options`, {
                included_pick: parseInt(option.included_pick),
                is_active: option.is_active,
                maximum_pick: parseInt(option.maximum_pick),
                minimum_pick: parseInt(option.minimum_pick),
                name: option.name,
                position: index + 2,
                parent_id: parentOptionResponse.data.data.id,
              })
              .then((optionResponse) => {
                if (option.items) {
                  option.items.forEach((item, itemIndex) => {
                    let sub_items = item.sub_items
                      ? item.sub_items.map((subItem, subItemIndex) => {
                          return {
                            name: subItem.name,
                            image_url: subItem.image_url,
                            is_active: subItem.is_active,
                            // is_default: subItem.is_default,
                            is_available: subItem.is_available,
                            maximum_pick: parseInt(subItem.maximum_pick),
                            position: subItemIndex + 1,
                            user_description: null,
                            count_as: parseFloat(subItem.count_as),
                            // parent_id: itemResponse.data.data.id
                          };
                        })
                      : null;

                    let itemBody = {
                      name: item.name,
                      image_url: item.image_url,
                      is_active: item.is_active,
                      // is_default: item.is_default,
                      is_available: item.is_available,
                      maximum_pick: parseInt(item.maximum_pick),
                      position: itemIndex + 1,
                      user_description: item.user_description,
                      count_as: parseFloat(item.count_as),
                    };
                    if (sub_items) {
                      itemBody = { ...itemBody, sub_items };
                      // delete itemBody.maximum_pick;
                      delete itemBody.count_as;
                    }
                    const newPureSubItemPayload = getPurePayload(itemBody);
                    api.post(
                      `/menu/options/${optionResponse.data.data.id}/items`,
                      newPureSubItemPayload
                    );
                    // .then(itemResponse => {
                    //   if (item.sub_items) {
                    //     item.sub_items.forEach((subItem, subItemIndex) => {
                    //       api.post(
                    //         `/menu/options/${parentOptionResponse.data.data.id}/items`,
                    //         {
                    //           name: subItem.name,
                    //           image_url: subItem.image_url,
                    //           is_active: subItem.is_active,
                    //           is_available: subItem.is_available,
                    //           maximum_pick: parseInt(subItem.maximum_pick),
                    //           position: subItemIndex + 1,
                    //           user_description: null,
                    //           count_as: parseInt(subItem.count_as),
                    //           parent_id: itemResponse.data.data.id
                    //         }
                    //       );
                    //     });
                    //   }
                    // });
                  });
                }
              });
          });
          dispatch({ type: CREATE_OPTION_SUCCESS });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Option created successfully',
            })
          );
          dispatch(
            getOptions({ searchString: '', page_number: 1, page_size: 100 })
          );
        }
      });
  };
}

export function editOption(optionId) {
  return function (dispatch) {
    api.get(`/menu/options/${optionId}?include=items`).then((response) => {
      const option = response.data.data;
      let editingOption = [
        {
          id: option.id,
          parent_id: option.parent_id,
          included_pick: option.included_pick,
          is_active: option.is_active,
          maximum_pick: option.maximum_pick,
          minimum_pick: option.minimum_pick,
          name: option.name,
          position: option.position,
        },
      ];
      if (option.sub_options && option.sub_options.length) {
        editingOption = [
          ...editingOption,
          ...option.sub_options.map((subOption) => ({
            id: subOption.id,
            parent_id: subOption.parent_id,
            included_pick: subOption.included_pick,
            is_active: subOption.is_active,
            maximum_pick: subOption.maximum_pick,
            minimum_pick: subOption.minimum_pick,
            name: subOption.name,
            position: subOption.position,
            items: formatItems(subOption.items),
          })),
        ];
      } else if (option.items && option.items.length) {
        editingOption[0].items = formatItems(option.items);
      }

      dispatch({ type: LOAD_EDIT_OPTION_PAGE, editingOption });
    });
  };
}

export function saveEditOptions(options) {
  return function (dispatch) {
    if (!validateOptionItem(options)) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            'Combination of items and/or sub items are not within the options min and max range',
        })
      );
      return;
    }
    const parentOption = options[0];
    api
      .patch(`/menu/options/${parentOption.id}`, {
        included_pick: parseInt(parentOption.included_pick),
        is_active: parentOption.is_active,
        maximum_pick: parseInt(parentOption.maximum_pick),
        minimum_pick: parseInt(parentOption.minimum_pick),
        name: parentOption.name,
        position: 1,
      })
      .then((parentOptionResponse) => {
        if (parentOption.items && parentOption.items.length) {
          parentOption.items.forEach((item, itemIndex) => {
            if (item.id) {
              let itemBody = {
                name: item.name,
                image_url: item.image_url,
                is_active: item.is_active,
                is_available: item.is_available,
                // is_default: item.is_default,
                maximum_pick: parseInt(item.maximum_pick),
                position: itemIndex + 1,
                user_description: item.user_description,
                count_as: parseFloat(item.count_as),
              };
              if (item.sub_items) {
                delete itemBody.count_as;
              }
              const purePyload = getPurePayload(itemBody);
              api
                .patch(
                  `/menu/options/${parentOption.id}/items/${item.id}`,
                  purePyload
                )
                .then((itemResponse) => {
                  if (item.sub_items && item.sub_items.length) {
                    const puresubItemPyload = getPurePayload({
                      name: subItem.name,
                      image_url: subItem.image_url,
                      is_active: subItem.is_active,
                      // is_default: subItem.is_default,
                      is_available: subItem.is_available,
                      maximum_pick: parseInt(subItem.maximum_pick),
                      position: subItemIndex + 1,
                      user_description: subItem.user_description,
                      count_as: parseFloat(subItem.count_as),
                    });
                    item.sub_items.forEach((subItem, subItemIndex) => {
                      if (subItem.id) {
                        api.patch(
                          `/menu/options/${parentOption.id}/items/${subItem.id}`,
                          puresubItemPyload
                        );
                      } else {
                        api.post(`/menu/options/${parentOption.id}/items`, {
                          name: subItem.name,
                          image_url: subItem.image_url,
                          is_active: subItem.is_active,
                          // is_default: subItem.is_default,
                          is_available: subItem.is_available,
                          maximum_pick: parseInt(subItem.maximum_pick),
                          position: subItemIndex + 1,
                          user_description: null,
                          count_as: parseFloat(subItem.count_as),
                          parent_id: item.id,
                        });
                      }
                    });
                  }
                });
            } else {
              let sub_items = item.sub_items
                ? item.sub_items.map((subItem, subItemIndex) => {
                    return {
                      name: subItem.name,
                      image_url: subItem.image_url,
                      is_active: subItem.is_active,
                      // is_default: subItem.is_default,
                      is_available: subItem.is_available,
                      maximum_pick: parseInt(subItem.maximum_pick),
                      position: subItemIndex + 1,
                      user_description: null,
                      count_as: parseFloat(subItem.count_as),
                      // parent_id: itemResponse.data.data.id
                    };
                  })
                : null;

              let itemBody = {
                name: item.name,
                image_url: item.image_url,
                is_active: item.is_active,
                // is_default: item.is_default,
                is_available: item.is_available,
                maximum_pick: parseInt(item.maximum_pick),
                position: itemIndex + 1,
                user_description: item.user_description,
                count_as: parseFloat(item.count_as),
              };
              if (sub_items) {
                itemBody = { ...itemBody, sub_items };
                // delete itemBody.maximum_pick;
                delete itemBody.count_as;
              }
              const newPureSubitemPayload = getPurePayload(itemBody);
              api.post(
                `/menu/options/${parentOption.id}/items`,
                newPureSubitemPayload
              );
            }
          });
          dispatch({ type: EDIT_OPTION_SUCCESS });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Option saved successfully',
            })
          );
          dispatch(
            getOptions({ searchString: '', page_number: 1, page_size: 100 })
          );
        } else {
          options.slice(1).forEach((option, optionIndex) => {
            if (option.id) {
              api
                .patch(`/menu/options/${option.id}`, {
                  included_pick: parseInt(option.included_pick),
                  is_active: option.is_active,
                  maximum_pick: parseInt(option.maximum_pick),
                  minimum_pick: parseInt(option.minimum_pick),
                  name: option.name,
                  position: optionIndex + 1,
                })
                .then((optionResponse) => {
                  {
                    if (option.items && option.items.length) {
                      option.items.forEach((item, itemIndex) => {
                        if (item.id) {
                          let itemBody = {
                            name: item.name,
                            image_url: item.image_url,
                            is_active: item.is_active,
                            // is_default: item.is_default,
                            is_available: item.is_available,
                            maximum_pick: parseInt(item.maximum_pick),
                            position: itemIndex + 1,
                            user_description: item.user_description,
                            count_as: parseFloat(item.count_as),
                          };

                          if (item.sub_items) {
                            // delete itemBody.maximum_pick;
                            delete itemBody.count_as;
                          }
                          const pureSubOption = getPurePayload(itemBody);
                          api
                            .patch(
                              `/menu/options/${option.id}/items/${item.id}`,
                              pureSubOption
                            )
                            .then((itemResponse) => {
                              if (item.sub_items && item.sub_items.length) {
                                item.sub_items.forEach(
                                  (subItem, subItemIndex) => {
                                    if (subItem.id) {
                                      const pureSubOptionItems = getPurePayload(
                                        {
                                          name: subItem.name,
                                          image_url: subItem.image_url,
                                          is_active: subItem.is_active,
                                          // is_default: subItem.is_default,
                                          is_available: subItem.is_available,
                                          maximum_pick: parseInt(
                                            subItem.maximum_pick
                                          ),
                                          position: subItemIndex + 1,
                                          user_description:
                                            subItem.user_description,
                                          count_as: parseFloat(
                                            subItem.count_as
                                          ),
                                        }
                                      );
                                      api.patch(
                                        `/menu/options/${option.id}/items/${subItem.id}`,
                                        pureSubOptionItems
                                      );
                                    } else {
                                      api.post(
                                        `/menu/options/${option.id}/items`,
                                        {
                                          name: subItem.name,
                                          image_url: subItem.image_url,
                                          is_active: subItem.is_active,
                                          // is_default: subItem.is_default,
                                          is_available: subItem.is_available,
                                          maximum_pick: parseInt(
                                            subItem.maximum_pick
                                          ),
                                          position: subItemIndex + 1,
                                          user_description: null,
                                          count_as: parseFloat(
                                            subItem.count_as
                                          ),
                                          parent_id: item.id,
                                        }
                                      );
                                    }
                                  }
                                );
                              }
                            });
                        } else {
                          let sub_items = item.sub_items
                            ? item.sub_items.map((subItem, subItemIndex) => {
                                return {
                                  name: subItem.name,
                                  image_url: subItem.image_url,
                                  is_active: subItem.is_active,
                                  // is_default: subItem.is_default,
                                  is_available: subItem.is_available,
                                  maximum_pick: parseInt(subItem.maximum_pick),
                                  position: subItemIndex + 1,
                                  user_description: null,
                                  count_as: parseFloat(subItem.count_as),
                                  // parent_id: itemResponse.data.data.id
                                };
                              })
                            : null;

                          let itemBody = {
                            name: item.name,
                            image_url: item.image_url,
                            is_active: item.is_active,
                            // is_default: sub_items.is_default,
                            is_available: item.is_available,
                            maximum_pick: parseInt(item.maximum_pick),
                            position: itemIndex + 1,
                            user_description: item.user_description,
                            count_as: parseFloat(item.count_as),
                          };
                          if (sub_items) {
                            itemBody = { ...itemBody, sub_items };
                            // delete itemBody.maximum_pick;
                            delete itemBody.count_as;
                          }
                          const newPureItemPayload = getPurePayload(itemBody);
                          api.post(
                            `/menu/options/${option.id}/items`,
                            newPureItemPayload
                          );
                        }
                      });
                    }
                  }
                });
            } else {
              api
                .post(`/menu/options`, {
                  included_pick: parseInt(option.included_pick),
                  is_active: option.is_active,
                  maximum_pick: parseInt(option.maximum_pick),
                  minimum_pick: parseInt(option.minimum_pick),
                  name: option.name,
                  position: optionIndex + 1,
                  parent_id: parentOption.id,
                })
                .then((optionResponse) => {
                  {
                    if (option.items && option.items.length) {
                      option.items.forEach((item, itemIndex) => {
                        if (item.id) {
                          let itemBody = {
                            name: item.name,
                            image_url: item.image_url,
                            is_active: item.is_active,
                            // is_default: item.is_default,
                            is_available: item.is_available,
                            maximum_pick: parseInt(item.maximum_pick),
                            position: itemIndex + 1,
                            user_description: item.user_description,
                            count_as: parseFloat(item.count_as),
                          };

                          if (item.sub_items) {
                            // delete itemBody.maximum_pick;
                            delete itemBody.count_as;
                          }
                          const puerSuboptionSubitem = getPurePayload(itemBody);
                          api.patch(
                            `/menu/options/${optionResponse.data.data.id}/items/${item.id}`,
                            puerSuboptionSubitem
                          );
                        } else {
                          let sub_items = item.sub_items
                            ? item.sub_items.map((subItem, subItemIndex) => {
                                return {
                                  name: subItem.name,
                                  image_url: subItem.image_url,
                                  is_active: subItem.is_active,
                                  // is_default: subItem.is_default,
                                  is_available: subItem.is_available,
                                  maximum_pick: parseInt(subItem.maximum_pick),
                                  position: subItemIndex + 1,
                                  user_description: null,
                                  count_as: parseFloat(subItem.count_as),
                                  // parent_id: itemResponse.data.data.id
                                };
                              })
                            : null;

                          let itemBody = {
                            name: item.name,
                            image_url: item.image_url,
                            is_active: item.is_active,
                            // is_default: item.is_default,
                            is_available: item.is_available,
                            maximum_pick: parseInt(item.maximum_pick),
                            position: itemIndex + 1,
                            user_description: item.user_description,
                            count_as: parseFloat(item.count_as),
                          };
                          if (sub_items) {
                            itemBody = { ...itemBody, sub_items };
                            // delete itemBody.maximum_pick;
                            delete itemBody.count_as;
                          }
                          const pureSubOptionSubItemPayload =
                            getPurePayload(itemBody);
                          api.post(
                            `/menu/options/${optionResponse.data.data.id}/items`,
                            pureSubOptionSubItemPayload
                          );
                        }
                      });
                    }
                  }
                });
            }
          });

          dispatch({ type: EDIT_OPTION_SUCCESS });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Option saved successfully',
            })
          );

          dispatch(
            getOptions({ searchString: '', page_number: 1, page_size: 100 })
          );

          //----
        }
      });
  };
}
export function loadCreatePage() {
  return {
    type: LOAD_CREATE_OPTION_PAGE,
  };
}

export function loadListPage() {
  return {
    type: LOAD_LIST_OPTION_PAGE,
  };
}

export function resetOptionReducer() {
  return {
    type: RESET_OPTION_REDUCER,
  };
}

export function updateOptionRequestParams(requestParams) {
  return {
    type: 'UPDATE_OPTIONS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

const validateOptionItem = (options) => {
  return true;
  const parentOption = options.find((option) => !option.parent_id);
  let optionsAreValid = options
    .filter((option) => option.parent_id)
    .every((option) => {
      return (
        parentOption.minimum_pick <= option.minimum_pick &&
        parentOption.maximum_pick >= option.maximum_pick
      );
    });

  if (!optionsAreValid) {
    return false;
  }
  return options.every((option) => {
    if (option.items && option.items.length) {
      let itemsAreValid =
        validateItemRangeSingle(option.items, option.maximum_pick) &&
        allItemsAreValid(
          option.items,
          option.minimum_pick,
          option.maximum_pick
        );

      return (
        itemsAreValid &&
        option.items.every((item) => {
          if (item.sub_items && item.sub_items.length) {
            return (
              validateItemRangeSingle(item.sub_items, item.maximum_pick) &&
              allItemsAreValid(item.sub_items, 0, item.maximum_pick)
            );
          }
          return true;
        })
      );
    }

    return true;
  });
};

const validateItemRangeSingle = (items, max) => {
  return true;
  return items.every((item) => {
    const totalCount =
      parseFloat(item.maximum_pick) * parseFloat(item.count_as);
    // return min <= totalCount && totalCount <= max;
    return totalCount <= max;
  });
};

const allItemsAreValid = (items, min, max) => {
  return true;
  const totalCounts = items.map((item) => item.maximum_pick * item.count_as);

  for (let totalCountSet of powerset(totalCounts)) {
    let totalCount = totalCountSet.reduce((a, b) => a + b, 0);

    if (
      parseFloat(min) <= totalCount &&
      totalCount <= parseFloat(max) &&
      totalCountSet.every((totalCount) => totalCount <= parseFloat(max))
    ) {
      return true;
    }
  }
  return false;
};

function* powerset(array, offset = 0) {
  while (offset < array.length) {
    let first = array[offset++];
    for (let item of powerset(array, offset)) {
      item.push(first);
      yield item;
    }
  }
  yield [];
}

export function updateSortedOptionsList(optionsList, sortOrder) {
  return {
    type: UPDATE_SORTED_OPTIONS_LIST,
    optionsList: optionsList,
    sortOrder: sortOrder,
  };
}
