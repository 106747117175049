import {
  GET_BUSINESS_ACCOUNTS_REQUEST,
  GET_BUSINESS_ACCOUNTS_SUCCESS,
  CREATE_NEW_USER_REQUEST,
  UPDATE_USER,
  PATCH_EDIT_USER,
  TOGGLE_USER_MODAL,
  RESET_IS_REGISTERED,
} from './rolesTypes';
import api from '../../api';
import { createAlert } from '../Alert/actions';

export function getBusinessAccounts() {
  return function (dispatch) {
    dispatch({ type: GET_BUSINESS_ACCOUNTS_REQUEST });
    return api.get('/admins').then((response) => {
      dispatch({
        type: GET_BUSINESS_ACCOUNTS_SUCCESS,
        accounts: response.data.data,
      });
    });
  };
}

export function submitUser(account, user) {
  return function (dispatch) {
    dispatch({ type: CREATE_NEW_USER_REQUEST });
    let apiCall = null;
    if (account) {
      const updatedAccount = {
        username: user.username,
        display_name: user.display_name,
        is_active: user.is_active,
        role: user.role,
        timezone: user.timezone,
      };
      apiCall = api.patch(`/admins/${account.id}`, updatedAccount);
    } else {
      // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const updatedAccount = {
        username: user.username,
        display_name: user.display_name,
        is_active: user.is_active,
        role: user.role,
        timezone: user.timezone,
      };
      apiCall = api.post('/admins', updatedAccount);
    }

    return apiCall
      .then((response) => {
        return api
          .patch(`/admins/${response.data.data.id}/business`, {
            business_ids: user.business_ids,
          })
          .then((response) => {
            dispatch({
              type: PATCH_EDIT_USER,
              payload: {
                account: response.data.data,
              },
            });
            dispatch(toggleCreateUserModal(null));
            dispatch(
              createAlert({
                type: 'success',
                message: account
                  ? 'User was updated successfully'
                  : 'The invitation was sent successfully to the new user',
              })
            );
            !account && dispatch(getBusinessAccounts());
          });
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: account
              ? 'An error occurred trying to update the user'
              : 'An error occurred trying to invite the user or the username exists',
          })
        );
      });
  };
}

export function updateUser(id, body) {
  return function (dispatch) {
    return api
      .patch(`/admins/${id}`, body)
      .then((response) => {
        dispatch({ type: UPDATE_USER, payload: response.data.data });
        dispatch(
          createAlert({
            type: 'success',
            message: 'User is updated successfully',
          })
        );
      })
      .catch((err) =>
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to update the user.',
          })
        )
      );
  };
}

export function postResendInvitation(account) {
  return function (dispatch) {
    if (account.is_password_set) {
      dispatch(
        createAlert({
          type: 'error',
          message:
            'You cannot resend invitation, because the user is registered in the portal.',
        })
      );
      return;
    }

    return api
      .post(`/admins/${account.id}/resend`)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The invitation is resent successfully',
          })
        );
      })
      .catch((err) => {
        dispatch(
          createAlert({
            type: 'error',
            message: err?.response?.data?.errors?.message || 'An error occurred trying to reinvite the user.',
          })
        );
      });
  };
}

export function toggleCreateUserModal(account = null) {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_USER_MODAL,
      account,
    });
  };
}

export function displaySuccessMessage() {
  return function (dispatch) {
    return (
      dispatch({
        type: RESET_IS_REGISTERED,
      }),
      dispatch(
        createAlert({
          type: 'success',
          message:
            'You have been successfully registered. Please login to acces the portal',
        })
      )
    );
  };
}
