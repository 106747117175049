import './YourCriteria.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  relativeDates,
  relativeBetweenDates,
} from '../../FilterCriteria/QueryBuilder/config';

const fields = {
  // first_name                     : 'first name',
  // last_name                      : 'last name',
  // email                          : 'email',
  // address                        : 'address',
  // postal_code                    : 'postal code',
  // average_transactions_per_month : 'average transactions per month',
  total_transactions: 'total transactions',
  total_spend: 'total spent',
  average_spend: 'average spent',
  average_transactions: 'average transactions',
  first_transaction_date: 'first transaction date',
  last_transaction_date: 'last transaction date',
  loyalty_credit_earned: 'loyalty_credit_earned',
  loyalty_credit_redeemed: 'loyalty credit redeemed',
  coupon_credit_earned: 'coupon credit earned',
  coupon_credit_redeemed: 'coupon credit redeemed',
  global_credit_earned: 'global credit earned',
  global_credit_redeemed: 'global credit redeemed',
  offer_credit_earned: 'offer credit earned',
  offer_credit_redeemed: 'offer credit redeemed',
  points_earned: 'points earned',
  points_redeemed: 'points redeemed',
  milestones_reached: 'milestones reached',
  tags: 'tag',
  birthdate: 'Birthdate',
  email: 'Email',
  created_at: 'Registration Date',
  total_dollars_saved: 'Total Dollars Saved',
  products_purchased: 'Product Purchased',
  next_birthday: 'Next Birthday',
  last_birthday: 'Last Birthday',
};

const operators = {
  select_equals: 'equals',
  equal: 'equals',
  not_equal: 'is not equal to',
  begins_with: 'begins with',
  does_not_begins_with: 'does not begin with',
  contains: 'contains',
  not_contains: 'does not contain',
  ends_with: 'ends with',
  not_ends_with: 'does not end with',
  less: 'is less than',
  less_or_equal: 'is less than or equal to',
  greater: 'is greater than',
  greater_or_equal: 'is greater than or equal to',
  between: 'is between',
  not_between: 'not between',
  is_empty: 'is blank',
  is_not_empty: 'is not blank',
  'is/was': 'is/was',
  relatively_before: 'is relatively before',
  relatively_after: 'is relatively after',
  relatively_between: 'is relatively between',
};

const convertValue = (value, isRelativelyBetween) =>
  isRelativelyBetween
    ? relativeBetweenDates[value]
    : relativeDates[value]
    ? relativeDates[value]
    : value;

const Label = styled.div`
  margin-bottom: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.darkBlue};
  text-transform: uppercase;
`;

const NullParagraph = styled.p`
  padding-left: 16px;
  // margin-top: -8px;
`;

const BusinessesWrapper = styled.div`
  padding-left: 16px;
  margin: 20px 0;
`;

class YourCriteria extends Component {
  renderRules(rules, conjunction, depth) {
    const renderRuleString = (rule) => {
      if (rules[rule].properties.field === 'tags') {
        const tagId = rules[rule].properties.value[0];
        const tag = this.props.allTags.find((tag) => tag.tag_id == tagId);
        return (
          <p className='rule rule--first'>
            {fields[rules[rule].properties.field]}{' '}
            {operators[rules[rule].properties.operator]} {tag.name} :{' '}
            {tag.value}
          </p>
        );
      } else if (rules[rule].properties.field === 'products_purchased') {
        const productId = rules[rule].properties.value[0];
        const product = this.props.allProducts.find(
          (product) => product.id == productId
        );
        return (
          <p className='rule rule--first'>
            {fields[rules[rule].properties.field]}{' '}
            {operators[rules[rule].properties.operator]} {product.name}
          </p>
        );
      } else {
        return (
          <p className='rule rule--first'>
            {fields[rules[rule].properties.field]}{' '}
            {operators[rules[rule].properties.operator]}{' '}
            {rules[rule].properties.operator !== 'relatively_between'
              ? rules[rule].properties.value.map((value, index) => {
                  let str = convertValue(value, false);
                  str += ' ';
                  if (index + 1 < rules[rule].properties.value.length) {
                    str += conjunction + ' ';
                  }
                  return str;
                })
              : rules[rule].properties.value.map((value, index) => {
                  let str = convertValue(value, true);
                  str += ' ';
                  if (index + 1 < rules[rule].properties.value.length) {
                    str += conjunction + ' ';
                  }
                  return str;
                })}
          </p>
        );
      }
    };

    const marginLeft = { marginLeft: `${16}px` };
    const marginTopLeft = {
      marginLeft: `${16}px`,
      marginTop: `${depth === 1 ? 0 : -18}px`,
    };
    return Object.keys(rules).map((rule, index) => {
      if (rules[rule].type === 'group') {
        return (
          <div key={rule} className='ruleBox' style={marginLeft}>
            <div>
              <div className='fullLine' />
            </div>
            <div>{this.renderGroup(rules[rule], depth + 1)}</div>
          </div>
        );
      }
      if (rules[rule].properties.field) {
        if (Object.keys(rules).length === 1) {
          return (
            <div key={rule} className='outerbox' style={marginTopLeft}>
              <div className='ruleBox'>
                <div>
                  <div className='circle circle--first circle--single' />
                </div>
                {renderRuleString(rule)}
              </div>
            </div>
          );
        }
        if (index === 0) {
          return (
            <div key={rule} className='outerbox' style={marginTopLeft}>
              <div className='ruleBox'>
                <div>
                  <div className='circle circle--first' />
                  <div className='line' />
                </div>
                {renderRuleString(rule)}
              </div>
              <div className='conjunction'>{conjunction}</div>
            </div>
          );
        }
        if (Object.keys(rules).length === index + 1) {
          return (
            <div key={rule} className='outerbox' style={marginLeft}>
              <div className='ruleBox'>
                <div>
                  <div className='line' />
                  <div className='circle circle--last' />
                </div>
                {renderRuleString(rule)}
              </div>
            </div>
          );
        }
        return (
          <div key={rule} className='outerbox' style={marginLeft}>
            <div className='ruleBox'>
              <div>
                <div className='line' />
                <div className='circle' />
                <div className='line' />
              </div>
              {renderRuleString(rule)}
            </div>
            <div className='conjunction'>{conjunction}</div>
          </div>
        );
      }
    });
  }

  renderGroup(group, depth) {
    const object = group.children1;
    const { conjunction } = group.properties;
    return this.renderRules(object, conjunction, depth);
  }

  renderCriteriaOrNullState() {
    if (this.props.criteria) {
      if (!this.props.criteria.filter_rules) {
        return <NullParagraph>No additional filters were added.</NullParagraph>;
      }
      return this.renderGroup(this.props.criteria.filter_json, 1);
    }
  }

  getSelectedBusinessesNames = () =>
    this.props.businesses.map(
      (selectedBusiness) =>
        this.props.businessLocationsOfMerchant.find(
          (globalBusiness) => globalBusiness.id === selectedBusiness
        ).name
    );

  render() {
    return (
      <div>
        <Label>Filter criteria</Label>
        <BusinessesWrapper>
          <div>
            <span style={{ fontWeight: '600' }}>Selected Businesses: </span>
            {this.props.businesses.length
              ? this.getSelectedBusinessesNames().join(', ')
              : 'All Businesses'}
          </div>
        </BusinessesWrapper>
        {this.renderCriteriaOrNullState()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  businessLocationsOfMerchant:
    state.businessLocationsList.businessLocationsOfMerchant,
});

export default connect(mapStateToProps)(YourCriteria);
