import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';
import CreateEditUserModalContainer from './CreateEditUser/CreateEditUserModalContainer';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr 1fr 50px;
  gird-gap: 0;
  margin: 0;
  padding: 7px 30px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr 1fr 50px;
  gird-gap: 0;
  margin: 0;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
  border-top: 1px solid #e1e1e1;
`;
const ModalRow = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  gird-gap: 0;
  margin: 0;
  padding: 7px 6px;
`;
const RoleName = styled.span`
  font-weight: 600;
  text-transform: capitalize;
`;

const MoreEllipsisIcon = styled(Icon)`
  font-size: 15px;
  color: #191818;
  &:hover {
    z-index: 10000;
    color: red;
    cursor: pointer;
  }
`;

const TextSearch = styled.div`
  padding: 0 30px 15px 30px;
  margin: 0;
`;

const OnHoverFeatures = styled.table`
  z-index: 999;
  position: absolute;
  background-color: white;
  display: block;
  right: 7%;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  & td {
    padding-right: 10px;
  }
`;

const RolesTable = (props) => {
  const roles = props.roles.headers;
  const {
    accounts,
    updateUser,
    postResendInvitation,
    authUser,
    businesses,
    isModal,
    toggleCreateUserModal,
  } = props;

  const [filterStates, setFilterStates] = useState({
    search: '',
    accounts: [],
  });
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const beautifyAccounts = (accounts) => {
    if (accounts.length) {
      return accounts.map((account) => ({
        ...account,
        modifiedRole: account.role
          .replace('ROLE', '')
          .replace(/_/g, ' ')
          .toLowerCase(),
      }));
    } else {
      return accounts.role.replace('ROLE', '').replace(/_/g, ' ').toLowerCase();
    }
  };

  useEffect(() => {
    setFilterStates({
      search: filterStates.search,
      accounts: beautifyAccounts(props.accounts),
    });
  }, [props]);

  const handleSearch = (e) => {
    const sanitizedSearchString = e.target.value.toLowerCase();
    setFilterStates({
      search: e.target.value,
      accounts: beautifyAccounts(props.accounts).filter(
        (account) =>
          account.username.toLowerCase().includes(sanitizedSearchString) ||
          beautifyAccounts(account)
            .toLowerCase()
            .includes(sanitizedSearchString)
      ),
    });
  };

  const toggleUser = (account) => {
    updateUser(account.id, { is_active: !account.is_active });
  };

  return (
    <div>
      <TextSearch>
        <TextField
          type='text'
          value={filterStates.search}
          variant='outlined'
          style={{ width: '100%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon className='fal fa-search' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <Icon
                  className='fas fa-times-circle'
                  style={{ color: '#193053' }}
                  onClick={() =>
                    setFilterStates({
                      search: '',
                      accounts: beautifyAccounts(props.accounts),
                    })
                  }
                />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleSearch(e)}
          placeholder='Search by user name or role'
          disabled={!props.accounts.length}
        />
      </TextSearch>
      <div style={{ borderLeft: 'none', borderRight: 'none' }}>
        <TableHeader>
          <div align='left'>Username</div>
          <div align='left'>Role</div>
          <div align='left'>Display Name</div>
          <div align='left'>Status</div>
          <div></div>
        </TableHeader>
        <div></div>
        {filterStates.accounts.map(
          (account, index) =>
            account.username !== authUser.username && (
              <TableRow key={index} onMouseLeave={() => setSubMenuOpen(false)}>
                <div>{account.username}</div>
                <div>
                  <RoleName>{account.modifiedRole}</RoleName>
                </div>
                <div>{account.display_name}</div>
                <div>{account.is_active ? 'ACTIVE' : 'INACTIVE'}</div>
                <div align='left' style={{ position: 'relative' }}>
                  {subMenuOpen !== account.id && (
                    <MoreEllipsisIcon
                      onMouseEnter={() => setSubMenuOpen(account.id)}
                      className='fas fa-ellipsis-v'
                    />
                  )}
                  {subMenuOpen === account.id && (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        position: 'absolute',
                        right: 0,
                        width: '300px',
                        borderRadius: '8px',
                        boxShadow:
                          'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                        zIndex: 2,
                        padding: '10px 20px',
                      }}
                    >
                      <ModalRow>
                        <Icon disabled className='fal fa-pencil' />
                        <span
                          onClick={() => {
                            setSubMenuOpen(false);
                            toggleCreateUserModal(account);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {'Edit Role'}
                        </span>
                      </ModalRow>
                      {account.is_password_set ? (
                        <ModalRow>
                          <Icon className='fa fa-check' />
                          <span style={{ cursor: 'default' }}>
                            {'Registration Complete'}
                          </span>
                        </ModalRow>
                      ) : (
                        <ModalRow>
                          <Icon disabled className='fal fa-envelope' />
                          <span
                            onClick={() => postResendInvitation(account)}
                            style={{ cursor: 'pointer' }}
                          >
                            {'Resend Invitation'}
                          </span>
                        </ModalRow>
                      )}
                    </div>
                  )}
                </div>
              </TableRow>
            )
        )}
      </div>
      {isModal && (
        <CreateEditUserModalContainer
          roles={roles}
          businesses={businesses}
          toggleCreateUserModal={toggleCreateUserModal}
        />
      )}
    </div>
  );
};

export default RolesTable;
