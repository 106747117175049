import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
const developmentFirebaseConfig = {
  apiKey: 'AIzaSyBlHQkKhaYL8k9EJMnk0y0_SCAqhTXdPOA',
  authDomain: 'develop-orders.firebaseapp.com',
  databaseURL: 'https://develop-orders-a6c93.firebaseio.com',
  projectId: 'develop-orders',
  storageBucket: 'develop-orders.appspot.com',
  messagingSenderId: '128462065576',
  appId: '1:128462065576:web:f1955dacfda39eef7e179d',
  measurementId: 'G-1TKR1H5360',
};
const stagingFirebaseConfig = {
  apiKey: 'AIzaSyCAdMaNffvBJZz6is15Yk9fnsXfblO1G8c',
  authDomain: 'staging-env-2.firebaseapp.com',
  databaseURL: 'https://staging-env-2-tracking.firebaseio.com',
  projectId: 'staging-env-2',
  storageBucket: 'staging-env-2.appspot.com',
  messagingSenderId: '1043032324683',
  appId: '1:1043032324683:web:527a86fc8221bc8f8a6bce',
  measurementId: 'G-R6HD0RG7JS',
};
const sandboxFirebaseConfig = {
  apiKey: 'AIzaSyC8znDoRlbeSXMFfwLS2HnZSNDzO89VCY8',
  authDomain: 'sandbox-env-2.firebaseapp.com',
  databaseURL: 'https://sandbox-env-2-tracking.firebaseio.com',
  projectId: 'sandbox-env-2',
  storageBucket: 'sandbox-env-2.appspot.com',
  messagingSenderId: '12880788970',
  appId: '1:12880788970:web:f5312b6a1d0676bc008693',
  measurementId: 'G-TYKD0JTQTS',
};

var productionFirebaseConfig = {
  apiKey: 'AIzaSyCojxPCDH0av-QAoyaNKT7JZdLfi_xU4mQ',
  authDomain: 'prod-basilboxcatering.firebaseapp.com',
  databaseURL: 'https://prod-basilboxcatering-tracking.firebaseio.com',
  projectId: 'prod-basilboxcatering',
  storageBucket: 'prod-basilboxcatering.appspot.com',
  messagingSenderId: '765866299489',
  appId: '1:765866299489:web:e1a01235981b0208071a20',
  measurementId: 'G-FWJMP1CC0B',
};
const firebaseConfig =
  ENV === 'development'
    ? developmentFirebaseConfig
    : ENV === 'staging'
    ? stagingFirebaseConfig
    : ENV === 'sandbox'
    ? sandboxFirebaseConfig
    : productionFirebaseConfig;
const secondDatabaseName =
  ENV === 'development'
    ? 'develop-orders-a6c93'
    : ENV === 'staging'
    ? 'test-firebase-php-86f70'
    : ENV === 'sandbox'
    ? 'sandbox-orders-tracker'
    : 'smoothcommerce-orders-tracker';
let app2 = null;
const initFirebase = () => {
  // firebase.initializeApp(firebaseConfig);
  app2 = firebase.initializeApp(firebaseConfig, secondDatabaseName);
  const firebaseToken = localStorage.getItem('firebase_token');
  if (firebaseToken) {
    app2
      .auth()
      .signInWithCustomToken(localStorage.getItem('firebase_token'))
      .then((userCredential) => {
        console.log('successful firebase sign in');
      })
      .catch((error) => {
        console.log('firebase token error:', error.message);
      });
  }
};
initFirebase();

export default app2;
