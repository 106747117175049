import React, { useEffect, useState, useCallback, Suspense, lazy } from 'react';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal';
import { formatLocations } from '../../../utils/childLocations/formatLocations';
import FilterButton from '../../../components/FilterModal/FilterButton';
import _, { filter } from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatedDate } from '../utils/getFormatedDate';

const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  datePiker: { height: '40px', backgroundColor: 'green' },
  button: {
    backgroundColor: '#2440ae',
    color: '#ffffff',
    maxHeight: '40px',
    width: '190px',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: '#3151d4',
    },
    '&:disabled': {
      backgroundColor: '#ebebeb',
      color: '#6f6f76',
    },
  },
}));

const Wrapper = styled.div`
  min-width: 1200px;
  overflow: auto;
`;
const Title = styled.div`
  height: 39px;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  margin: 25px 0 12px 0;
`;
const Subtitle = styled.div`
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
  margin-bottom: 32px;
`;
const SearchCard = styled.div`
  display: grid;
  grid-template-columns: 2.4fr 1fr;
  padding: 28px 18px 28px 32px;
  background-color: #ffffff;
  border-radius: 20px;
  grid-gap: 18px;
  margin-bottom: 24px;
  min-width: 1200px;
  overflow: auto;
`;
const ListWrapper = styled.div`
  border-radius: 20px;
  background-color: #ffffff;
  min-width: 1200px;
  overflow: auto;
`;
const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-gap: 15px;
`;
const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  /* background-color: yellow; */
`;
const ButtonWrapper = styled.div`
  /* display: flex;
  vertical-align: middle;
  text-align: center; */
  width: 100%;
  /* background-color: yellow; */
`;
export const SortDiv = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #2440ae;
`;
const LocationButton = styled.button`
  /* margin-left: 10px; */
  border-radius: 4px;
  padding: 7px 10px;
  cursor: pointer;
  background-color: #ffffff;
  color: #757575;
  border: 1px solid #c4c4c4;
  margin-bottom: 15px;
  width: 100%;
  &:hover {
    color: #000000;
    border: 1px solid #000000;
  }
`;
const FilterWrapper = styled.div`
  margin: ${(props) => props.margin || '15px 0 0 0'};
  padding: 0;
  /* background-color: yellow; */
`;
const InitialMessageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #6f6f76;
  justify-content: center;
  align-items: center;
`;

const SummaryReportList = ({
  allBusinesses,
  allOrderSummaries,
  requestParams,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  getOrderSummaries,
  setSelectedLocationSummary,
  exportHeaders,
  totalCheckbox,
  setTotalCheckbox,
  checkboxList,
  setCheckboxList,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  selectedBusinesses,
  setSelectedBusinesses,
  formatedBusinesses,
  setFormatedBusinesses,
  maxDueDate,
  setMaxDueDate,
  exprotData,
  setExportData,
  errorMessage,
  seterrorMessage,
  orderSource,
  formatedOrderSources,
  setFormatedOrderSources,
  selectedOrderSources,
  setSelectedOrderSources,
  selectedOrderSourcesValues,
  setSelectedOrderSourcesValues,
  orderTypesList,
  formatedOrderTypes,
  setFormatedOrderTypes,
  selectedOrderTypes,
  setSelectedOrderTypes,
  selectedOrderTypesValues,
  setSelectedOrderTypesValues,
  paymentTypesList,
  formatedPaymentTypes,
  setFormatedPaymentTypes,
  selectedPaymentTypes,
  setSelectedPaymentTypes,
  selectedPaymentTypesValues,
  setSelectedPaymentTypesValues,
  updateRequestParams,
  filteredLocations,
  setFilteredLocations,
}) => {
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false);
  const parent_child_clients = ['sodexo', 'feastify'];
  const classes = useStyles();
  const columnHeaders = [
    'checkbox',
    'Location',
    'Total Sales',
    'Expected Settlement/(Due)',
    'export',
  ];
  const columnNames = [
    'checkbox',
    'business_name',
    'total_sales',
    'expected_settlement',
    'export',
  ];
  const columnWidth = ['3%', '20%', '20%', '20%', '37%'];
  const columnTypes = ['checkbox', 'string', 'string', 'string', 'export'];
  const columnHeaderIsSortableList = [false, false, false, false, false];
  const searchField = '';
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  useEffect(() => {
    const currentClient = localStorage.getItem('appIdentifier');
    let newformatedOrderSources = orderSource && [...orderSource];
    let newformatedOrderTypes = orderTypesList && [...orderTypesList];
    let newformatedPaymentTypes = paymentTypesList && [...paymentTypesList];
    let formattedAllBusinesses = [];
    if (parent_child_clients.includes(currentClient)) {
      allBusinesses?.forEach((business) => {
        if (business.sub_businesses.length > 0) {
          business.sub_businesses.forEach((subBusiness) => {
            formattedAllBusinesses.push({
              id: subBusiness.id,
              display_name:
                subBusiness.display_name + ' - ' + business.display_name,
            });
          });
        }
      });
    } else {
      formattedAllBusinesses = allBusinesses?.map((business) => {
        return {
          id: business.id,
          display_name: business.display_name,
        };
      });
    }
    formattedAllBusinesses?.unshift({ id: 0, display_name: 'All Locations' });
    newformatedOrderSources?.unshift({
      label: 'All Order Sources',
      value: 'all',
    });
    newformatedOrderTypes?.unshift({
      value: 'all',
      type: 'All Order Types',
      is_loyalty_only_enabled: '',
    });
    if (newformatedPaymentTypes?.length > 0) {
      newformatedPaymentTypes?.unshift({
        id: 'all',
        type: 'All Payment Types',
      });
    }
    setFormatedBusinesses(formattedAllBusinesses);
    setFormatedOrderSources(newformatedOrderSources);
    setFormatedOrderTypes(newformatedOrderTypes);
    setFormatedPaymentTypes(newformatedPaymentTypes);
  }, [allBusinesses, orderSource, orderTypesList, paymentTypesList]);
  useEffect(() => {
    var newMaxDate = new Date(startDate);
    newMaxDate.setDate(newMaxDate.getDate() + 31);
    setMaxDueDate(newMaxDate);
  }, [startDate]);

  const allBusinessIds = (businesses) => {
    let newAllBusinessIds = [];
    businesses.forEach((business) => {
      business.id > 0 && newAllBusinessIds.push(business.id);
    });
    return newAllBusinessIds;
  };
  const hadleSelectedBusinessesIDs = (e, selectedBusinesses) => {
    const newSelectedBusinessIDs = selectedBusinesses.map(
      (business) => business.id
    );
    let currentSelectedBusinessIDs = [...selectedBusinessesIDs];
    if (newSelectedBusinessIDs.includes(0)) {
      const businessIds = allBusinessIds(formatedBusinesses);
      if (_.isEqual(currentSelectedBusinessIDs.sort(), businessIds.sort())) {
        currentSelectedBusinessIDs = [];
      } else {
        currentSelectedBusinessIDs = businessIds;
      }
    } else {
      currentSelectedBusinessIDs = newSelectedBusinessIDs;
    }
    setSelectedBusinessesIDs(currentSelectedBusinessIDs.sort());
  };
  // Order Source
  const hadleSelectedOrderSource = (e, selectedItems) => {
    let newSelectedOrderSources;
    let newSelectedOrderSourcesValues;
    if (selectedItems?.length === 0) {
      newSelectedOrderSourcesValues = [];
      newSelectedOrderSources = [];
      setSelectedOrderSources(newSelectedOrderSources);
      setSelectedOrderSourcesValues(newSelectedOrderSourcesValues);
    } else {
      const selectionValues = selectedItems?.map((item) => item.value);
      if (selectionValues.includes('all')) {
        if (selectionValues?.length > 1) {
          newSelectedOrderSourcesValues = [];
          newSelectedOrderSources = [];
        } else {
          newSelectedOrderSources = [...orderSource];
          newSelectedOrderSourcesValues = orderSource.map(
            (source) => source.value
          );
        }
      } else {
        newSelectedOrderSources = selectedItems.map((item) => item);
        newSelectedOrderSourcesValues = selectedItems.map((item) => item.value);
      }
      setSelectedOrderSources(newSelectedOrderSources);
      setSelectedOrderSourcesValues(newSelectedOrderSourcesValues);
    }
  };
  // Order Types
  const hadleSelectedOrderType = (e, selectedItems) => {
    let newSelectedOrderTypes;
    let newSelectedOrderTypesValues;
    if (selectedItems?.length === 0) {
      newSelectedOrderTypesValues = [];
      newSelectedOrderTypes = [];
      setSelectedOrderTypes(newSelectedOrderTypes);
      setSelectedOrderTypesValues(newSelectedOrderTypesValues);
    } else {
      const selectionValues = selectedItems?.map((item) => item.value);
      if (selectionValues.includes('all')) {
        if (selectionValues?.length > 1) {
          newSelectedOrderTypesValues = [];
          newSelectedOrderTypes = [];
        } else {
          newSelectedOrderTypes = [...orderTypesList];
          newSelectedOrderTypesValues = orderTypesList?.map(
            (item) => item.type
          );
        }
      } else {
        newSelectedOrderTypes = selectedItems?.map((item) => item);
        newSelectedOrderTypesValues = selectedItems?.map((item) => item.type);
      }
      setSelectedOrderTypes(newSelectedOrderTypes);
      setSelectedOrderTypesValues(newSelectedOrderTypesValues);
    }
  };
  // payment type
  const hadleSelectedPaymentType = (e, selectedItems) => {
    let newSelectedPaymentTypes;
    let newSelectedPaymentTypesValues;
    if (selectedItems?.length === 0) {
      newSelectedPaymentTypesValues = [];
      newSelectedPaymentTypes = [];
      setSelectedPaymentTypes(newSelectedPaymentTypes);
      setSelectedPaymentTypesValues(newSelectedPaymentTypesValues);
    } else {
      const selectionValues = selectedItems?.map((item) => item.id);
      if (selectionValues.includes('all')) {
        if (selectionValues?.length > 1) {
          newSelectedPaymentTypesValues = [];
          newSelectedPaymentTypes = [];
        } else {
          newSelectedPaymentTypes = [...paymentTypesList];
          newSelectedPaymentTypesValues = paymentTypesList?.map(
            (item) => item.type
          );
        }
      } else {
        newSelectedPaymentTypes = selectedItems?.map((item) => item);
        newSelectedPaymentTypesValues = selectedItems?.map((item) => item.type);
      }
      setSelectedPaymentTypes(newSelectedPaymentTypes);
      setSelectedPaymentTypesValues(newSelectedPaymentTypesValues);
    }
  };

  //
  // const updateSelcetedBusinesses = useCallback(() => {
  //   let newSelectedBusinesses = (newSelectedBusinesses =
  //     formatedBusinesses?.filter((business) =>
  //       selectedBusinessesIDs.includes(business.id)
  //     ));
  //   setSelectedBusinesses(newSelectedBusinesses);
  // }, [selectedBusinessesIDs]);
  // useEffect(() => {
  //   updateSelcetedBusinesses();
  // }, [updateSelcetedBusinesses]);
  const handleGetOrderSummaries = () => {
    const selectedStartDate = formatedDate(startDate, '-', true);
    const selectedEndDate = formatedDate(endDate, '-', true);
    const formatedSelectedOrderSourcesValues = selectedOrderSourcesValues.map(
      (source) =>
        source !== 'web' && source !== 'mobile' && source !== 'generic'
          ? 'scan'
          : source.toLowerCase()
    );
    const formatedSelectedOrderTypesValues = selectedOrderTypesValues.map(
      (type) =>
        type === 'Drop off'
          ? 'drop_off'
          : type === 'Scan and go'
          ? 'scan_and_go'
          : type.toLowerCase()
    );
    const formatedSelectedPaymentTypesValues = selectedPaymentTypesValues.map(
      (type) => type.toLowerCase()
    );
    getOrderSummaries(
      selectedStartDate,
      selectedEndDate,
      selectedBusinessesIDs,
      formatedSelectedOrderSourcesValues,
      formatedSelectedOrderTypesValues,
      formatedSelectedPaymentTypesValues
    );
  };
  const handleCheckboxSelect = (items) => {
    let newSelectedExportSummaries = [];
    items.forEach((status, index) => {
      if (status) {
        newSelectedExportSummaries.push(allOrderSummaries[index]);
      }
    });
    setExportData(newSelectedExportSummaries);
  };
  const handleClickRow = (selectedRow) => {
    setSelectedLocationSummary(selectedRow);
  };
  const handleError = (errorMessage) => {
    seterrorMessage(errorMessage);
  };

  return (
    <Wrapper>
      <Title>Transaction Summary Report</Title>
      <Subtitle>
        A summary of all transactions for the location(s) and date range
        selected. Each report will be based on the local timezone of the
        location(s).
      </Subtitle>
      <SearchCard>
        <SearchWrapper>
          <div>
            <DatePickerWrapper>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  autoOk
                  disableFuture
                  variant='inline'
                  inputVariant='outlined'
                  label='Start Date'
                  format='dd/MM/yyyy'
                  placeholder='DD/MM/YYYY'
                  value={startDate}
                  InputAdornmentProps={{ position: 'end' }}
                  onChange={(date) => setStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{ width: '100%' }}
                  size='small'
                  onError={handleError}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableFuture
                  disabled={!startDate}
                  variant='inline'
                  inputVariant='outlined'
                  label='End Date'
                  format='dd/MM/yyyy'
                  placeholder='DD/MM/YYYY'
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  style={{ width: '100%' }}
                  size='small'
                  minDate={startDate}
                  minDateMessage={`End Date should not be before start date: ${startDate
                    ?.toString()
                    .substring(4, 16)}`}
                  maxDate={maxDueDate}
                  maxDateMessage={`End Date should not be more than 1 month after start date: ${startDate
                    ?.toString()
                    .substring(4, 16)}`}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    padding: '0',
                    height: '14px',
                    color: 'blue',
                    display: 'none',
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                    backgroundColor: 'red',
                  }}
                  onError={handleError}
                />
              </MuiPickersUtilsProvider>
            </DatePickerWrapper>
            {formatedOrderTypes?.length > 0 && (
              <FilterWrapper>
                <Autocomplete
                  id='type'
                  multiple
                  limitTags={3}
                  size='small'
                  options={formatedOrderTypes}
                  disableCloseOnSelect
                  getOptionLabel={(item) =>
                    item.type?.charAt(0).toUpperCase() + item.type?.slice(1)
                  }
                  value={selectedOrderTypes}
                  onChange={(e, selectedItems) =>
                    hadleSelectedOrderType(e, selectedItems)
                  }
                  renderOption={(item, option) => (
                    <div
                      style={{
                        fontWeight: item.value === 0 ? 'bold' : 'normal',
                      }}
                    >
                      <Checkbox
                        color='primary'
                        style={{
                          marginRight: '5px',
                          transform: 'scale(0.9)',
                        }}
                        checked={
                          selectedOrderTypesValues?.length ===
                          orderTypesList?.length
                            ? true
                            : selectedOrderTypesValues?.includes(item.id)
                            ? true
                            : option.selected
                        }
                      />
                      {item.type?.charAt(0).toUpperCase() + item.type?.slice(1)}
                    </div>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Order Type'
                      variant='outlined'
                      placeholder=''
                    />
                  )}
                  disabled={false}
                />
              </FilterWrapper>
            )}
          </div>
          <div>
            {formatedOrderSources?.length > 0 && (
              <FilterWrapper margin='0px'>
                <Autocomplete
                  id='source'
                  multiple
                  limitTags={3}
                  size='small'
                  options={formatedOrderSources}
                  disableCloseOnSelect
                  getOptionLabel={(item) =>
                    item.label?.charAt(0).toUpperCase() + item.label?.slice(1)
                  }
                  value={selectedOrderSources}
                  onChange={(e, selectedItems) =>
                    hadleSelectedOrderSource(e, selectedItems)
                  }
                  renderOption={(item, option) => (
                    <div
                      style={{
                        fontWeight: item.value === 0 ? 'bold' : 'normal',
                      }}
                    >
                      <Checkbox
                        color='primary'
                        style={{
                          marginRight: '5px',
                          transform: 'scale(0.9)',
                        }}
                        name={item.value}
                        checked={
                          selectedOrderSourcesValues?.length ===
                          orderSource?.length
                            ? true
                            : selectedOrderSourcesValues?.includes(item.value)
                            ? true
                            : option.selected
                        }
                      />
                      {item.label !== 'web' &&
                      item.label !== 'mobile' &&
                      item.label !== 'generic' &&
                      item.value !== 'all'
                        ? 'Scan'
                        : item.label?.charAt(0).toUpperCase() +
                          item.label?.slice(1)}
                    </div>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Order Source'
                      variant='outlined'
                      placeholder=''
                    />
                  )}
                  clearIcon={<i className='fa fa-times'></i>}
                  disabled={false}
                />
              </FilterWrapper>
            )}
            {formatedPaymentTypes?.length > 0 && (
              <FilterWrapper margin={!formatedOrderSources ? '0 0 0 0' : null}>
                <Autocomplete
                  id='type'
                  multiple
                  limitTags={3}
                  size='small'
                  options={formatedPaymentTypes}
                  disableCloseOnSelect
                  getOptionLabel={(item) => item.type}
                  value={selectedPaymentTypes}
                  onChange={(e, selectedItems) =>
                    hadleSelectedPaymentType(e, selectedItems)
                  }
                  renderOption={(item, option) => (
                    <div
                      style={{
                        fontWeight: item.value === 0 ? 'bold' : 'normal',
                      }}
                    >
                      <Checkbox
                        color='primary'
                        style={{
                          marginRight: '5px',
                          transform: 'scale(0.9)',
                        }}
                        checked={
                          selectedPaymentTypesValues?.length + 1 ===
                          formatedPaymentTypes?.length
                            ? true
                            : selectedPaymentTypesValues?.includes(item.id)
                            ? true
                            : option.selected
                        }
                      />
                      {item.type}
                    </div>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Payment Type'
                      variant='outlined'
                      placeholder=''
                    />
                  )}
                  disabled={false}
                />
              </FilterWrapper>
            )}
          </div>
        </SearchWrapper>
        <ButtonWrapper>
          <div style={{ marginBottom: '17px' }}>
            <FilterButton
              message={
                selectedBusinessesIDs?.length === allBusinesses.length
                  ? 'All Locations Selected'
                  : `Selected Locations (${selectedBusinessesIDs?.length})`
              }
              setIsOpen={setIsLocationFilterOpen}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              className={classes.button}
              disabled={
                !(
                  startDate &&
                  endDate &&
                  selectedBusinessesIDs?.length > 0 &&
                  selectedOrderSourcesValues?.length > 0 &&
                  selectedOrderTypesValues?.length > 0 &&
                  selectedPaymentTypesValues?.length > 0 &&
                  !errorMessage
                )
              }
              onClick={handleGetOrderSummaries}
              style={{ margin: 'auto 0' }}
            >
              Generate Report
            </Button>
          </div>
        </ButtonWrapper>
      </SearchCard>
      <ListWrapper>
        {allOrderSummaries?.length > 0 && (
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allOrderSummaries}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              searchField={searchField}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'summaryReport'}
              handleClickRow={handleClickRow}
              handleCheckboxSelect={handleCheckboxSelect}
              checkboxId={'business_name'}
              exportHeaders={exportHeaders}
              exprotData={exprotData}
              exportFileName={'Transaction_Summaries'}
              totalCheckbox={totalCheckbox}
              setTotalCheckbox={setTotalCheckbox}
              checkboxList={checkboxList}
              setCheckboxList={setCheckboxList}
            />
          </Suspense>
        )}
      </ListWrapper>
      {!allOrderSummaries && (
        <InitialMessageWrapper>
          Select a date range and location to generate a report.
        </InitialMessageWrapper>
      )}
      {allOrderSummaries?.length === 0 && (
        <InitialMessageWrapper>
          No transactions to show. Please adjust your selections.
        </InitialMessageWrapper>
      )}
      <Dialog open={isLocationFilterOpen} fullWidth maxWidth='md'>
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={filteredLocations}
          setFilteredLocations={setFilteredLocations}
          isOpen={isLocationFilterOpen}
          setIsOpen={setIsLocationFilterOpen}
          emptyIsEqualToAll={false}
          isEmptyAccepted={false}
        />
      </Dialog>
    </Wrapper>
  );
};

export default SummaryReportList;
