import React from 'react';
import { connect } from 'react-redux';
import BusinessLocationsListContainer from './LocationListManager/BusinessLocationsListContainer';
import BusinessWizardContainer from './LocationDetailsManager/BusinessWizard/BusinessWizardContainer';
import { withRouter } from 'react-router';

let BusinessLocationsContainer = (props) => {
  const isBusinessAdminRole =
    props.role === 'ROLE_BUSINESS_ADMIN' ||
    localStorage.getItem('role') === 'ROLE_BUSINESS_ADMIN';
  return !props.isCreatingLocation && !props.isEditingLocation ? (
    <BusinessLocationsListContainer
      {...props}
      isBusinessAdminRole={isBusinessAdminRole}
    />
  ) : (
    <BusinessWizardContainer isBusinessAdminRole={isBusinessAdminRole} />
  );
};

const mapStateToProps = (state) => ({
  role: state.accountReducer.role,
  isCreatingLocation: state.businessLocationDetails.isCreatingLocation,
  isEditingLocation: state.businessLocationDetails.isEditingLocation,
});

BusinessLocationsContainer = withRouter(BusinessLocationsContainer);
export default connect(mapStateToProps)(BusinessLocationsContainer);
