import { getBusinessLocationsOfMerchant } from '../BusinessLocationDetails/LocationListManager/actions';
import { getSegments } from '../Segmentation/segmentationActions';
import { getTags } from '../Tags/tagsActions';
import { getOptions } from '../MenuManagement/OptionManager/optionActions';
import { getVariants } from '../MenuManagement/Variants/variantsActions';
import {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_BUSINESS_ADMIN,
  ROLE_MARKETING_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_LOYALTY_ADMIN,
} from '../../utils/rolesRepository';
import { getOffers } from '../Engagement/Offers/offerActions';
import { getOffersEngine } from '../OfferEngine/OfferEngineActions';
import { getCombos } from '../MenuManagement/CombosManager/combosAction';
import {
  SET_PROMOTE_TIER_UPGRADE_MODAL_IS_VISIBLE,
  GET_ORDER_TYPES_LIST,
  GET_TAX_CATEGORY_LIST_SUCCESS,
  GET_LANGUAGES_CONFIG_SUCCESS,
  GET_MISSING_POS_MAPPINGS_SUCCESS,
} from './fullTypes';
import api from '../../api';
import { newOfferEngineClients } from '../../utils/clientCustomizations';
export { promptReauth, signoutUser, softSignoutUser } from '../Account/actions'; // basically like a copy paste to this file
export function onAppLoad() {
  const newOfferClients = newOfferEngineClients;
  let appIdentifier = localStorage.getItem('appIdentifier');
  return function (dispatch, getState) {
    const { role } = getState().accountReducer;
    if (
      [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_BUSINESS_ADMIN,
        ROLE_MARKETING_ADMIN,
        ROLE_FINANCE_ADMIN,
        ROLE_LOYALTY_ADMIN,
      ].includes(role)
    ) {
      dispatch(getMissingCombosPOSMapping());
      dispatch(getBusinessLocationsOfMerchant());
      dispatch(getOffers());
      if (newOfferClients.includes(appIdentifier)) {
        dispatch(
          getOffersEngine({
            page_number: 1,
            page_size: '1000',
            sorting_option: 'title-asc',
            search_string: '',
            has_next_page: true,
          })
        );
      } else {
        dispatch(getOffers());
      }
      if (role !== ROLE_MARKETING_ADMIN) dispatch(getOrderTypesList());
    }
    if ([ROLE_BUSINESS_ADMIN, ROLE_FINANCE_ADMIN].includes(role)) {
      dispatch(getOffers());
      if (newOfferClients.includes(appIdentifier)) {
        dispatch(
          getOffersEngine({
            page_number: 1,
            page_size: '1000',
            sorting_option: 'title-asc',
            search_string: '',
            has_next_page: true,
          })
        );
      } else {
        dispatch(getOffers());
      }
      dispatch(getOrderTypesList());
    }
    if ([ROLE_MARKETING_ADMIN].includes(role)) {
      dispatch(
        getVariants({
          page_number: 1,
          page_size: '300',
          sorting_option: 'title-asc',
          search_string: '',
          has_next_page: true,
        })
      );
    }
    if (
      [
        ROLE_SUPER_ADMIN,
        ROLE_ADMIN,
        ROLE_MARKETING_ADMIN,
        ROLE_LOYALTY_ADMIN,
      ].includes(role)
    ) {
      dispatch(getSegments());
      if (newOfferClients.includes(appIdentifier)) {
        dispatch(
          getOffersEngine({
            page_number: 1,
            page_size: '1000',
            sorting_option: 'title-asc',
            search_string: '',
            has_next_page: true,
          })
        );
      } else {
        dispatch(getOffers());
      }
    }
    if ([ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_MARKETING_ADMIN].includes(role)) {
      dispatch(getTags());
    }
    if ([ROLE_SUPER_ADMIN, ROLE_ADMIN].includes(role)) {
      dispatch(
        getOptions({
          page_number: 1,
          page_size: '2000',
          sorting_option: 'title-asc',
          search_string: '',
        })
      );
      dispatch(getCombos());
      dispatch(
        getVariants({
          page_number: 1,
          page_size: '300',
          sorting_option: 'title-asc',
          search_string: '',
          has_next_page: true,
        })
      );
      dispatch(getOrderTypesList());
      dispatch(getTaxCategoryList());
    }
    dispatch(getLanguagesConfig());
  };
}

export function setPromoteTierUpgradeModalIsVisible(isVisible) {
  return {
    type: SET_PROMOTE_TIER_UPGRADE_MODAL_IS_VISIBLE,
    isVisible: isVisible,
  };
}

export function getOrderTypesList() {
  return function (dispatch, getState) {
    let orderTypeDisabled = [];
    if (getState().accountReducer.appMetaData) {
      // const config = getState().accountReducer.appMetaData.configuration;
      // if (!config.is_delivery_enabled) orderTypeDisabled.push('delivery');
      // let orderTypesList = [];
      // if (!orderTypeDisabled.length) {
      //   orderTypesList.push({ id: '[1,2]', type: 'Pickup and Delivery' });
      // }

      api.get(`orders/types`).then((response) => {
        let orderTypesList = response.data.data.map((orderType) => {
          return {
            ...orderType,
            type: (
              orderType.type.charAt(0).toUpperCase() + orderType.type.slice(1)
            ).replaceAll('_', ' '),
          };
        });

        dispatch({
          type: GET_ORDER_TYPES_LIST,
          orderTypesList: orderTypesList,
        });
      });
    }
  };
}

export function getTaxCategoryList() {
  return function (dispatch) {
    return api.get(`/tax-categories`).then(
      (response) => {
        dispatch({
          type: GET_TAX_CATEGORY_LIST_SUCCESS,
          taxCategoryList: response.data.data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getLanguagesConfig() {
  return function (dispatch) {
    api.get(`/languages`).then((response) => {
      dispatch({
        type: GET_LANGUAGES_CONFIG_SUCCESS,

        languagesConfig: response.data.data,
      });
    });
  };
}
export function getMissingCombosPOSMapping() {
  return function (dispatch) {
    const role = localStorage.getItem('role');
    if (['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(role))
      return Promise.all([
        api.get(`/discount-mapping/missing/combo-item`),
        api.get(`/discount-mapping/missing/combo`),
        api.get(`/pos-mapping/missing/item`),
        api.get(`/pos-mapping/missing/product`),
      ])
        .then((response) => {
          dispatch({
            type: GET_MISSING_POS_MAPPINGS_SUCCESS,
            missingComboItemPOSMappings: response[0]?.data.data,
            missingComboPOSMappings: response[1]?.data.data,
            missingItemPOSMappings: response[2]?.data.data,
            missingProductPOSMappings: response[3]?.data.data,
          });
        })
        .catch((error) => console.log(error));
  };
}
