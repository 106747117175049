import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './segmentationActions';
import * as selectors from './segmentationSelectors';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TdFlex,
  EmptyStateMessage,
  MoreEllipsisIcon,
  TableWrapper,
} from 'merchant-portal-components';
import { Button, ButtonRow } from 'merchant-portal-components';
import styled from 'styled-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import CreateSegmentWizard from './CreateSegmentWizard/CreateSegmentWizard';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import exportCSVWrapper from '../HOCs/exportCSVWrapper';
import PaginationControl from '../../components/Pagination/PaginationControl';
import ConfirmDeleteModal from '../../components/Modal/ConfirmDeleteModal';
import Icon, { EditIcon, DeleteIcon } from '../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { sort, sortByTypes, STRING } from '../../utils/sort/sort';
import { getProducts } from '../MenuManagement/Products/productsActions';
import ReactGA from 'react-ga';
import { isOnlyLoyaltyAdminUser } from '../../utils/appRoles';
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;
const PaginationWrapper = styled.div`
  padding: 25px 37px;
  display: flex;
  justify-content: right;
`;
const fields = {
  // first_name                     : 'first name',
  // last_name                      : 'last name',
  // email                          : 'email',
  // address                        : 'address',
  // postal_code                    : 'postal code',
  // average_transactions_per_month : 'average transactions per month',
  total_transactions: 'total transactions',
  total_spend: 'total spent',
  average_spend: 'average spent',
  average_transactions: 'average transactions',
  first_transaction_date: 'first transaction date',
  last_transaction_date: 'last transaction date',
  loyalty_credit_earned: 'loyalty credit earned',
  loyalty_credit_redeemed: 'loyalty credit redeemed',
  coupon_credit_earned: 'coupon credit earned',
  coupon_credit_redeemed: 'coupon credit redeemed',
  global_credit_earned: 'global credit earned',
  global_credit_redeemed: 'global credit redeemed',
  offer_credit_earned: 'offer credit earned',
  offer_credit_redeemed: 'offer credit redeemed',
  points_earned: 'points earned',
  points_redeemed: 'points redeemed',
  milestones_reached: 'milestones reached',
  email: 'email',
  created_at: 'registration date',
  birthdate: 'birthdate',
  total_dollars_saved: 'Total Dollars Saved',
  products_purchased: 'Product Purchased',
  next_birthday: 'Next Birthday',
  last_birthday: 'Last Birthday',
};

const operators = {
  select_equals: 'equals',
  equal: 'equals',
  not_equal: 'is not equal to',
  begins_with: 'begins with',
  does_not_begins_with: 'does not begin with',
  contains: 'contains',
  not_contains: 'does not contain',
  ends_with: 'ends with',
  not_ends_with: 'does not end with',
  less: 'is less than',
  less_or_equal: 'is less than or equal to',
  greater: 'is greater than',
  greater_or_equal: 'is greater than or equal to',
  between: 'is between',
  not_between: 'not between',
  is_empty: 'is blank',
  is_not_empty: 'is not blank',
  'is/was': 'is/was',
  relatively_before: 'is relatively before',
  relatively_after: 'is relatively after',
  relatively_between: 'is relatively between',
};

const flatten = (arr) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
};

const convertValue = (value) => {
  return '';
  // return relativeDates[value] ? relativeDates[value] : value;
};

class ListSegment extends Component {
  state = {
    segmentIdToDelete: null,
    searchString: '',
    allSegmentsLength: '',
  };

  componentDidMount() {
    track.page('engagement-segmentation');
    if (
      !this.props.segments.mailchimpLists &&
      !isOnlyLoyaltyAdminUser(this.props.appConfiguration)
    ) {
      this.props.getMailChimpLists();
    }
    this.props.setLocations(this.props.allBusinesses);
    if (!isOnlyLoyaltyAdminUser(this.props.appConfiguration)) {
      this.props.getProducts({
        page_number: 1,
        page_size: '4000',
        sorting_option: 'title-asc',
        search_string: '',
        has_next_page: true,
      });
    }
    ReactGA.pageview(window.location.pathname, undefined, 'Segment List');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.allBusinesses !== this.props.allBusinesses ||
      (this.props.allBusinesses && !this.props.segments.selectedLocations)
    ) {
      this.props.setLocations(this.props.allBusinesses);
    }
    if (!prevProps.token && this.props.token && !this.props.allSegments) {
      // this.props.getSegments();
    }
    if (
      !prevProps.token &&
      this.props.token &&
      !this.props.segments.mailchimpLists
    ) {
      this.props.getMailChimpLists();
    }
    if (this.state.segmentIdToDelete) {
      if (
        prevProps.segments.isDeleting.includes(this.state.segmentIdToDelete) &&
        !this.props.segments.isDeleting.includes(this.state.segmentIdToDelete)
      ) {
        this.setState({ segmentIdToDelete: null });
      }
    }
    if (
      prevProps.segments.requestingCreateSegment &&
      prevProps.segments.createSegment &&
      !this.props.segments.requestingCreateSegment &&
      !this.props.segments.createSegment
    ) {
      this.props.getSegments();
    }
    if (
      prevProps.segments.requestingCreateSegment &&
      prevProps.segments.editSegment &&
      !this.props.segments.requestingCreateSegment &&
      !this.props.segments.editSegment
    ) {
      this.props.getSegments();
    }
  }

  renderGroup = (group, depth) => {
    const object = group.children1;
    const { conjunction } = group.properties;
    let rules = this.renderRules(object, conjunction, depth);

    let filteredRules = [...new Set(rules)];

    return filteredRules;
  };

  renderRules = (rules, conjunction, depth) => {
    const renderRuleString = (rule) => {
      let str = [];
      if (rules[rule].properties.field === 'tags') {
        const tagId = rules[rule].properties.value[0];
        const tag = this.props.allTags.find((tag) => tag.tag_id == tagId);

        str.push(`${fields[rules[rule].properties.field]}`);
      } else if (rules[rule].properties.field === 'products_purchased') {
        const productId = rules[rule].properties.value[0];
        const product = this.props.allProducts.find(
          (product) => product.id == productId
        );

        str.push(`${fields[rules[rule].properties.field]}`);
      } else {
        str.push(
          `${fields[rules[rule].properties.field]} ${
            rules[rule].properties.value.length == 1
              ? convertValue(rules[rule].properties.value[0])
              : rules[rule].properties.value.reduce((a, b) => {
                  if (b) {
                    return convertValue(a) + ' AND ' + convertValue(b);
                  } else {
                    return convertValue(a);
                  }
                })
          }`
        );
      }

      return str;
    };

    return Object.keys(rules).map((rule, index) => {
      if (rules[rule].type === 'group') {
        return this.renderGroup(rules[rule], depth + 1);
      }
      if (rules[rule].properties.field) {
        if (index === 0) {
          return `${renderRuleString(rule)} ${conjunction}`;
        }
        if (Object.keys(rules).length === index + 2) {
          return `${renderRuleString(rule)}`;
        }
        return `${renderRuleString(rule)} ${conjunction}`;
      }
    });
  };

  initiateEditSegment(segment) {
    if (!this.props.requestingGetSegments) {
      if (!segment.audience.zone) {
        this.props.initiateEditSegment({
          ...segment,
          audience: { ...segment.audience, zone: 'all_users' },
        });
      } else {
        this.props.initiateEditSegment(segment);
      }
      // this.props.initiateEditSegment(segment);
    }
  }

  deleteSegment(segment_id) {
    if (!this.props.requestingGetSegments) {
      this.props.deleteSegment(segment_id);
    }
  }

  exportSegment(segment) {
    if (!this.props.requestingGetSegments) {
      this.props.exportSegment(segment.segment_id);
    }
  }

  renderTrashOrLoadingIcon(segment) {
    if (this.props.segments.isDeleting.includes(segment.segment_id)) {
      return (
        <Tooltip title='Locked'>
          <Icon className='fal fa-spinner-third fa-spin' />
        </Tooltip>
      );
    }
    return (
      <Tooltip title='Delete'>
        <div>
          <DeleteIcon
            onClick={() =>
              this.setState({ segmentIdToDelete: segment.segment_id })
            }
          />
        </div>
      </Tooltip>
    );
  }

  initiateCloneSegment(segment) {
    if (!this.props.requestingGetSegments) {
      if (!segment.audience.zone) {
        this.props.initiateCloneSegment({
          ...segment,
          audience: { ...segment.audience, zone: 'all_users' },
        });
      } else {
        this.props.initiateCloneSegment(segment);
      }
      // this.props.initiateEditSegment(segment);
    }
  }

  renderCopyIcon(segment) {
    return (
      <Tooltip title='Copy'>
        <Icon
          className='far fa-copy'
          onClick={() => this.initiateCloneSegment(segment)}
        />
      </Tooltip>
    );
  }

  renderFileOrLoadingIcon(segment) {
    if (this.props.isDownloading.includes(segment.segment_id)) {
      return <Icon className='fal fa-spinner-third fa-spin' />;
    }
    return (
      <Tooltip
        title={
          segment.isAvailable
            ? 'Download'
            : `Please wait about ${segment.minutesLeft} more minutes`
        }
      >
        <Icon
          className='fal fa-download'
          style={{ color: !segment.isAvailable ? '#b0b0b0' : null }}
          onClick={() => {
            segment.isAvailable &&
              this.props.exportCSV({
                requestPath: `segments/${segment.segment_id}/customers`,
                fileName: `${segment.name}`,
                requestParams: ``,
                id: segment.segment_id,
              });
          }}
        />
      </Tooltip>
    );
  }
  ///segments/3/customers

  renderFrequency(mailchimp) {
    if (mailchimp) {
      switch (mailchimp.sync_frequency) {
        case 'hourly':
          return ' Hourly';
        case 'daily':
          return ' Daily';
        case 'weekly':
          return ' Weekly';
        case 'monthly':
          return ' Monthly';
        default:
          return '';
      }
    }
  }

  renderZone(audience) {
    switch (audience) {
      case 'live_in_zone':
        return 'Users with a delivery address in this zone';
      case 'live_outside_zone':
        return 'Users with a delivery address outside this zone';
      case 'last_located_in_zone':
        return 'Users last located inside this zone';
      case 'last_located_outside_zone':
        return 'Users last located outside this zone';
      case 'purchase_in_zone':
        return 'Purchased in area';
      case 'purchase_outside_zone':
        return 'Purchased outside area';
      default:
        return 'All Areas';
    }
  }

  handleSegmentsSearch = (searchString) => {
    this.props.updateSegmentsRequestParams({
      page_number: 1,
      page_size: this.props.segments.requestParams.page_size,
    });
    this.setState({ searchString: searchString });
  };
  handleClearSearch = () => {
    this.setState({ searchString: '' });
  };
  handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray =
      this.props.segments.requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (this.props.allSegments) {
      const result = sort(this.props.allSegments, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      this.props.updateSegmentsRequestParams({
        page_number: 1,
        page_size: this.props.segments.requestParams.page_size,
      });
      this.props.updateSortedSegmentsList(result, sortOrder);
    }
  };

  renderRows(segmentsData) {
    //let serialNumber = 0;
    if (segmentsData.length > 0) {
      return segmentsData.map((segment, index) => {
        let str;

        if (segment.filters.filter_json) {
          str = flatten(this.renderGroup(segment.filters.filter_json, 1)).join(
            ' '
          );

          if (['and', 'or'].includes(str.slice(-3).trim().toLowerCase())) {
            str = str.slice(0, -3);
          }
        }
        return (
          <Tr
            style={{ height: '75px', cursor: 'pointer' }}
            key={index}
            onClick={() => this.initiateEditSegment(segment)}
          >
            <Td bold>{segment.name}</Td>
            <Td>{!str ? 'No Filter' : str}</Td>
            <Td style={{ padding: '0 0 0 7', textAlign: 'left' }}>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                {this.renderZone(segment.audience.zone)}{' '}
              </div>
            </Td>
            <Td style={{ padding: '0 0 0 7', textAlign: 'left' }}>
              <div style={{ display: 'flex', justifyContent: 'left' }}>
                {segment.integration == 'none'
                  ? 'None'
                  : segment.is_overwrite
                  ? 'Replace'
                  : 'Merge'}
                {/* {this.renderFrequency(segment.mailchimp)} */}
              </div>
            </Td>
            <Td
              style={{ padding: '0 5', pointerEvents: 'none' }}
              align='center'
              onClick={(e) => e.stopPropagation()}
            >
              {segment.segment_id && (
                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'space-between',
                    gridAutoFlow: 'column',
                    gridGap: '16px',
                    alignItems: 'center',
                  }}
                >
                  {this.renderFileOrLoadingIcon(segment)}
                  {this.renderCopyIcon(segment)}
                  {this.renderTrashOrLoadingIcon(segment)}
                </div>
              )}
            </Td>
          </Tr>
        );
      });
    } else {
      return (
        <Tr>
          <Td colSpan='6' padding='50px 0' align='center'>
            {' '}
            No matching data{' '}
          </Td>
        </Tr>
      );
    }
  }

  render() {
    if (this.props.segments.editSegment) {
      return (
        <CreateSegmentWizard edit allBusinesses={this.props.allBusinesses} />
      );
    } else if (this.props.segments.createSegment) {
      return <CreateSegmentWizard allBusinesses={this.props.allBusinesses} />;
    } else if (this.props.segments.cloneSegment) {
      return (
        <CreateSegmentWizard clone allBusinesses={this.props.allBusinesses} />
      );
    }
    const { allSegments, appConfiguration } = this.props;
    const { requestParams } = this.props.segments;
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;
    let segmentsData = [];
    if (allSegments) {
      segmentsData = allSegments
        .filter(
          (segment) =>
            segment.name
              .toLowerCase()
              .indexOf(this.state.searchString.toLowerCase()) > -1
        )
        .slice(indexOfFirst, indexOfLast);
    }
    const isLoyaltyOnly = isOnlyLoyaltyAdminUser(appConfiguration);
    const diplaySegments =
      (isLoyaltyOnly && allSegments) ||
      (!isLoyaltyOnly &&
        allSegments &&
        this.props.allTags &&
        this.props.allProducts);
    return (
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
          <BreadCrumb pageName='Segments' />
        </div>
        {diplaySegments ? (
          <TableWrapper>
            <TopDiv>
              <div>
                <TextField
                  type='text'
                  style={{ width: '100%' }}
                  variant='outlined'
                  value={this.state.searchString}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icon className='fal fa-search' />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='start'>
                        <Icon
                          className='fas fa-times-circle'
                          style={{ color: '#193053' }}
                          onClick={this.handleClearSearch}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => this.handleSegmentsSearch(e.target.value)}
                  placeholder='Search segments'
                />
              </div>
              <Button
                style={{ fontSize: 'initial' }}
                onClick={() => this.props.initiateCreateSegment()}
              >
                Create Segment
              </Button>
            </TopDiv>
            <Table noBorder>
              <thead>
                <TrHead style={{ background: 'none' }}>
                  {/* <Th align="left">Name</Th> */}
                  <Th
                    align='left'
                    onClick={() => {
                      this.handleSort({
                        value: 'name',
                        sortMethod: sortByTypes,
                        sortType: STRING,
                      });
                    }}
                    value='name'
                    sortingOption={
                      this.props.segments.requestParams.sorting_option
                    }
                    isSortable='true'
                  >
                    Name
                  </Th>
                  <Th align='left'>Filter Criteria</Th>
                  <Th align='left'>Targeted Audience</Th>
                  <Th align='left'>Email</Th>
                  <Th align='center'>Actions</Th>
                </TrHead>
              </thead>
              <TBody striped>
                {allSegments && this.renderRows(segmentsData, this.props)}
              </TBody>
            </Table>
            <PaginationWrapper>
              {allSegments && allSegments.length > 10 && (
                <PaginationControl
                  response={{
                    ...this.props.allSegments,
                    page: this.props.segments.requestParams.page_number,
                    page_size: this.props.segments.requestParams.page_size,
                    count: segmentsData.length,
                  }}
                  requestParams={this.props.segments.requestParams}
                  type='segments'
                />
              )}
            </PaginationWrapper>
          </TableWrapper>
        ) : (
          <LoadingBlueLarge />
        )}
        {allSegments && allSegments.length == 0 && (
          <EmptyStateMessage>
            There are no segments to display.
          </EmptyStateMessage>
        )}
        {this.state.segmentIdToDelete && (
          <ConfirmDeleteModal
            requestingDelete={this.props.segments.isDeleting.includes(
              this.state.segmentIdToDelete
            )}
            deleteAction={() =>
              this.deleteSegment(this.state.segmentIdToDelete)
            }
            cancel={() => this.setState({ segmentIdToDelete: null })}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    segments: state.segments,
    alerts: state.alerts,
    token: state.accountReducer.token,
    allSegments: selectors.selectAllSegments(state),
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
    allTags: state.tagsReducer.allTags,
    allProducts: state.productsReducer.allProducts,
    requestingGetSegments: selectors.selectRequestingGetSegments(state),
    appConfiguration: state.accountReducer.appMetaData.configuration,
  };
}
ListSegment = exportCSVWrapper(ListSegment);

export default connect(mapStateToProps, { ...actions, getProducts })(
  ListSegment
);
