import actions from 'redux-form/lib/actions';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  SHOW_PRODUCT_INDEX,
  TOGGLE_PRODUCT_REQUEST,
  TOGGLE_PRODUCT_SUCCESS,
  TOGGLE_PRODUCT_FAILURE,
  // SHOW_CREATE_PRODUCT_FORM,
  // CREATE_PRODUCT_REQUEST,
  // CREATE_PRODUCT_RESPONSE,
  // SHOW_EDIT_PRODUCT_FORM,
  // DELETE_PRODUCT_REQUEST,
  // DELETE_PRODUCT_RESPONSE,
  SET_PRODUCT_WIZARD_IS_VISIBLE,
  RESET_PRODUCT_REDUCER_STATE,
  PATCH_PRODUCT_REQUEST,
  PATCH_PRODUCT_SUCCESS,
  PATCH_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  INITIATE_CREATE_PRODUCT,
  GET_PRODUCT_INFORMATION,
  LINK_PRODUCT_WITH_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_VARIANTS_SUCCESS,
  UPDATE_PRODUCT_VARIANT_INITIAL_VALUES,
  RESET_PRODUCTION_VARIANT_INITIAL_VALUES,
  PUT_PRODUCT_VARIANTS_SUCCESS,
  GET_PRODUCT_OPTIONS_SUCCESS,
  PUT_PRODUCT_OPTIONS_SUCCESS,
  UPDATE_PRODUCT_TOGGLES,
  GET_PRODUCT_BUSINESS_SUCCESS,
  UPDATE_PRODUCT_BUSINESSES,
  GET_PRODUCT_TAGS_SUCCESS,
  PUT_PRODUCT_TAGS_SUCCESS,
  SET_PRODUCT_PREVIEW_FLAT_PRODUCT,
  CHANGE_VARIANT,
  INCREMENT_ITEM,
  DECREMENT_ITEM,
  INCREMENT_SUB_ITEM,
  DECREMENT_SUB_ITEM,
  VALIDATE_OPTION,
  GET_SELECTED_BUSINESS_SUCCESS,
  PUT_SELECTED_BUSINESS_SUCCESS,
  UPDATE_SORTED_PRODUCTS_LIST,
  GET_PRODUCT_OVERRIDES,
  GET_PRODUCT_ASSIGNED_BUSINESSES,
  GET_TAX_CATEGORY_LIST,
  SUBMIT_PRODUCT_OVERRIDES_REQUEST,
  SUBMIT_PRODUCT_OVERRIDES,
  GET_PRODUCT_VARIANTS_REQUEST,
  GET_PRODUCT_AVAILABILITIES,
  PATCH_PRODUCT_AVAILABILITIES,
  GET_PRODUCTS_VARIANTS_SUCCESS,
} from './productsTypes';

const defaultState = {
  isSortingRequested: false,
  allProducts: false,
  productAvailabilities: [],
  requestingToggleProductIds: [],
  productWizardIsVisible: false,
  isEditing: false,
  productInformation: {},
  productInformationInitialValues: {
    name: '',
    label: '',
    user_description: null,
    image_url: null,
    start_time: '07:00:00',
    end_time: '23:30:00',
    start_datetime: null,
    end_datetime: null,
    is_points_enabled: true,
    taxCategoryId: '',
    orderType: [],
    is_combo_only: false,
    is_alcoholic: false,
  },
  productToggles: {
    is_featured: false,
    is_available: false,
    is_active: false,
  },
  productId: null,
  patchProductIsRequesting: false,
  productCustomizerReducer: null,
  createProductIsRequesting: false,
  categoriesInitialValues: [{ id: null, subCategoriesId: [] }],
  getProductCategoriesIsRequesting: true,
  productVariantsInitialValues: [],
  originalProductVariantsInitialValues: [],
  getProductVariantsIsRequesting: true,
  productOptionsInitialValues: [],
  getProductOptionsIsRequesting: true,
  allOptions: [],
  getProductTagsIsRequesting: true,
  productTagsInitialValues: [],
  categories: [],
  productSelectedBusinessesInitialValues: [],
  requestParams: {
    page_number: 1,
    page_size: '100',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: false,
  },
  productOverrides: null,
  formattedBusinesses: null,
  isSubmiting: false,
  isRequestingProductVariants: false,
  allProductVariants: null, // for filter product variants modal
  optionWithDefault: null,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'CLEAR_PRODUCT_LIST':
      return {
        ...state,
        allProducts: action.allProducts,
      };
    case PUT_SELECTED_BUSINESS_SUCCESS:
      return {
        ...state,
        productSelectedBusinessesInitialValues:
          action.productSelectedBusinessesInitialValues,
      };
    case GET_SELECTED_BUSINESS_SUCCESS:
      return {
        ...state,
        productSelectedBusinessesInitialValues:
          action.productSelectedBusinessesInitialValues,
      };
    case GET_PRODUCTS_REQUEST:
      return { ...state, requestingGetProducts: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        requestingGetProducts: false,
        allProducts: action.response,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.hasNextPage,
        },
      };
    case GET_PRODUCTS_VARIANTS_SUCCESS: // for filter product variants modal
      return {
        ...state,
        requestingGetProducts: false,
        allProductVariants: action.allProductVariants,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.hasNextPage,
        },
      };
    case GET_PRODUCTS_FAILURE:
      return { ...state, requestingGetProducts: false, error: action.error };
    case SHOW_PRODUCT_INDEX:
      return { ...state, creatingProduct: false, editingProduct: false };
    case TOGGLE_PRODUCT_REQUEST:
      return {
        ...state,
        requestingToggleProductIds: [
          ...state.requestingToggleProductIds,
          action.id,
        ],
      };
    case TOGGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        requestingToggleProductIds: state.requestingToggleProductIds.filter(
          (id) => id !== action.id
        ),
        allProducts: state.allProducts.map((product) => {
          if (product.id === action.id) {
            return { ...product, is_active: !product.is_active };
          }
          return product;
        }),
      };
    case TOGGLE_PRODUCT_FAILURE:
      return {
        ...state,
        requestingToggleProductIds: state.requestingToggleProductIds.filter(
          (id) => id !== action.id
        ),
        toggleProductError: action.error,
      };
    case SET_PRODUCT_WIZARD_IS_VISIBLE:
      return {
        ...state,
        productWizardIsVisible: action.productWizardIsVisible,
        productToEdit: action.productToEdit || defaultState.productToEdit,
        productInformation:
          action.productInformation || defaultState.productInformation,
        productToEditToggles:
          action.productToEditToggles || defaultState.productToEditToggles,
        isEditing: action.isEditing,
        productId: action.productId,
      };
    case RESET_PRODUCT_REDUCER_STATE:
      return defaultState;
    case PATCH_PRODUCT_REQUEST:
      return {
        ...state,
        patchProductIsRequesting: true,
      };
    case PATCH_PRODUCT_SUCCESS:
      return {
        ...state,
        productInformationInitialValues: action.productInformationInitialValues,
        patchProductIsRequesting: false,
        // productInformation: action.productInformationInitialValues
      };
    case PATCH_PRODUCT_FAILURE:
      return {
        ...state,
        patchProductIsRequesting: false,
      };
    case CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        createProductIsRequesting: true,
      };

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        productId: action.productId,
        productInformationInitialValues: action.productInformation,
        productSelectedBusinessesInitialValues:
          action.productSelectedBusinessesInitialValues,
        categories: action.categories,
        allOptions: action.allOptions,
        createProductIsRequesting: false,
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        createProductIsRequesting: false,
      };
    case INITIATE_CREATE_PRODUCT:
      return {
        ...state,
        productInformationInitialValues:
          defaultState.productInformationInitialValues,
        productWizardIsVisible: true,
        isEditing: false,
        editingProductId: null,
        getProductVariantsIsRequesting: false,
        getProductCategoriesIsRequesting: false,
        getProductOptionsIsRequesting: false,
      };
    case GET_PRODUCT_INFORMATION:
      return {
        ...state,
        productInformationInitialValues: action.productToEdit,
        productInformation: action.productInformation,
        productId: action.editingProductId,
        productWizardIsVisible: true,
        isEditing: true,
        editingProductId: action.editingProductId,
        productToggles: action.productToggles,
      };
    case 'GET_PRODUCT_TAX_ID':
      return {
        ...state,
        productInformationInitialValues: {
          ...state.productInformationInitialValues,
          taxCategoryId: action.taxCategoryId,
        },
      };
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        getProductCategoriesIsRequesting: false,
        categoriesInitialValues: action.categoriesInitialValues,
        categories: action.categories,
      };

    case LINK_PRODUCT_WITH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesInitialValues: action.categoriesInitialValues,
      };
    case GET_PRODUCT_VARIANTS_SUCCESS:
      return {
        ...state,
        isRequestingProductVariants: false,
        getProductVariantsIsRequesting: false,
        productVariantsInitialValues: action.productVariantsInitialValues,
        originalProductVariantsInitialValues:
          action.productVariantsInitialValues,
      };
    case GET_PRODUCT_VARIANTS_REQUEST:
      return {
        ...state,
        isRequestingProductVariants: true,
      };
    case UPDATE_PRODUCT_VARIANT_INITIAL_VALUES:
      return {
        ...state,
        productVariantsInitialValues: action.productVariantsInitialValues,
      };
    case RESET_PRODUCTION_VARIANT_INITIAL_VALUES:
      return {
        ...state,
        productVariantsInitialValues:
          state.originalProductVariantsInitialValues,
      };
    case PUT_PRODUCT_VARIANTS_SUCCESS:
      return {
        ...state,
        productVariantsInitialValues: action.productVariantsInitialValues,
        originalProductVariantsInitialValues:
          action.productVariantsInitialValues,
      };
    case GET_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        productOptionsInitialValues: action.productOptionsInitialValues,
        allOptions: action.allOptions,
        getProductOptionsIsRequesting: false,
        optionWithDefault: action.optionWithDefault,
      };
    case GET_PRODUCT_TAGS_SUCCESS:
      return {
        ...state,
        productTagsInitialValues: action.productTagsInitialValues,
        getProductTagsIsRequesting: false,
      };
    case PUT_PRODUCT_OPTIONS_SUCCESS:
      return {
        ...state,
        productOptionsInitialValues: action.productOptionsInitialValues,
      };
    case UPDATE_PRODUCT_TOGGLES:
      return {
        ...state,
        productToggles: { ...state.productToggles, ...action.productToggles },
      };
    case PUT_PRODUCT_TAGS_SUCCESS:
      return {
        ...state,
        productTagsInitialValues: action.productTagsInitialValues,
      };
    case SET_PRODUCT_PREVIEW_FLAT_PRODUCT:
      return {
        ...state,
        productCustomizerReducer: action.productCustomizerReducer,
      };
    case SUBMIT_PRODUCT_OVERRIDES_REQUEST:
      return {
        ...state,
        isSubmiting: true,
      };
    case SUBMIT_PRODUCT_OVERRIDES:
      return {
        ...state,
        isSubmiting: false,
      };
    case CHANGE_VARIANT:
      return {
        ...state,
        productCustomizerReducer: action.productCustomizerReducer,
      };

    case INCREMENT_ITEM:
      return {
        ...state,
        productCustomizerReducer: {
          ...state.productCustomizerReducer,
          flatProduct: {
            ...state.productCustomizerReducer.flatProduct,
            itemsById: {
              ...state.productCustomizerReducer.flatProduct.itemsById,
              [action.item.item_id]: {
                ...action.item,
                quantity: action.item.quantity + 1,
              },
            },
          },
        },
      };
    case DECREMENT_ITEM:
      return {
        ...state,
        productCustomizerReducer: {
          ...state.productCustomizerReducer,
          flatProduct: {
            ...state.productCustomizerReducer.flatProduct,
            itemsById: {
              ...state.productCustomizerReducer.flatProduct.itemsById,
              [action.item.item_id]: {
                ...action.item,
                quantity: action.item.quantity - 1,
              },
            },
          },
        },
      };

    case INCREMENT_SUB_ITEM:
      return {
        ...state,
        productCustomizerReducer: {
          ...state.productCustomizerReducer,
          flatProduct: {
            ...state.productCustomizerReducer.flatProduct,
            subItemsById: {
              ...state.productCustomizerReducer.flatProduct.subItemsById,
              [action.subItem.item_id]: {
                ...action.subItem,
                quantity: action.subItem.quantity + 1,
              },
            },
          },
        },
      };
    case DECREMENT_SUB_ITEM:
      return {
        ...state,
        productCustomizerReducer: {
          ...state.productCustomizerReducer,
          flatProduct: {
            ...state.productCustomizerReducer.flatProduct,
            subItemsById: {
              ...state.productCustomizerReducer.flatProduct.subItemsById,
              [action.subItem.item_id]: {
                ...action.subItem,
                quantity:
                  action.quantity || action.quantity === 0
                    ? action.quantity
                    : action.subItem.quantity - 1,
              },
            },
          },
        },
      };
    case VALIDATE_OPTION:
      return {
        ...state,
        productCustomizerReducer: {
          ...state.productCustomizerReducer,
          flatProduct: {
            ...state.productCustomizerReducer.flatProduct,
            optionsById: {
              ...state.productCustomizerReducer.flatProduct.optionsById,
              [action.validatedOption.option_id]: action.validatedOption,
            },
          },
        },
      };
    case 'UPDATE_PRODUCTS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case UPDATE_SORTED_PRODUCTS_LIST:
      return {
        ...state,
        allProducts: action.allProducts,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder,
        },
      };
    case GET_PRODUCT_OVERRIDES:
      return {
        ...state,
        productOverrides: action.payload,
      };
    case GET_PRODUCT_ASSIGNED_BUSINESSES:
      return {
        ...state,
        formattedBusinesses: action.payload,
      };
    case GET_PRODUCT_AVAILABILITIES:
      return {
        ...state,
        productAvailabilities: action.payload,
        isRequestingGetProductAvailabilities: false,
      };
    case PATCH_PRODUCT_AVAILABILITIES:
      return {
        ...state,
        productAvailabilities: action.productAvailabilities,
      };
    default:
      return state;
  }
}
