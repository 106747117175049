import api from '../../api';
import {
  REQUEST_EMPLOYEE_DATA,
  SET_EMPLOYEE_DATA,
} from './OrderTabletUsersTypes';
import { createAlert } from '../Alert/actions';

async function callAPI() {
  return await api
    .get('/employees')
    .then((response) => {
      const payload = {
        data: response.data.data,
        meta: response.data.meta,
      };
      return payload;
    })
    .catch((error) => {
      return {
        error: error,
      };
    });
}

export function postNewEmployee(data, cb) {
  return function (dispatch) {
    return api
      .post('/employees', data)
      .then((response) => {
        dispatch(
          createAlert({ type: 'success', message: 'User Added Successfully!' })
        );
        cb && cb();
        dispatch(getEmployeeData());
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error.response.data.errors.message,
          })
        );
      });
  };
}

export async function getEmployeeInformation(employeeId) {
  return await api
    .get(`/employees/${employeeId}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return {
        error: error,
      };
    });
}

export function patchEmployee(employeeId, data, cb) {
  delete data.business_id;
  return function (dispatch) {
    return api
      .patch(`/employees/${employeeId}`, data)
      .then((res) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'User Updated Successfully!',
          })
        );
        cb && cb();
        dispatch(getEmployeeData());
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error.response.data.errors.message,
          })
        );
      });
  };
}

export function getEmployeeData() {
  return function (dispatch) {
    dispatch({ type: REQUEST_EMPLOYEE_DATA });
    callAPI().then((payload) => {
      dispatch({ type: SET_EMPLOYEE_DATA, payload });
    });
  };
}

export function searchInformation(user, data) {
  return data.filter(
    (data) =>
      data.email.toLowerCase().trim().includes(user) ||
      data.business.name.toLowerCase().trim().includes(user)
  );
}

export function validatePasswordField(value, passwordVerifier) {
  let error;
  if (!value.password) error = 'Required';
  else if (!passwordVerifier.eight)
    error = 'Password must have a minimum of 8 characters';
  else if (!passwordVerifier.upperLower)
    error = 'Password must have at least 1 uppercase and 1 lowercase character';
  else if (!passwordVerifier.digitSymbol)
    error = 'Password must have at least 1 digit or symbol';

  return error;
}

export function validateConfirmPasswordField(value, passwordVerifier) {
  let error;
  if (!value.passwordConfirm) {
    error = 'Required';
  } else if (value.passwordConfirm !== value.password) {
    error = 'Confirmation password does not match';
  }
  return error;
}

export function validateEmail(value) {
  let error;
  if (!value.email) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.email)) {
    error = 'Invalid email address';
  }
  return error;
}

export function validateBusinessField(
  value,
  businessesLocations,
  employeeLocations
) {
  let error;

  // const availableBusinessIds =
  //   businessesLocations && employeeLocations
  //     ? businessesLocations.filter(
  //         (business) => !employeeLocations.includes(business.id)
  //       )
  //     : null;

  // if (!availableBusinessIds.includes(value.business_id)) {
  //   error = 'An employee is already associated with that business';
  // }

  return error;
}
