import api from '../../../api';
import {
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
  FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
} from './types';
import moment from 'moment';
import ReactGA from 'react-ga';
import { createAlert } from '../../Alert/actions';
function getBusinessLocationsOfMerchantRequest() {
  return {
    type: GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  };
}
export function updateLocationRequestParams(params) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_LOCATION_FILTERING_REQUEST_PARAMS',
      params: params,
    });
  };
}
export function getBusinessLocationsOfMerchant(selectedBusiness) {
  return function (dispatch) {
    dispatch(getBusinessLocationsOfMerchantRequest());
    const startTime = moment();
    return api
      .get(`/businesses`)
      .then((response) => {
        const endTime = moment();
        const responseTime = endTime.diff(startTime, 'millisecond');
        ReactGA.timing({
          category: 'Response Time',
          variable: 'Fetching',
          value: responseTime,
          label: 'Getting Businesses Response Time',
        });
        ReactGA.event({
          category: 'Successful Response',
          action: 'Getting Businesses',
          label: 'Successfully Getting Businesses',
        });
        const businessLocationsOfMerchant = response.data.data;
        const subBusinessLocationsOfMerchant = [];
        businessLocationsOfMerchant.forEach((parentBusiness) => {
          subBusinessLocationsOfMerchant.push(...parentBusiness.sub_businesses);
        });
        dispatch({
          type: GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
          businessLocationsOfMerchant,
          subBusinessLocationsOfMerchant,
          selectedBusinessLocationsOfMerchant: selectedBusiness
            ? businessLocationsOfMerchant.filter(
                (item) => item.id === selectedBusiness.id
              )
            : businessLocationsOfMerchant,
        });
      })
      .catch((error) => {
        ReactGA.event({
          category: 'Unsuccessful Response',
          action: 'Getting Businesses',
          label: 'Unsuccessfully Getting Businesses',
        });
        // console.log(error);
      });
  };
}
export function updateIsActive(businessId, is_active) {
  return function (dispatch) {
    dispatch({ type: 'CREATE_BUSINESS_LOCATION_REQUEST' });
    return api
      .patch(`/businesses/${businessId}`, { is_active })
      .then((response) => {
        dispatch({
          type: 'UPDATE_BUSINESS_SUCCESS',
          businessId: businessId,
        });
        dispatch(getBusinessLocationsOfMerchant());
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business location updated successfully',
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function filterBusinessLocationsOfMerchantWithQuery(selectedBusiness) {
  return function (dispatch) {
    dispatch({
      type: FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
      payload: selectedBusiness,
    });
  };
}
