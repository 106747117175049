import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab3Comp from './Tab3Comp';
import styled from 'styled-components';
import Fileuploader from './Fileuploader';
import { values } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '300px',
    },
    '&:focus': {
      outline: 'none !important',
    },
  },
}));
const Notes = styled.div`
  display: grid;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: normal;
`;
const RemoveIcon = styled.i`
  cursor: pointer;
  font-size: 20px;
`;
const SharableWrapper = styled.i`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
`;
const CodeRowWrraper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 20px;
  grid-gap: 50px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;
const AddButton = styled.button`
  padding: 15px 5px;
  border-radius: 10px;
  background: #2440ae;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: 0px;
  width: ${(props) => props.width || '100px'};
  margin-top: 30px;
  cursor: pointer;
  &:disabled {
    background: #ebebeb;
    color: #6f6f76;
    cursor: default;
  }
`;

const WrapperMain = styled.div`
  padding-bottom: 20px;
  textarea {
    width: 100%;
    border-color: #cdcdcd;
    border-radius: 6px;
    padding: 10px;
  }
  .actionButton {
    padding: 13px 30px 13px 30px;
    border-radius: 28px;
    background: #2440ae !important;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: 0px;
    width: max-content;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .textRight {
    text-align: right;
  }
`;

function TabPanel(props) {
  const { children, value, index, values, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pb: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPromo = ({
  values,
  setFieldValue,
  setAvailabilityModalState,
  selectedPromoCode,
  setSelectedPromoCode,
}) => {
  const [promoCodeList, setPromoCodeList] = useState([
    {
      code: '',
      is_shareable: false,
      is_used: false,
    },
  ]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeCode = (e, i) => {
    const newPromoCodeList = [...promoCodeList];
    newPromoCodeList[i] = { ...newPromoCodeList[i], code: e.target.value };
    setPromoCodeList(newPromoCodeList);
  };
  const handleChangeIsShareable = (e, i) => {
    const newPromoCodeList = [...promoCodeList];
    newPromoCodeList[i] = {
      ...newPromoCodeList[i],
      is_shareable: e.target.checked,
    };
    setPromoCodeList(newPromoCodeList);
  };
  const handleAddCode = () => {
    const newPromoCodeList = [...promoCodeList];
    newPromoCodeList.push({ code: '', is_shareable: false });
    setPromoCodeList(newPromoCodeList);
  };
  const handleRemoveCode = (index) => {
    const newPromoCodeList = [...promoCodeList];
    if (newPromoCodeList.length > 1) {
      newPromoCodeList.splice(index, 1);
      setPromoCodeList(newPromoCodeList);
    }
  };
  const isSaveDisabled = () => {
    let newIsDisabled = false;
    promoCodeList.forEach((code) => {
      if (code.length < 5) newIsDisabled = true;
    });
    return newIsDisabled;
  };
  const handleSave = () => {
    // let tabOneData = tab1data.split('\n');
    // if (selectedPromoCode) {
    //   newOfferPromoCodes[selectedPromoCode.index] = promoCodeList[0];
    //   setFieldValue('offerPromoCode', newOfferPromoCodes);
    //   setSelectedPromoCode();
    // } else {
    // }
    let newOfferPromoCodes = [...values.offerPromoCode];
    promoCodeList.forEach((promo) => {
      newOfferPromoCodes.push(promo);
    });
    setFieldValue('offerPromoCode', newOfferPromoCodes);
    setAvailabilityModalState(false);
  };
  return (
    <WrapperMain>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            {/* <Tab label='Add manually' {...a11yProps(0)} /> */}
            {/* <Tab label='Upload codes' {...a11yProps(1)} />
            <Tab label='Autogenerate codes' {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {/* <Notes>
            Enter up to 1000 codes. To add more than 1000 codes, upload it via
            the “Upload codes” tab.
          </Notes> */}
          {promoCodeList.map((promo, i) => (
            <CodeRowWrraper key={i}>
              <TextField
                value={promo?.code}
                variant='outlined'
                placeholder='Enter code'
                onChange={(e) => handleChangeCode(e, i)}
                style={{
                  border: promo?.code.length < 5 && '1px solid red',
                  borderRadius: '5px',
                  outline: promo?.code.length < 5 && 'none !important',
                }}
                size='small'
              />
              <SharableWrapper>
                <FormControlLabel
                  // value={promo?.is_shareable}
                  control={
                    <Checkbox
                      color='primary'
                      onChange={(e) => handleChangeIsShareable(e, i)}
                      checked={promo?.is_shareable}
                    />
                  }
                  label='Shareable?'
                />
                <Tooltip title='A shareable code can be shared with multiple users whereas an unshareable code can be used by one individual customer only. Whether the promo code can be used single time or multiple times by the same user would be defined by Reusability value. If the offer is reusable, the same customer will be able to use the same promo code multiple times'>
                  <i className='fa fa-info-circle fa-lg'></i>
                </Tooltip>
              </SharableWrapper>
              {i !== 0 && (
                <RemoveIcon
                  className='fa fa-minus-circle'
                  onClick={() => handleRemoveCode(i)}
                ></RemoveIcon>
              )}
            </CodeRowWrraper>
          ))}
          <AddButton
            onClick={handleAddCode}
            width='200px'
            disabled={selectedPromoCode}
          >
            + Add New Code
          </AddButton>
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <Notes>
            Upload codes as a text file. Each code should be entered on a
            separate line.
          </Notes>
          <Fileuploader
            setTab2data={setTab2data}
            setPromoCodeList={setPromoCodeList}
            values={values}
            setAvailabilityModalState={setAvailabilityModalState}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Tab3Comp
            values={values}
            setAvailabilityModalState={setAvailabilityModalState}
          />
        </TabPanel> */}
      </Box>
      <div className='textRight'>
        <AddButton
          disabled={isSaveDisabled()}
          onClick={handleSave}
          width='150px'
        >
          Save Codes
        </AddButton>
      </div>
    </WrapperMain>
  );
};
export default TabPromo;
