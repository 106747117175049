import React from 'react';
import { Field } from 'formik';
import {
  RenderTextField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import {
  AddProductsButton,
  ComboItemDiv,
  ItemNameField,
  ShowComboItemsText,
  IconEllipsis,
  RemoveComboGroupName,
  ItemRequiredWrapper,
  ComboItemsWrapper,
  ItemMoveWrapper,
  ItemContentWrapper,
  ButtonLikeWrapper,
} from '../styles/style';
import AddProductsModal from './AddProductsModal';
import ComboOverridesModal from './ComboOverridesModal';
import SingleComboProductsDiv from './SingleComboProductsDiv';
import { Icon } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import { Switch } from '@material-ui/core';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  sortableHandle,
} from 'react-sortable-hoc';
const SortableItem = SortableElement(
  ({
    index,
    itemIndex,
    comboItem,
    comboItems,
    arrayHelpers,
    setCombosOverrideModalState,
    toggleCombosOverridesModal,
    isCombosOverridesModal,
    combosOverrideModalState,
    toggleShowProductsState,
    showProductsState,
    beautifyProductVariantNames,
    toggleChargeExtraModal,
    removeProductVariant,
    toggleProductModal,
    isChargeExtraModal,
    isProductModal,
    chargeExtraModalState,
    setChargeExtraModalState,
    initValues,
    setFieldValue,
    handleReset,
    values,
    productsModalState,
    setProductsModalState,
    getProductsCategories,
    productsCategories,
    getProductsVariants,
    productsVariants,
    isRequestingGetProductsVariants,
    allProducts,
    getAllProducts,
    getCombosOptionsOverrides,
    combosOptionsOverride,
    isRequestingGetCombosOptionsOverrides,
    options,
    initialValues,
    isComboItemsSubmitSuccess,
    isRequestingGetAllProducts,
    RenderTextField,
  }) => (
    <ComboItemsWrapper key={`comboItems_${itemIndex}`}>
      <ItemMoveWrapper>
        <Icon
          className='far fa-equals'
          style={{ cursor: 'move', textAlign: 'center' }}
        />
      </ItemMoveWrapper>
      <ItemContentWrapper>
        <ItemNameField>
          <Field
            name={`comboItems[${itemIndex}].label`}
            component={RenderTextField}
            label='Combo Group Name'
            error={
              arrayHelpers?.form?.touched?.comboItems &&
              arrayHelpers?.form?.touched?.comboItems[itemIndex] &&
              arrayHelpers?.form?.touched?.comboItems[itemIndex]?.label &&
              !arrayHelpers?.form?.values?.comboItems[itemIndex]?.label
                ? 'Required'
                : null
            }
            validate={required}
          />
          <div>
            <Tooltip title='Edit Customization Rules'>
              <span>
                <IconEllipsis
                  className='fas fa-ellipsis-v'
                  style={{ fontSize: '20px' }}
                  onClick={() => {
                    setCombosOverrideModalState({
                      comboItemId: comboItem.id,
                    });
                    toggleCombosOverridesModal();
                  }}
                  disabled={!comboItem.productVariants.length}
                />
              </span>
            </Tooltip>

            {isCombosOverridesModal &&
              combosOverrideModalState.comboItemId === comboItem.id && (
                <ComboOverridesModal
                  toggleCombosOverridesModal={toggleCombosOverridesModal}
                  comboItem={comboItem}
                  getCombosOptionsOverrides={getCombosOptionsOverrides}
                  combosOptionsOverride={combosOptionsOverride}
                  isRequestingGetCombosOptionsOverrides={
                    isRequestingGetCombosOptionsOverrides
                  }
                  options={options}
                  comboItemIndex={itemIndex}
                  comboItems={comboItems}
                  handleReset={handleReset}
                  initialValues={initialValues}
                  initValues={initValues}
                  setFieldValue={setFieldValue}
                  comboItemFormik={
                    arrayHelpers?.form?.values.comboItems[itemIndex]
                  }
                  arrayHelpers={arrayHelpers}
                />
              )}
          </div>
          <RemoveComboGroupName
            onClick={() => {
              if (comboItem.id) {
                setFieldValue(`comboItems[${itemIndex}].is_active`, false);
              } else {
                arrayHelpers.remove(itemIndex);
              }
            }}
          >
            <i className='fal fa-trash-alt'></i>
          </RemoveComboGroupName>
        </ItemNameField>
        <ItemRequiredWrapper>
          <span>Combo Group is Required</span>
          <Switch
            checked={comboItem.is_required}
            onChange={() =>
              setFieldValue(
                `comboItems[${itemIndex}].is_required`,
                !comboItem.is_required
              )
            }
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ItemRequiredWrapper>
        <div>
          {!comboItem.productVariants.length ? (
            <p
              style={{
                fontStyle: 'italic',
                marginBottom: '20px',
                fontSize: '14px',
              }}
            >
              There are no customization for this combo group
            </p>
          ) : (
            <div>
              <ShowComboItemsText
                type='button'
                onClick={() => {
                  toggleShowProductsState(comboItem.id);
                }}
                style={{ marginBottom: '20px' }}
              >
                {showProductsState.includes(comboItem.id) ? 'Hide' : 'Show'}{' '}
                {comboItem.productVariants.length} customization(s) included
                <i
                  style={{ marginLeft: '15px' }}
                  className={`fas ${
                    showProductsState.includes(comboItem.id)
                      ? 'fa-sort-up'
                      : 'fa-sort-down'
                  }`}
                ></i>
              </ShowComboItemsText>

              {showProductsState.includes(comboItem.id) &&
                comboItem.productVariants.map(
                  (productVariant, productVariantIndex) => (
                    <SingleComboProductsDiv
                      key={`comboItem_${comboItems}_${productVariantIndex}`}
                      productVariant={productVariant}
                      productVariantIndex={productVariantIndex}
                      comboItem={comboItem}
                      index={itemIndex}
                      arrayHelpers={arrayHelpers}
                      beautifyProductVariantNames={beautifyProductVariantNames}
                      toggleChargeExtraModal={toggleChargeExtraModal}
                      removeProductVariant={removeProductVariant}
                      isChargeExtraModal={isChargeExtraModal}
                      chargeExtraModalState={chargeExtraModalState}
                      setChargeExtraModalState={setChargeExtraModalState}
                      initValues={initValues}
                      setFieldValue={setFieldValue}
                      handleReset={handleReset}
                      values={values}
                    />
                  )
                )}
            </div>
          )}

          <AddProductsButton
            onClick={() => {
              setProductsModalState({
                comboItemId: comboItem.id,
                productVariants: comboItem.productVariants,
                itemLabel: comboItem.label,
              });
              toggleProductModal();
            }}
            disabled={
              !comboItem.id &&
              !isComboItemsSubmitSuccess &&
              !comboItem.productVariants.length
            }
          >
            + Add Products
            {!comboItem.id && !isComboItemsSubmitSuccess && (
              <Tooltip title='Combo Group should be saved before adding products'>
                <Icon
                  className='fal fa-info-circle'
                  style={{
                    cursor: 'inherit',
                    marginLeft: '10px',
                    fontSize: '16px',
                  }}
                />
              </Tooltip>
            )}
          </AddProductsButton>

          {isProductModal &&
            productsModalState.comboItemId === comboItem.id && (
              <AddProductsModal
                toggleProductModal={toggleProductModal}
                productsModalState={productsModalState}
                getProductsCategories={getProductsCategories}
                productsCategories={productsCategories}
                getProductsVariants={getProductsVariants}
                productsVariants={productsVariants}
                isRequestingGetProductsVariants={
                  isRequestingGetProductsVariants
                }
                comboItem={comboItem}
                allProducts={allProducts}
                getAllProducts={getAllProducts}
                index={itemIndex}
                setFieldValue={setFieldValue}
                arrayHelpers={arrayHelpers}
                isRequestingGetAllProducts={isRequestingGetAllProducts}
              />
            )}
        </div>
      </ItemContentWrapper>
    </ComboItemsWrapper>
  )
);
const SortableList = SortableContainer(
  ({
    comboItems,
    arrayHelpers,
    setCombosOverrideModalState,
    toggleCombosOverridesModal,
    isCombosOverridesModal,
    combosOverrideModalState,
    toggleShowProductsState,
    showProductsState,
    beautifyProductVariantNames,
    toggleChargeExtraModal,
    removeProductVariant,
    toggleProductModal,
    isChargeExtraModal,
    isProductModal,
    chargeExtraModalState,
    setChargeExtraModalState,
    initValues,
    setFieldValue,
    handleReset,
    values,
    productsModalState,
    setProductsModalState,
    getProductsCategories,
    productsCategories,
    getProductsVariants,
    productsVariants,
    isRequestingGetProductsVariants,
    allProducts,
    getAllProducts,
    getCombosOptionsOverrides,
    combosOptionsOverride,
    isRequestingGetCombosOptionsOverrides,
    options,
    initialValues,
    isComboItemsSubmitSuccess,
    isRequestingGetAllProducts,
    RenderTextField,
  }) => {
    return (
      <ComboItemDiv>
        {comboItems.map((comboItem, index) => {
          return (
            comboItem.is_active && (
              <SortableItem
                key={`item-${index}`}
                index={index}
                itemIndex={index}
                comboItems={comboItems}
                comboItem={comboItem}
                arrayHelpers={arrayHelpers}
                combosOverrideModalState={combosOverrideModalState}
                isCombosOverridesModal={isCombosOverridesModal}
                setCombosOverrideModalState={setCombosOverrideModalState}
                toggleShowProductsState={toggleShowProductsState}
                toggleCombosOverridesModal={toggleCombosOverridesModal}
                showProductsState={showProductsState}
                beautifyProductVariantNames={beautifyProductVariantNames}
                toggleChargeExtraModal={toggleChargeExtraModal}
                removeProductVariant={removeProductVariant}
                toggleProductModal={toggleProductModal}
                isChargeExtraModal={isChargeExtraModal}
                isProductModal={isProductModal}
                chargeExtraModalState={chargeExtraModalState}
                setChargeExtraModalState={setChargeExtraModalState}
                initValues={initValues}
                setFieldValue={setFieldValue}
                handleReset={handleReset}
                values={values}
                productsModalState={productsModalState}
                setProductsModalState={setProductsModalState}
                getProductsCategories={getProductsCategories}
                productsCategories={productsCategories}
                getProductsVariants={getProductsVariants}
                productsVariants={productsVariants}
                isRequestingGetProductsVariants={
                  isRequestingGetProductsVariants
                }
                allProducts={allProducts}
                getAllProducts={getAllProducts}
                getCombosOptionsOverrides={getCombosOptionsOverrides}
                combosOptionsOverride={combosOptionsOverride}
                isRequestingGetCombosOptionsOverrides={
                  isRequestingGetCombosOptionsOverrides
                }
                options={options}
                initialValues={initialValues}
                isComboItemsSubmitSuccess={isComboItemsSubmitSuccess}
                isRequestingGetAllProducts={isRequestingGetAllProducts}
                RenderTextField={RenderTextField}
                lock
              />
            )
          );
        })}
      </ComboItemDiv>
    );
  }
);

const SingleComboItem = ({
  comboItems,
  arrayHelpers,
  setCombosOverrideModalState,
  toggleCombosOverridesModal,
  isCombosOverridesModal,
  combosOverrideModalState,
  toggleShowProductsState,
  showProductsState,
  beautifyProductVariantNames,
  toggleChargeExtraModal,
  removeProductVariant,
  toggleProductModal,
  isChargeExtraModal,
  isProductModal,
  chargeExtraModalState,
  setChargeExtraModalState,
  initValues,
  setFieldValue,
  handleReset,
  values,
  productsModalState,
  setProductsModalState,
  getProductsCategories,
  productsCategories,
  getProductsVariants,
  productsVariants,
  isRequestingGetProductsVariants,
  allProducts,
  getAllProducts,
  getCombosOptionsOverrides,
  combosOptionsOverride,
  isRequestingGetCombosOptionsOverrides,
  options,
  initialValues,
  isComboItemsSubmitSuccess,
  isRequestingGetAllProducts,
}) => {
  const activeComboItems = comboItems.filter(
    (comboItem) => comboItem.is_active
  );
  return (
    <div style={{ marginBottom: '20px' }}>
      {activeComboItems.length !== 0 ? (
        <SortableList
          comboItems={comboItems}
          arrayHelpers={arrayHelpers}
          combosOverrideModalState={combosOverrideModalState}
          isCombosOverridesModal={isCombosOverridesModal}
          setCombosOverrideModalState={setCombosOverrideModalState}
          toggleShowProductsState={toggleShowProductsState}
          toggleCombosOverridesModal={toggleCombosOverridesModal}
          showProductsState={showProductsState}
          beautifyProductVariantNames={beautifyProductVariantNames}
          toggleChargeExtraModal={toggleChargeExtraModal}
          removeProductVariant={removeProductVariant}
          toggleProductModal={toggleProductModal}
          isChargeExtraModal={isChargeExtraModal}
          isProductModal={isProductModal}
          chargeExtraModalState={chargeExtraModalState}
          setChargeExtraModalState={setChargeExtraModalState}
          initValues={initValues}
          setFieldValue={setFieldValue}
          handleReset={handleReset}
          values={values}
          productsModalState={productsModalState}
          setProductsModalState={setProductsModalState}
          getProductsCategories={getProductsCategories}
          productsCategories={productsCategories}
          getProductsVariants={getProductsVariants}
          productsVariants={productsVariants}
          isRequestingGetProductsVariants={isRequestingGetProductsVariants}
          allProducts={allProducts}
          getAllProducts={getAllProducts}
          getCombosOptionsOverrides={getCombosOptionsOverrides}
          combosOptionsOverride={combosOptionsOverride}
          isRequestingGetCombosOptionsOverrides={
            isRequestingGetCombosOptionsOverrides
          }
          options={options}
          initialValues={initialValues}
          isComboItemsSubmitSuccess={isComboItemsSubmitSuccess}
          isRequestingGetAllProducts={isRequestingGetAllProducts}
          onSortEnd={({ oldIndex, newIndex }) => {
            const newList = arrayMove(comboItems, oldIndex, newIndex);
            setFieldValue(`comboItems`, newList);
            // setTimeout(() => setIsSorting(false), 250);
          }}
          RenderTextField={RenderTextField}
          lockAxis='y'
          pressDelay={200}
        />
      ) : (
        <div style={{ paddingBottom: '15px' }}>
          There are no Combo Items to show
        </div>
      )}
    </div>
  );
};

export default SingleComboItem;
