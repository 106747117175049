import { api } from '../../api';
import {
  INITIATE_CREATE_LAYOUT,
  CREATE_APP_FEED_LAYOUT_REQUEST,
  CREATE_APP_FEED_LAYOUT_SUCCESS,
  APP_FEED_LAYOUT_SEARCH_RESULT,
  GET_LIST_APP_FEED_LAYOUT_SUCCESS,
  DELETE_APP_FEED_LAYOUT,
  SET_DEFAULT_APP_FEED,
  UPDATE_DEFAULT_LAYOUT,
  DUPLICATE_APP_FEED_LAYOUT,
  SET_PAGE_TYPE,
  EDIT_FEED_LAYOUT,
  UPDATE_APP_FEED_LAYOUT_SUCCESS,
  RESET_APP_FEED_LAYOUT_REDUCER,
} from './appFeedLayoutTypes';
import { createAlert } from '../Alert/actions';
import moment from 'moment';
import { getPurePayload } from '../../utils/purePayload';

export function initiateCreateLayout() {
  return {
    type: INITIATE_CREATE_LAYOUT,
  };
}
export function resetAppFeedLayoutReducer() {
  return {
    type: RESET_APP_FEED_LAYOUT_REDUCER,
  };
}
function setFeedRowForIOS(feedRow) {
  let newFeedRows = { ...feedRow };
  if (newFeedRows.items?.length) {
    newFeedRows.items.map((item, index) => {
      if (item.card_action) {
        newFeedRows.items[index].card_action =
          newFeedRows.items[index].card_action;
      }
    });
  }
  return newFeedRows;
}
export function createAppFeedLayout(layoutInfo) {
  return function (dispatch, getState) {
    const { feedRows } = getState().appFeed;
    let newFeedRows = [];
    // feedRows.map((feedRow) => newFeedRows.push(setFeedRowForIOS(feedRow)));
    feedRows.forEach((feedRow) => {
      const neatRowFeed = getPurePayload(feedRow);
      newFeedRows.push(setFeedRowForIOS(neatRowFeed));
    });
    const neatPayload = getPurePayload(layoutInfo);
    if (!newFeedRows.length) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'At least one feed row required to create a layout',
        })
      );
    } else {
      dispatch({ type: CREATE_APP_FEED_LAYOUT_REQUEST });
      const newFeedRow = formatFeed(newFeedRows).map((item) => {
        const modifiedFeed = { ...item };
        if (item.start_date?.toLowerCase().includes('invalid')) {
          delete modifiedFeed.start_date;
        }
        if (item.end_date?.toLowerCase().includes('invalid')) {
          delete modifiedFeed.end_date;
        }
        return modifiedFeed;
      });

      return api
        .post(`/feed-template`, neatPayload)
        .then((response) => {
          api
            .put(`/feed-template/${response.data.data.id}/feed`, {
              feed: newFeedRow,
            })
            .then((feedResponse) => {
              dispatch(
                createAlert({
                  type: 'success',
                  message: 'Feed Layout Created Successfully',
                })
              );
              dispatch({ type: CREATE_APP_FEED_LAYOUT_SUCCESS });
            })
            .catch((error) => {
              if (error.response.status == 400) {
                dispatch(
                  createAlert({
                    type: 'error',
                    message:
                      error?.response?.data?.errors?.message ||
                      'Please check the form values',
                  })
                );
              }
            });
        })
        .catch((error) => {
          if (error.response.status == 400) {
            dispatch(
              createAlert({
                type: 'error',
                message:
                  error?.response?.data?.errors?.message ||
                  'Please check the form values',
              })
            );
          }
        });
    }
  };
}

export function updateAppFeed(layoutInfo, id) {
  return function (dispatch, getState) {
    let newFeedRows = [];
    dispatch({ type: CREATE_APP_FEED_LAYOUT_REQUEST });
    const { feedRows } = getState().appFeed;
    feedRows.forEach((feedRow) => {
      const neatRowFeed = getPurePayload(feedRow);
      newFeedRows.push(setFeedRowForIOS(neatRowFeed));
    });
    const neatPayload = getPurePayload(layoutInfo);
    if (!newFeedRows.length) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'At least one feed row required to create a layout',
        })
      );
    } else {
      const newFeedRow = formatFeed(newFeedRows).map((item) => {
        const modifiedFeed = { ...item };
        if (item.start_date?.toLowerCase().includes('invalid')) {
          delete modifiedFeed.start_date;
        }
        if (item.end_date?.toLowerCase().includes('invalid')) {
          delete modifiedFeed.end_date;
        }
        return modifiedFeed;
      });
      return api
        .patch(`/feed-template/${id}`, neatPayload)
        .then((response) => {
          api
            .put(`/feed-template/${response.data.data.id}/feed`, {
              feed: newFeedRow,
            })
            .then((feedResponse) => {
              dispatch(
                createAlert({
                  type: 'success',
                  message: 'Feed Layout Updated Successfully',
                })
              );
              dispatch({ type: UPDATE_APP_FEED_LAYOUT_SUCCESS });
            })
            .catch((error) => {
              if (error.response.status == 400) {
                dispatch(
                  createAlert({
                    type: 'error',
                    message:
                      error?.response?.data?.errors?.message ||
                      'Please check the form values',
                  })
                );
              }
            });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            dispatch(
              createAlert({
                type: 'error',
                message:
                  error?.response?.data?.errors?.message ||
                  'Please check the form values',
              })
            );
          }
        });
    }
  };
}

export function editAppFeedLayout(id) {
  return function (dispatch) {
    return api.get(`/feed-template/${id}`).then((response) => {
      dispatch({
        type: EDIT_FEED_LAYOUT,
        formInitialData: response.data,
        id: id,
      });
    });
  };
}

const formatFeed = (appFeed) => {
  const appFeedWithoutIds = appFeed.map((row) => {
    return Object.keys(row).reduce((newObject, key) => {
      if (row['end_date'] !== null) {
        const date = moment(row['end_date']);
        const formattedEDate = date.format('YYYY-MM-DD');
        row.end_date = formattedEDate + ' ' + '23:59:59';
      }

      if (row['start_date'] !== null) {
        const sDate = moment(row['start_date']);
        const formattedSDate = sDate.format('YYYY-MM-DD');
        row.start_date = formattedSDate + ' ' + '00:00:00';
      }

      if (key !== 'id') {
        return { ...newObject, [key]: row[key], name: 'name' };
      }
      return newObject;
    }, {});
  });

  return appFeedWithoutIds.map((row, rowIndex) => {
    if (row.items.length) {
      return {
        ...row,
        position: rowIndex + 1,
        segments: row.segments.map((segment) => {
          if (segment.segment_id) {
            return segment.segment_id;
          }
          return segment;
        }),
        tags: row.tags.map((tag) => {
          if (tag.tag_id) {
            return tag.tag_id;
          }
          return tag;
        }),
        feed_type: row.feed_type === 'web_links' ? 'custom' : row.feed_type,
        items: row.items.map((item) => {
          return {
            ...Object.keys(item).reduce((newItemObject, key) => {
              if (key !== 'id' && key !== 'feed_id' && key !== 'position') {
                return { ...newItemObject, [key]: item[key], name: 'name' };
              }
              return newItemObject;
            }, {}),
            is_visible: row.is_visible,
          };
        }),
      };
    }
    return {
      ...row,
      position: rowIndex + 1,
      segments: row.segments.map((segment) => {
        if (segment.segment_id) {
          return segment.segment_id;
        }
        return segment;
      }),
      tags: row.tags.map((tag) => {
        if (tag.tag_id) {
          return tag.tag_id;
        }
        return tag;
      }),
    };
  });
};

export function getListAppFeedLayout() {
  return function (dispatch) {
    return api.get('/feed-template?include=businesses').then((response) => {
      dispatch({
        type: GET_LIST_APP_FEED_LAYOUT_SUCCESS,
        list: response.data.data,
      });
    });
  };
}

export function deleteAppFeedLayout(singleAppFeed) {
  if (singleAppFeed.is_default) {
    return createAlert({
      type: 'error',
      message: 'You cannot delete the default template',
    });
  }
  const id = singleAppFeed.id;
  return function (dispatch) {
    return api.delete(`/feed-template/${id}`).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Layout deleted successfully',
          })
        );
        dispatch({ type: DELETE_APP_FEED_LAYOUT, appFeed: id });
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred trying to delete layout.',
          })
        );
      }
    );
  };
}

export function setSearchResult(
  text,
  filterAttribute,
  filterOrder,
  initialList
) {
  let list = [...initialList];

  if (text) {
    list = list.filter(
      (appFeed) =>
        appFeed.template_name.toLowerCase().includes(text.toLowerCase()) ||
        appFeed.notes.includes(text.toLowerCase())
    );
  }

  if (filterAttribute) {
    if (filterAttribute === 'name') {
      list = list.sort((appFeed1, appFeed2) => {
        const result = appFeed1.template_name > appFeed2.template_name;

        if (!filterOrder || filterOrder === 'asc') {
          return result ? 1 : -1;
        } else if (filterOrder === 'desc') {
          return result ? -1 : 1;
        }
      });
    } else if (filterAttribute === 'default-layout-first') {
      list = list.sort((appFeed1, appFeed2) => {
        const result =
          moment(appFeed1.created_at) > moment(appFeed2.created_at);
        if (!filterOrder || filterOrder === 'asc') {
          return result ? 1 : -1;
        } else if (filterOrder === 'desc') {
          return result ? -1 : 1;
        }
      });

      list = list.sort((appFeed1, appFeed2) => {
        const result = appFeed1.is_default > appFeed2.is_default;
        return result ? -1 : 1;
      });
    } else if (filterAttribute === 'date-added') {
      list = list.sort((appFeed1, appFeed2) => {
        const result =
          moment(appFeed1.created_at) > moment(appFeed2.created_at);
        if (!filterOrder || filterOrder === 'asc') {
          return result ? 1 : -1;
        } else if (filterOrder === 'desc') {
          return result ? -1 : 1;
        }
      });
    } else if (filterAttribute === 'date-modified') {
      list = list.sort((appFeed1, appFeed2) => {
        const result =
          moment(appFeed1.updated_at) > moment(appFeed2.updated_at);
        if (!filterOrder || filterOrder === 'asc') {
          return result ? 1 : -1;
        } else if (filterOrder === 'desc') {
          return result ? -1 : 1;
        }
      });
    } else if (filterAttribute === 'number-businesses') {
      list = list.sort((appFeed1, appFeed2) => {
        const result = appFeed1.businesses.length > appFeed2.businesses.length;
        if (!filterOrder || filterOrder === 'asc') {
          return result ? 1 : -1;
        } else if (filterOrder === 'desc') {
          return result ? -1 : 1;
        }
      });
    }
  }

  return {
    type: APP_FEED_LAYOUT_SEARCH_RESULT,
    payload: { text, filterAttribute, filterOrder, list },
  };
}

export function setDefaultAppFeed(value) {
  return function (dispatch) {
    return api
      .patch(`/feed-template/${value.id}`, {
        template_name: value.template_name,
        is_default: true,
      })
      .then(
        (response) => {
          dispatch({
            type: UPDATE_DEFAULT_LAYOUT,
            id: response.data.data.id,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Selected template is updated successfully',
            })
          );
          dispatch({ type: SET_DEFAULT_APP_FEED, payload: response.data });
        },
        (error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                error?.response?.data?.errors?.message ||
                'An error occurred trying to update the layout.',
            })
          );
        }
      );
  };
}

export function assignTemplatesBusinesses(businesses) {
  return function (dispatch) {
    Promise.all(
      businesses.map((business) => {
        if (business.selectedTemplate) {
          api.put(`feed-template/${business.selectedTemplate.id}/business`, {
            businesses: [business.id],
          });
        }
      })
    ).then(
      (responses) => {
        dispatch(
          createAlert({
            type: 'success',
            message:
              'Selected template is assigned successfully to the businesses',
          })
        );
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred trying to assign the layout to businesses',
          })
        );
      }
    );
  };
}

export function bulkAssignTemplateBusinesses(templateId, businesses) {
  return function (dispatch) {
    return Promise.all([
      api.put(`feed-template/${templateId}/business`, {
        businesses,
      }),
    ]).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message:
              'Selected template is assigned successfully to the businesses',
          })
        );
        dispatch(getListAppFeedLayout());
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              error?.response?.data?.errors?.message ||
              'An error occurred trying to assign the layout to businesses',
          })
        );
      }
    );
  };
}

export function deepDuplicateFeed(singleAppFeed) {
  const appFeedBody = {
    template_name: singleAppFeed.template_name,
    notes: singleAppFeed.notes,
    is_default: false,
  };
  return function (dispatch) {
    const pureFeedPayload = getPurePayload(appFeedBody);
    return api.post(`/feed-template`, pureFeedPayload).then((responsePost) => {
      api.get(`feed-template/${singleAppFeed.id}/feed`).then((responseGet) => {
        const newFeedRow = formatFeed(responseGet.data.data).map((item) => {
          const modifiedFeed = { ...item };
          if (item.start_date?.toLowerCase().includes('invalid')) {
            delete modifiedFeed.start_date;
          }
          if (item.end_date?.toLowerCase().includes('invalid')) {
            delete modifiedFeed.end_date;
          }
          return modifiedFeed;
        });
        const pureFeedRowPayload = newFeedRow.map((feedRow) =>
          getPurePayload(feedRow)
        );
        return api
          .put(`feed-template/${responsePost.data.data.id}/feed`, {
            feed: pureFeedRowPayload,
          })
          .then(
            (response) => {
              return api
                .get(`feed-template/${responsePost.data.data.id}`)
                .then((responseGetFinal) => {
                  dispatch({
                    type: DUPLICATE_APP_FEED_LAYOUT,
                    payload: responseGetFinal.data.data,
                  });
                  dispatch(
                    createAlert({
                      type: 'success',
                      message: 'Selected template is duplicated successfully',
                    })
                  );
                });
            },
            (error) => {
              dispatch(
                createAlert({
                  type: 'error',
                  message:
                    error?.response?.data?.errors?.message ||
                    'An error occurred trying to duplicate the template.',
                })
              );
            }
          );
      });
    });
  };
}

export function setPageType() {
  return function (dispatch) {
    dispatch({
      type: SET_PAGE_TYPE,
    });
  };
}
