import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon } from 'merchant-portal-components';
import BusinessInformationFormContainer from '../BusinessInformationForm/BusinessInformationFormContainer';
import AddressAndDetailsContainer from '../AddressAndDetails/AddressAndDetailsContainer';
import HoursContainer from '../Hours/HoursContainer';
import DeliveryZonesContainer from '../DeliveryZoneInformation/DeliveryZonesContainer';
import PaymentIntegrationContainer from '../PaymentIntegration/PaymentIntegrationContainer';
import { LoadingBlueLarge } from 'merchant-portal-components';
import DeactivateLocationContainer from '../DeactivateLocation/DeactivateLocationContainer';
import LocationDeactivatedBanner from '../DeactivateLocation/components/LocationDeactivatedBanner';
import { Button } from 'merchant-portal-components';
import LocationFilterModalSingleSelect from '../../../../../components/FilterModal/LocationFilterModalSingleSelect';
const ContentBody = styled.div`
  margin: auto;
  display: grid;
  grid-gap: 24px;
  min-width: 1150px;
  overflow-x: auto;
`;

const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  align-items: center;
`;

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 200px;
  grid-gap: 10px;
  align-items: center;

  &:hover {
    color: #3051d4;
  }
`;

const PageHeader = styled.h2`
  font-weight: 700;
  font-size: 36px;
`;

const BusinessWizardIndex = ({
  isCreatingLocation,
  resetBusinessLocationDetailsReducerState,
  resetDeliveryZoneReducerState,
  shouldComponentBeLoading,
  is_delivery_enabled,
  businessInformationInitialValues,
  copyBusinessMenu,
  businessId,
  isBusinessAdminRole,
}) => {
  const [deactivationTime, setDeactivationTime] = useState(null);
  const [deactivateSection, setDeactivateSection] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentRole = localStorage.getItem('role');
  useEffect(() => {
    const time = localStorage.getItem(
      `location-${businessInformationInitialValues.name}-deactivation-time`
    );

    if (time) setDeactivationTime(time);
    else setDeactivationTime(null);
    if (!businessInformationInitialValues.master_is_active) {
      setDeactivateSection(true);
    } else {
      setDeactivateSection(false);
    }
  }, [businessInformationInitialValues.master_is_active]);
  const handleCopyBusinessMenu = (copyBusiness) => {
    const copyBusinessId = copyBusiness?.id;
    const mainBusinessId = businessId;
    const copyBusinessName = copyBusiness.business_name;
    const payload = {
      main_business_id: mainBusinessId,
      copy_business_id: copyBusinessId,
    };
    copyBusinessMenu(payload, copyBusinessName);
  };
  return (
    <Fragment>
      <HeaderWrapper>
        <BreadCrumbWrapper>
          <BackButton
            onClick={() => {
              resetBusinessLocationDetailsReducerState();
              resetDeliveryZoneReducerState();
            }}
          >
            <ChevronLeftIcon />
            <div>Back to Location List</div>
          </BackButton>
          {Object.keys(businessInformationInitialValues).length > 0 &&
          !businessInformationInitialValues?.master_is_active ? (
            <LocationDeactivatedBanner deactivationTime={deactivationTime} />
          ) : null}
          <PageHeader>
            {isCreatingLocation ? `Add Location` : `Edit Location`}
          </PageHeader>
        </BreadCrumbWrapper>
        {businessId && currentRole === 'ROLE_SUPER_ADMIN' && (
          <Button
            onClick={() => setIsOpen(true)}
            disabled={!businessId || currentRole !== 'ROLE_SUPER_ADMIN'}
          >
            Copy Business Menu
          </Button>
        )}
      </HeaderWrapper>
      {!shouldComponentBeLoading ? (
        <ContentBody>
          <BusinessInformationFormContainer
            deactivateSection={deactivateSection}
            isBusinessAdminRole={isBusinessAdminRole}
          />
          <AddressAndDetailsContainer
            deactivateSection={false}
            isBusinessAdminRole={isBusinessAdminRole}
          />
          <HoursContainer
            deactivateSection={!businessId}
            businessId={businessId}
          />
          {is_delivery_enabled && (
            <DeliveryZonesContainer
              isCreatingLocation={isCreatingLocation}
              deactivateSection={false}
              isBusinessAdminRole={isBusinessAdminRole}
            />
          )}
          <PaymentIntegrationContainer deactivateSection={false} />
          <DeactivateLocationContainer deactivateSection={false} />
        </ContentBody>
      ) : (
        <LoadingBlueLarge />
      )}
      {isOpen && (
        <LocationFilterModalSingleSelect
          approveButtonName='Select Business'
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          initialBusiness={null}
          handleSave={handleCopyBusinessMenu}
        />
      )}
    </Fragment>
  );
};
export default BusinessWizardIndex;
