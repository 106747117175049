import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const WrapperInner = styled.div`
  display: grid;
  input[type='text'] {
    border: 1px solid #cdcdcd;
    padding: 13px 20px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 6px;
    width: 100%;
  }
  input[type='checkbox'] {
    height: 17px;
    width: 17px;
  }
`;
const BoxFlex = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
`;
const BoxCommonFlex = styled.div`
  display: flex;
  align-items: center;
`;
const LabelText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
`;
const LabelCheckBox = styled.span`
  line-height: 1;
  margin-left: 8px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: normal;
`;
const Tab3Comp = ({ values, setAvailabilityModalState }) => {
  const [codeQuantity, setCodeQuantity] = useState(0);
  const [codeLength, setCodeLength] = useState(0);
  const [isAlphaChecked, setIsAlphaChecked] = useState(false);
  const [isNumChecked, setIsNumChecked] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [disableAutoGenerate, setDisableAutoGenerate] = useState(true);
  const handleChange = () => {
    let RandomCharArray = [];
    // let RandomChar = Array(5)
    //   .fill(0)
    //   .map((x) => Math.random().toString(36).charAt(2))
    //   .join("");

    for (let i = 1; i <= codeQuantity; i++) {
      if (isAlphaChecked && isNumChecked) {
        let RandomChar = Array(+codeLength)
          .fill(0)
          .map((x) => Math.random().toString(36).charAt(2))
          .join('');
        RandomCharArray.push(prefix + RandomChar);
      } else if (isAlphaChecked && !isNumChecked) {
        var charset = 'abcdefghijklmnopqrstuvwxyz';

        var text = '';
        for (let j = 1; j <= +codeLength; j++) {
          text += charset[Math.floor(Math.random() * charset.length)];
        }
        let RandomChar = text;
        RandomCharArray.push(prefix + RandomChar);
      } else if (!isAlphaChecked && isNumChecked) {
        let RandomChar = Math.floor(
          Math.pow(10, codeLength - 1) +
            Math.random() *
              (Math.pow(10, codeLength) - Math.pow(10, codeLength - 1) - 1)
        );
        RandomCharArray.push(prefix + RandomChar);
      }
    }
    //let tabOneData = RandomCharArray.split('\n');
    let tabtwoData = RandomCharArray.map((tabData) => tabData);
    tabtwoData.forEach((promo) => {
      values.offerPromoCode.push({
        code: promo,
        is_used: 'false',
      });
    });
    setAvailabilityModalState(false);
  };

  useEffect(() => {
    if (codeQuantity && codeLength && (isAlphaChecked || isNumChecked)) {
      setDisableAutoGenerate(false);
    }
  });
  return (
    <WrapperInner>
      <BoxFlex>
        <div>
          <LabelText>Code quantity</LabelText>
          <input
            type='text'
            name='quantity'
            onChange={(e) => setCodeQuantity(e.target.value)}
          />
        </div>
        <div>
          <LabelText>Code length (excludes prefix)</LabelText>
          <input type='text' onChange={(e) => setCodeLength(e.target.value)} />
        </div>
      </BoxFlex>
      <div>
        <LabelText>Character format</LabelText>
        <BoxCommonFlex>
          <input
            type='checkbox'
            id='Alphabet'
            name='Alphabet'
            LabelText='Alphabet'
            value={isAlphaChecked}
            onChange={(e) => setIsAlphaChecked(!isAlphaChecked)}
          />
          <LabelCheckBox>Alphabet</LabelCheckBox>
          <input
            type='checkbox'
            id='Number'
            name='Number'
            value={isNumChecked}
            onChange={(e) => setIsNumChecked(!isNumChecked)}
          />
          <LabelCheckBox>Number</LabelCheckBox>
        </BoxCommonFlex>
      </div>
      <div>
        <LabelText>Code prefix (Optional)</LabelText>
        <input type='text' onChange={(e) => setPrefix(e.target.value)} />
      </div>
      <div className='textRight'>
        <button
          className='actionButton'
          disabled={disableAutoGenerate}
          onClick={handleChange}
        >
          Autogenerate code
        </button>
      </div>
    </WrapperInner>
  );
};

export default Tab3Comp;
