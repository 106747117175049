import React from 'react';
import {
  SectionWrapper,
  SectionTitle,
  InfoWrapper,
  InfoTitle,
  InfoDesc,
  ProductWrapper,
} from './styles/styles';

export const ProductsOrderedInfo = ({ orderDetails }) => {
  const displayedPrice = (price, qty) => {
    let finalPrice = price * qty;

    return '$' + finalPrice.toFixed(2);
  };
  const displayProduct = (product) => {
    if (product.options.length > 0) {
      return (
        <>
          <ProductWrapper margin='0 0 10px 0'>
            <InfoTitle isCapital align='center'>
              {product.quantity}
            </InfoTitle>
            <InfoTitle isCapital>{product.name}</InfoTitle>
            <InfoTitle isCapital>{product?.price}</InfoTitle>
          </ProductWrapper>
          {product.options.map((option, optionIndex) => {
            return (
              <>
                {option.items?.length > 0 &&
                  option.items?.map((item, itemIndex) => {
                    return (
                      <>
                        <ProductWrapper keys={itemIndex} margin='0 0 10px 0'>
                          <InfoDesc isCapital position={2}>
                            - {`${item.name} `}
                            <span style={{ textTransform: 'none' }}>
                              (x{item.quantity || 1})
                            </span>
                          </InfoDesc>
                          {item.sub_items.length === 0 && (
                            <InfoDesc position={3}>
                              {displayedPrice(
                                item?.prices[0]?.price,
                                product.quantity
                              )}
                            </InfoDesc>
                          )}
                        </ProductWrapper>
                        {item.sub_items.length > 0 &&
                          item.sub_items.map((item, itemIndex) => {
                            return (
                              <ProductWrapper
                                keys={itemIndex}
                                margin='0 0 10px 0'
                              >
                                <InfoDesc isCapital position={2}>
                                  - - {`${item.name} `}
                                  <span style={{ textTransform: 'none' }}>
                                    (x{item.quantity || 1})
                                  </span>
                                </InfoDesc>
                                <InfoDesc position={3}>
                                  {displayedPrice(
                                    item?.prices[0]?.price,
                                    product.quantity
                                  )}
                                </InfoDesc>
                              </ProductWrapper>
                            );
                          })}
                      </>
                    );
                  })}
                {option.sub_options?.length > 0 &&
                  option.sub_options?.map((subOption, itemIndex) => {
                    return (
                      <>
                        {subOption.items?.length > 0 &&
                          subOption.items?.map((item, itemIndex) => {
                            return (
                              <>
                                <ProductWrapper
                                  keys={itemIndex}
                                  margin='0 0 10px 0'
                                >
                                  <InfoDesc isCapital position={2}>
                                    - {`${item.name} `}
                                    <span style={{ textTransform: 'none' }}>
                                      (x{item.quantity || 1})
                                    </span>
                                  </InfoDesc>
                                  {item.sub_items.length === 0 && (
                                    <InfoDesc position={3}>
                                      {displayedPrice(
                                        item?.prices[0]?.price,
                                        product.quantity
                                      )}
                                    </InfoDesc>
                                  )}
                                </ProductWrapper>
                                {item.sub_items.length > 0 &&
                                  item.sub_items.map((item, itemIndex) => {
                                    return (
                                      <ProductWrapper
                                        keys={itemIndex}
                                        margin='0 0 10px 0'
                                      >
                                        <InfoDesc isCapital position={2}>
                                          - - {`${item.name} `}
                                          <span
                                            style={{ textTransform: 'none' }}
                                          >
                                            (x{item.quantity || 1})
                                          </span>
                                        </InfoDesc>
                                        <InfoDesc position={3}>
                                          {displayedPrice(
                                            item?.prices[0]?.price,
                                            product.quantity
                                          )}
                                        </InfoDesc>
                                      </ProductWrapper>
                                    );
                                  })}
                              </>
                            );
                          })}
                      </>
                    );
                  })}
              </>
            );
          })}
        </>
      );
    } else {
      return (
        <ProductWrapper margin='0 0 10px 0'>
          <InfoTitle isCapital align='center'>
            {product.quantity}
          </InfoTitle>
          <InfoTitle isCapital>{product.name}</InfoTitle>
          <InfoTitle isCapital>{product?.price}</InfoTitle>
        </ProductWrapper>
      );
    }
  };
  return (
    <SectionWrapper>
      <SectionTitle>Products Ordered</SectionTitle>
      <ProductWrapper margin='0 0 30px 0'>
        <InfoTitle align='center'>Qty</InfoTitle>
        <InfoTitle>Product</InfoTitle>
        <InfoTitle>Total</InfoTitle>
      </ProductWrapper>
      {orderDetails.products.map((product) => displayProduct(product))}
    </SectionWrapper>
  );
};

export default ProductsOrderedInfo;
