import React, { useState, useEffect } from 'react';
import OffersEngineListContainer from './OffersEngineList/OffersEngineListContainer';
import OfferEngineDetailsContainer from './OfferEngineDetails/OfferEngineDetailsContainer';
import { connect } from 'react-redux';
import { getProducts } from '../MenuManagement/Products/productsActions';
import * as actions from './OfferEngineActions';
import { isOnlyLoyaltyAdminUser } from '../../utils/appRoles';

const OffersContainer = ({
  isCreatingOffer,
  isEditingEngineOffer,
  history,
  getProducts,
  requestParams,
  updateOffersRequestParams,
  offerReorderRequestParams,
  updateOfferReorderingRequestParams,
  getOffersPosition,
  allOffersPositions,
  getLoyaltyTiers,
  appConfiguration,
}) => {
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    const hasLoyaltyTier =
      localStorage.getItem('is_tiers_enabled') &&
      Boolean(localStorage.getItem('is_tiers_enabled'));
    if (hasLoyaltyTier) {
      getLoyaltyTiers();
    }
    if (!isOnlyLoyaltyAdminUser(appConfiguration)) {
      getProducts({
        page_number: 1,
        page_size: '4000',
        sorting_option: 'title-asc',
        search_string: '',
        has_next_page: false,
      });
    }
    return () => {
      updateOfferReorderingRequestParams({
        ...offerReorderRequestParams,
        page_number: 1,
      });
      updateOffersRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  if (isCreatingOffer || isEditingEngineOffer) {
    return <OfferEngineDetailsContainer history={history} />;
  } else {
    return (
      <OffersEngineListContainer
        searchString={searchString}
        setSearchString={setSearchString}
        allOffersPositions={allOffersPositions}
        updateOfferReorderingRequestParams={updateOfferReorderingRequestParams}
        getOffersPosition={getOffersPosition}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  isCreatingOffer: state.OfferEngineReducer.isCreatingOffer,
  allOffersPositions: state.OfferEngineReducer.allOffersPositions,
  requestParams: state.OfferEngineReducer.requestParams,
  offerReorderRequestParams: state.OfferEngineReducer.offerReorderRequestParams,
  isEditingEngineOffer: state.OfferEngineReducer.isEditingEngineOffer,
  appConfiguration: state.accountReducer.appMetaData.configuration,
});

export default connect(mapStateToProps, { ...actions, getProducts })(
  OffersContainer
);
