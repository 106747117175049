import React from 'react';
import styled from 'styled-components';

const NoDataMessageWrapper = styled.div`
  min-width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
`;
const NoDataMessage = ({ isActive, type, len }) => {
  const noDataMessage = `No ${isActive ? 'Active' : 'Inactive'} ${type}`;
  return (
    len === 0 && <NoDataMessageWrapper>{noDataMessage}</NoDataMessageWrapper>
  );
};

export default NoDataMessage;
