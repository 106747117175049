const appIdentifier = localStorage.getItem('appIdentifier');

export const newOfferEngineClients = [
  'chaiiwala',
  'daily_ritual_cafe',
  'double_pizza',
  'smooth_cafe',
  'mary_browns',
  'bigwheelburger',
  'harvestcleaneats',
  'toppers',
];
export const multipleAppFeedItemClients = ['deloitte'];

export const brinkClients = ['madradish', 'chillipeppers', 'good_earth'];
export const orderManagementClients = ['feastify', 'basilbox_catering'];
export const isParentChildClient = ['feastify'].includes(appIdentifier);
