import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Card from '../../../components/Card/Card';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AvailableHoursGenric from '../components/AvailableHoursGenric';
import {
  productAvailableAllTime,
  isProductAvailableAllTimeFunction,
} from '../utils/helper.js';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { ICON_AFTER_POSITION } from 'react-dates/esm/constants';
import PromoListContainer from '../OffersEnginePromo/OffersEnginePromoContainer';
import MiniToggleSwitch from '../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
import PromoModal from '../components/PromoModal';
const Wrapper = styled.div`
  display: grid;
  align-items: center;

  .mb25 {
    margin-bottom: 25px;
  }
`;
const WrapperInner = styled.div`
  display: grid;
  align-items: center;
  margin-left: 30px;
`;
const Subtitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #191818;
`;
const FullWrapper = styled.div`
  display: grid;

  .formSection {
    display: grid;
  }
`;
const NoteText = styled.div`
  font-size: 14px;
  color: #191818;
  margin-bottom: 10px;
  margin-left: 30px;
  span {
    font-weight: 600;
  }
`;
const NoteHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 5px;
`;
const NoteHeadNormal = styled.span`
  font-size: 14px;
  color: #191818;
  font-weight: normal;
`;
const SubHead = styled.div`
  font-size: 17px;
  color: #191818;
  font-weight: 600;
  margin-bottom: 15px;
`;
const SingleDatePickerWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  gap: 30px 20px;
  align-items: end;

  .errorClass {
    border: 1px solid red;
  }
`;

const DatePickerMain = styled.div`
  height: 80px;
  padding-top: ${(props) => props.paddingTop || 0};
`;
const DatePickerLabel = styled.div`
  font-weight: bold;
`;

const SlideFour = ({
  values,
  setFieldValue,
  offerAvailabilities,
  offerPromoCode,
  getPromos,
  updatePromoRequestParams,
  requestParamsPromo,
  setVisibleofferButton,
  errors,
}) => {
  const [selectedPromoCode, setSelectedPromoCode] = useState();
  const [activationType, setActivationType] = useState('manual');
  const [availabilityModalState, setAvailabilityModalState] = useState(false);

  const toggleAvailableHoursModal = () => {
    setAvailabilityModalState(!availabilityModalState);
  };

  const [age, setAge] = React.useState('');
  const [startDateFocusedState, setstartDateFocusedState] = useState(false);
  const [endDateFocusedState, setendDateFocusedState] = useState(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [isProductAvailableAllTime, setIsProductAvailableAllTime] = useState(
    isProductAvailableAllTimeFunction(values.offer_details.availability)
  );
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    if (offerPromoCode?.length > 0) {
      setActivationType('promo');
      setFieldValue('offer_details.auto_redeem', false);
    } else {
      if (values.offer_details.auto_redeem) {
        setActivationType('auto');
      } else {
        setActivationType('manual');
      }
    }
    return () => {
      updatePromoRequestParams({
        ...requestParamsPromo,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  const checkActivationType = useCallback(() => {}, [offerPromoCode]);
  useEffect(() => {
    checkActivationType();
  }, [checkActivationType]);
  const [selectedstartdate, setSelectedstartdate] = useState(
    values.offer_details.start_date
      ? moment(values.offer_details.start_date)
      : null
  );
  const [selectedenddate, setSelectedenddate] = useState(
    values.offer_details.end_date ? moment(values.offer_details.end_date) : null
  );
  const [toggleIsEnable, setToggleIsEnable] = useState(
    !values.offer_details.end_date
  );
  const [dateValidation, setdateValidation] = useState('');
  useEffect(() => {
    const formatedStartTime = moment(selectedstartdate).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    setFieldValue('offer_details.start_date', formatedStartTime);
  }, [selectedstartdate]);
  useEffect(() => {
    if (selectedenddate) {
      const formatedEndTime = moment(selectedenddate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      setFieldValue('offer_details.end_date', formatedEndTime);
    }
  }, [selectedenddate]);
  return (
    <Wrapper>
      {availabilityModalState && (
        <PromoModal
          toggleAvailableHoursModal={toggleAvailableHoursModal}
          values={values}
          setFieldValue={setFieldValue}
          setAvailabilityModalState={setAvailabilityModalState}
          selectedPromoCode={selectedPromoCode}
          setSelectedPromoCode={setSelectedPromoCode}
        ></PromoModal>
      )}
      <Subtitle>Availability</Subtitle>
      <Card>
        <SingleDatePickerWrapper>
          <DatePickerMain>
            <DatePickerLabel>Start Date *</DatePickerLabel>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDateTimePicker
                // label='Start time'
                placeholder='Start Time'
                inputVariant='outlined'
                value={selectedstartdate}
                onChange={setSelectedstartdate}
                // disablePast
                format='yyyy/MM/DD HH:mm'
              />
              {errors?.offer_details?.start_date && (
                <div style={{ color: 'red' }}>
                  {errors?.offer_details?.start_date}
                </div>
              )}
            </MuiPickersUtilsProvider>
          </DatePickerMain>
          <DatePickerMain
            className={dateValidation === 'Invalid date' ? 'errorClass' : ''}
          >
            <DatePickerLabel>End Date</DatePickerLabel>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDateTimePicker
                // label='End Time *'
                placeholder='End Time'
                inputVariant='outlined'
                value={selectedenddate === '' ? null : selectedenddate}
                onChange={setSelectedenddate}
                // disablePast
                mask='____/__/__  __:__'
                format='yyyy/MM/DD HH:mm'
                disabled={toggleIsEnable}
                minDate={selectedstartdate}
                minDateMessage='Expiry date cannot be before Start date'
              />
              {errors?.offer_details?.end_date && (
                <div style={{ color: 'red' }}>
                  {errors?.offer_details?.end_date}
                </div>
              )}
            </MuiPickersUtilsProvider>
          </DatePickerMain>
          <DatePickerMain paddingTop='40px'>
            <MiniToggleSwitch
              label='Offer never expires'
              checked={toggleIsEnable}
              onChange={(e) => {
                setToggleIsEnable(e.target.checked);
                setFieldValue(
                  'offer_details.end_date',
                  e.target.checked ? '' : ''
                );
                setFieldValue('offer_details.never_expires', e.target.checked);
                setSelectedenddate('');
              }}
            ></MiniToggleSwitch>
          </DatePickerMain>
        </SingleDatePickerWrapper>
        <SubHead>Availability Time</SubHead>
        <RadioGroup
          aria-labelledby='radio-availability-group-label'
          value={values.offerIsofferAvailableAllTime == true ? 'true' : 'false'}
          id='radio-availability-group'
          name='radio-availability-group'
          onChange={(event) => {
            handleChange(event);
            if (event.target.value == 'true') {
              const allTimeOffer = productAvailableAllTime();
              values.offerAvailabilities = allTimeOffer;
            }
            setFieldValue(
              'offerIsofferAvailableAllTime',
              event.target.value == 'true' ? true : false
            );
          }}
        >
          <FormControlLabel
            value={'true'}
            control={<Radio />}
            label='Offer is always available'
          />
          <FormControlLabel
            value='false'
            control={<Radio />}
            label='Offer is available at specific times of the week'
          />
        </RadioGroup>
        {values.offerIsofferAvailableAllTime == false && (
          <AvailableHoursGenric
            offerAvailabilities={offerAvailabilities}
            setFieldValue={setFieldValue}
            setIsProductAvailableAllTime={setIsProductAvailableAllTime}
            isProductAvailableAllTime={isProductAvailableAllTime}
          ></AvailableHoursGenric>
        )}
      </Card>
      <Subtitle>Activation</Subtitle>
      <Card>
        <SubHead>How is the offer activated?</SubHead>
        <RadioGroup
          aria-labelledby='radio-activation-group-label'
          value={activationType}
          id='radio-activation-group'
          name='radio-activation-group'
          onChange={(event) => {
            handleChange(event);
            if (event.target.value == 'manual') {
              setActivationType('manual');
              setFieldValue('offer_details.auto_redeem', false);
              setFieldValue('offerPromoCode', []);
            } else if (event.target.value == 'auto') {
              setActivationType('auto');
              setFieldValue('offer_details.auto_redeem', true);
              setFieldValue('offerPromoCode', []);
            } else {
              setActivationType('promo');
              setFieldValue('offer_details.auto_redeem', false);
            }
          }}
        >
          <FormControlLabel
            value='manual'
            control={<Radio />}
            label='Manually - by the customer'
          />
          <FormControlLabel
            value='auto'
            control={<Radio />}
            label='Automatically - when the customer becomes eligible'
          />
          <FormControlLabel
            value='promo'
            control={<Radio />}
            label='By entering a promo code'
          />
        </RadioGroup>
        {activationType === 'promo' && (
          <>
            <NoteText>
              Customers can activate the offer by entering a promo code{' '}
              <span>on the review order screen.</span> This promo code could be
              set up to be shareable or unshareable.
            </NoteText>
            <PromoListContainer
              searchString={searchString}
              setSearchString={setSearchString}
              getPromos={getPromos}
              availabilityModalState={availabilityModalState}
              setAvailabilityModalState={setAvailabilityModalState}
              offerPromoCode={values.offerPromoCode}
              setFieldValue={setFieldValue}
              requestParamsPromo={requestParamsPromo}
              updatePromoRequestParams={updatePromoRequestParams}
              setSelectedPromoCode={setSelectedPromoCode}
              values={values}
            ></PromoListContainer>
          </>
        )}
      </Card>
      <Subtitle>Reusability</Subtitle>
      <Card>
        <SubHead>How many times can the customer redeem this offer?</SubHead>
        <RadioGroup
          aria-labelledby='radio-reusability-group-label'
          value={values.offer_details.reusable == true ? 'true' : 'false'}
          id='radio-reusability-group'
          name='radio-reusability-group'
          onChange={(event) => {
            handleChange(event);
            setFieldValue(
              'offer_details.reusable',
              event.target.value == 'true' ? true : false
            );
          }}
        >
          <FormControlLabel value='false' control={<Radio />} label='Once' />
          <FormControlLabel
            value='true'
            control={<Radio />}
            label='Unlimited number of times'
          />
        </RadioGroup>
      </Card>
    </Wrapper>
  );
};
export default SlideFour;
