import React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { ExpireWrapper, SingleDatePickerWrapper } from '../styles/style.js';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import { ICON_AFTER_POSITION } from 'react-dates/esm/constants';

const DatesRow = ({
  values,
  setFieldValue,
  startDateFocusedState,
  setstartDateFocusedState,
  expiryFocusedState,
  setexpiryFocusedState,
  handleChangeExpiry,
  expire,
}) => {
  return (
    <div>
      <div>
        <p>Start Date:</p>
        <SingleDatePickerWrapper>
          <SingleDatePicker
            date={moment(values.comboStartDate)}
            onDateChange={(date) => {
              setFieldValue('comboStartDate', date);
            }}
            focused={startDateFocusedState}
            onFocusChange={({ focused }) => setstartDateFocusedState(focused)}
            placeholder='Start Date'
            isOutsideRange={() => false}
            id='start_date_id'
            readOnly={true}
            showDefaultInputIcon={true}
            inputIconPosition={ICON_AFTER_POSITION}
          />
        </SingleDatePickerWrapper>
      </div>
      <ExpireWrapper>
        <SmallToggleSwitch
          checked={!expire}
          onChange={({ target: { checked } }) =>
            handleChangeExpiry(checked, setFieldValue)
          }
          value='checkedB'
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label='Never Expires'
        />
        {expire && (
          <div>
            <p>End Date:</p>
            <SingleDatePickerWrapper>
              <SingleDatePicker
                date={moment(values.comboExpiryDate)}
                onDateChange={(date) => {
                  setFieldValue('comboExpiryDate', date);
                }}
                focused={expiryFocusedState}
                onFocusChange={({ focused }) => setexpiryFocusedState(focused)}
                placeholder='End Date'
                isOutsideRange={() => false}
                id='expiry_date_id'
                readOnly={true}
                showDefaultInputIcon={true}
                inputIconPosition={ICON_AFTER_POSITION}
              />
            </SingleDatePickerWrapper>
          </div>
        )}
      </ExpireWrapper>
    </div>
  );
};

export default DatesRow;
