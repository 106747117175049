const copyToClipboard = (item, createAlert) => {
  let textArea = document.createElement('textarea');
  textArea.value = item.url;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    createAlert({
      type: 'success',
      message: 'Copied ' + item.name + ' successfully'
    });
  } catch (err) {
    createAlert({
      type: 'error',
      message: err?.response?.data?.errors?.message || 'Not copied!'
    });
  }
  document.body.removeChild(textArea);
};

export default copyToClipboard;
