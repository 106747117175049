import {
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
  FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
} from "./types";

export default function (
  state = {
    requestingGetBusinessLocationsOfMerchant: false,
    businessLocationsOfMerchant: [],
    subBusinessLocationsOfMerchant: [],
    selectedBusinessLocationsOfMerchant: [],
    requestParams: {
      page_number: 1,
      page_size: 25,
    },
  },
  action
) {
  switch (action.type) {
    case GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST:
      return {
        ...state,
        requestingGetBusinessLocationsOfMerchant: true,
        businessLocationsOfMerchant: [],
        subBusinessLocationsOfMerchant: [],
      };
    case GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        requestingGetBusinessLocationsOfMerchant: false,
        businessLocationsOfMerchant: action.businessLocationsOfMerchant,
        subBusinessLocationsOfMerchant: action.subBusinessLocationsOfMerchant,
        selectedBusinessLocationsOfMerchant:
          action.selectedBusinessLocationsOfMerchant,
      };
    case FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS:
      return {
        ...state,
        selectedBusinessLocationsOfMerchant: [action.payload],
      };
    case "UPDATE_LOCATION_FILTERING_REQUEST_PARAMS":
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
