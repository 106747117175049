import React, { Fragment } from 'react';
import styled from 'styled-components';
import Icon, { EditIcon } from '../../../../components/Icon';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import PhoneNumber from '../../../../components/PhoneNumber';

const TitleGridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
`;

const Title = styled.p`
  font-weight: 600;
  margin: 0 45px 45px 0;
  font-size: 20px;
`;

const IconWrapperEdit = styled.span`
  width: 15px;
  height: 15px;
  margin: 2px 0 2px 8px;
  font-family: FontAwesome5Pro;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bebebe;
`;
const IconWrapper = styled.span`
  width: 15px;
  height: 15px;
  margin: 2px 0 2px 8px;
  font-family: FontAwesome5Pro;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;

const CustomerIsActiveWrapper = styled.div`
  padding-top: 16px;
  align-self: center;
  margin-right: auto;
  grid-column: 1/2;
  grid-row: 1/2;
`;

const CustomerFieldRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 75px;
  padding: 10px 0 20px 0;
`;

const StyledP = styled.p``;

const CustomerFieldWrapper = styled.div`
  min-width: 150px;
  word-break: break-word;
`;

const ButtonP = styled.p`
  color: ${(props) => (props.disabled ? '#c1bfbf' : props.theme.blue)};
  cursor: pointer;
  font-weight: 600;
`;
const Deactivecustomer = styled.span`
  color: ${(props) => (props.disabled ? '#919FD6' : props.theme.blue)};
  cursor: pointer;
  font-weight: 600;
`;

const PhoneVerificationWrapper = styled.div`
  padding-top: 16px;
  align-self: center;
  margin-right: auto;
  grid-column: 2/3;
  grid-row: 1/2;
`;

const Wrapper = styled.div`
  padding: 37px 37px 0 37px;
`;

const NonField = styled.div`
  font-size: 16px !important;
  border: none;
  border-radius: none;
  height: 40px;
  width: 100%;
  padding: 10px 10px 10px 0px;
  font-weight: 600;
`;

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;

const AccountInformation = (props) => {
  const {
    customerDetails,
    accountActivationConfirmModalToggleVisibility,
    accountInformationEditModalToggleVisibility,
    handleConfirmPhoneNumber,
  } = props;
  let disablecustomer;
  if (customerDetails.is_deleted) {
    disablecustomer = true;
  }

  props.Accontclick(customerDetails.is_deleted);

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      maxWidth: '453px',
      borderRadius: '7px',
      padding: '8px 9px 9px 12px',
      borderRadius: '7px',
      fontSize: '12px',
      marginTop: '40px',
      fontFamily: 'Montserrat',
      backgroundColor: 'rgba(0, 0, 0, 0.8);',
      color: '#ffffff',
    },
    button: {
      backgroundColor: '#2440ae',
      color: '#ffffff',
      maxHeight: '40px',
      width: '100%',
      borderRadius: '28px',
      fontSize: '14px',
      fontWeight: '600',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      textAlign: 'right',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3151d4',
      },
    },
  }));
  const classes = useStyles();
  function disableCustomerhandle() {
    if (!disablecustomer) {
      accountActivationConfirmModalToggleVisibility();
    }
  }
 
  return (
    <Fragment>
      <Wrapper>
        <TitleGridWrapper>
          <Title data-test='component-AccountInformationTitle'>
            Account Information
            {customerDetails.is_deleted ? (
              <Tooltip
                classes={classes}
                title='Edit unavailable because this account is deleted.'
                placement='right'
              >
                <IconWrapperEdit>
                  <EditIcon
                    onClick={accountInformationEditModalToggleVisibility}
                    disabled={disablecustomer}
                  />
                </IconWrapperEdit>
              </Tooltip>
            ) : (
              <IconWrapper>
                <EditIcon
                  onClick={accountInformationEditModalToggleVisibility}
                />
              </IconWrapper>
            )}
          </Title>
        </TitleGridWrapper>
        <CustomerFieldRow>
          <CustomerFieldWrapper>
            <StyledP>Customer First Name</StyledP>
            <NonField>{customerDetails.first_name}</NonField>
          </CustomerFieldWrapper>
          <CustomerFieldWrapper>
            <StyledP>Customer Last Name</StyledP>
            <NonField>{customerDetails.last_name}</NonField>
          </CustomerFieldWrapper>
          <CustomerFieldWrapper>
            <StyledP>Status</StyledP>
            <NonField>
              {customerDetails.is_deleted
                ? 'Deleted'
                : customerDetails.is_active
                ? 'Active'
                : 'Inactive'}
            </NonField>
          </CustomerFieldWrapper>
        </CustomerFieldRow>
        <CustomerFieldRow>
          <CustomerFieldWrapper>
            <StyledP>Email</StyledP>
            <NonField>{customerDetails.email}</NonField>
          </CustomerFieldWrapper>
          <CustomerFieldWrapper>
            <StyledP>Phone Number</StyledP>
            {/* <NonField>{customerDetails.detail.mobile_phone}</NonField> */}
            <PhoneNumber
              name='mobilePhone'
              value={customerDetails.detail.mobile_phone}
              variant='outlined'
              defaultPhoneValue={customerDetails.detail.mobile_phone}
              disabled
            />
          </CustomerFieldWrapper>
          <CustomerFieldWrapper>
            <StyledP>Company Name</StyledP>
            <NonField>
              {customerDetails.detail.company_name
                ? customerDetails.detail.company_name
                : '--'}
            </NonField>
          </CustomerFieldWrapper>
        </CustomerFieldRow>
        <CustomerFieldRow>
          <CustomerFieldWrapper>
            <StyledP>Customer Birthday</StyledP>
            <NonField>
              {customerDetails.detail.birthdate !== null
                ? moment(customerDetails.detail.birthdate).format('MM/DD/YYYY')
                : 'Not Provided'}
            </NonField>
          </CustomerFieldWrapper>
          {
          <CustomerFieldWrapper>
            <StyledP>Registration Date</StyledP>
            {
              !customerDetails?.is_guest?
                <NonField>
                {customerDetails.created_at !== null
                  ? moment(customerDetails.created_at).format('MM/DD/YYYY')
                  : 'Not Provided'}
                </NonField>
                :
                <NonField>
                  {'Guest user'}
                </NonField>
            }
          </CustomerFieldWrapper>
          }
        </CustomerFieldRow>
        <CustomerFieldRow>
          <PhoneVerificationWrapper>
            {!customerDetails.is_sms_confirmed ? (
              <ButtonP onClick={handleConfirmPhoneNumber}>
                Confirm phone number
              </ButtonP>
            ) : (
              <ButtonP disabled>
                SMS Confirmed <Icon className='fas fa-check-circle' />
              </ButtonP>
            )}
          </PhoneVerificationWrapper>
          <CustomerIsActiveWrapper>
            <Deactivecustomer
              onClick={disableCustomerhandle}
              disabled={disablecustomer}
            >
              {customerDetails.is_active
                ? 'Deactivate this account'
                : 'Activate this account'}
            </Deactivecustomer>
            {customerDetails.is_deleted ? (
              <Tooltip
                classes={classes}
                title='Action unavailable because this account is deleted.'
                placement='right'
                style={{ marginTop: '100px' }}
              >
                <IconWrapper>
                  <i className='fal fa-info-circle'></i>
                </IconWrapper>
              </Tooltip>
            ) : (
              customerDetails.is_active && (
                <Tooltip
                  classes={classes}
                  title='Deactivate this account to prevent the customer from logging into their account. Once deactivated, the account can be reactivated at any time.'
                  placement='right'
                >
                  <IconWrapper>
                    <i className='fal fa-info-circle'></i>
                  </IconWrapper>
                </Tooltip>
              )
            )}
          </CustomerIsActiveWrapper>
        </CustomerFieldRow>
      </Wrapper>
      <HorizontalLine />
    </Fragment>
  );
};

export default AccountInformation;
