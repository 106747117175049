import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ProductsMappingContainer from './ProductsMapping/ProductsMappingContainer';
import ItemsMappingContainer from './ItemsMapping/ItemsMappingContainer';
import CombosMappingContainer from './CombosMapping/CombosMappingContainer';
import ComboItemGroupMappingContainer from './ComboItemGroupMapping/ComboItemGroupMappingContainer';
import CouponsMappingContainer from './CouponsMapping/CouponsMappingContainer';
import OffersMappingContainer from './OffersMapping/OffersMappingContainer';
import NoOptionVariantsSelected from '../../MenuManagement/PriceManager/components/NoOptionVariantsSelected';
import { Select } from 'merchant-portal-components';
import {
  setSelectedValuePOS,
  getPOSDiscountsMappingFields,
  getItemsPOSValues,
  getCombos,
  getComboItemGroup,
} from './posAction';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { getOptionPrices } from '../PriceManager/priceActions';
import { renderOptionsModified } from './ItemsMapping/helper/helper';
import ReactGA from 'react-ga';
import { isOnlyLoyaltyAdminUser } from '../../../utils/appRoles';
import { brinkClients } from '../../../utils/clientCustomizations';
const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  color: #191818;
  letter-spacing: 0.04px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 27px;
`;

const CardTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: #191818;
  margin-bottom: 26px;
`;

const MappingOptionsSelect = styled.div`
  width: 339px;
  padding-bottom: 24px;
`;

const ItemOptionSelect = styled.div`
  width: 339px;
  padding-bottom: 24px;
`;
const initialRequestParams = {
  page_number: 1,
  page_size: 2000,
  searchString: '',
};
const POSContainer = ({
  setSelectedValuePOS,
  getPOSDiscountsMappingFields,
  posDiscountsMappingFields,
  isShowingPosDiscount,
  isRequestingPosMappingFields,
  options,
  getItemsPOSValues,
  variants,
  itemsPosValues,
  optionPrices,
  getCombos,
  getComboItemGroup,
  allCombos,
  history,
  appConfiguration,
}) => {
  let appIdentifier = localStorage.getItem('appIdentifier');
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedProductId, setselectedProductId] = useState();
  const [selectedComboId, setSelectedComboId] = useState();
  useEffect(() => {
    isShowingPosDiscount && getPOSDiscountsMappingFields();
    ReactGA.pageview(window.location.pathname, undefined, 'POS Mapping');
  }, []);
  useEffect(() => {
    if (selectedValue?.id === 5) {
      getCombos(initialRequestParams);
    }
  }, [selectedValue?.id]);
  const renderMappingOptions = () => {
    if (isOnlyLoyaltyAdminUser(appConfiguration)) {
      return [
        {
          id: 3,
          name: 'Offers',
        },
      ];
    } else if (brinkClients.includes(appIdentifier)) {
      return [
        {
          id: 0,
          name: 'Products',
        },
        {
          id: 1,
          name: 'Items/Sub-Items',
        },
        {
          id: 2,
          name: 'Combos',
        },
        {
          id: 5,
          name: 'Combo items group',
        },
        {
          id: 3,
          name: 'Offers',
        },
        {
          id: 4,
          name: 'Coupons',
        },
      ];
    } else if (isShowingPosDiscount)
      return [
        {
          id: 0,
          name: 'Products',
        },
        {
          id: 1,
          name: 'Items/Sub-Items',
        },
        {
          id: 2,
          name: 'Combos',
        },
        {
          id: 3,
          name: 'Offers',
        },
        {
          id: 4,
          name: 'Coupons',
        },
      ];
    else
      return [
        {
          id: 0,
          name: 'Products',
        },
        {
          id: 1,
          name: 'Items/Sub-Items',
        },
      ];
  };

  const type = (selectedValue) => {
    return selectedValue.name.slice(0, -1).toLowerCase();
  };
  useEffect(() => {
    if (history?.location?.state?.type === 'product') {
      const foundPOSType = renderMappingOptions().find(
        (item) => item.name === 'Products'
      );
      if (foundPOSType) {
        setselectedProductId(history?.location?.state?.product_id);
        setSelectedComboId();
        setSelectedValue(foundPOSType);
        setSelectedValuePOS([]);
        setSelectedOption(null);
      }
    }
    if (history?.location?.state?.type === 'item') {
      const foundPOSType = renderMappingOptions().find(
        (item) => item.name === 'Items/Sub-Items'
      );
      const foundOption = renderOptionsModified(options).find(
        (item) => item.id === parseInt(history?.location?.state?.option_id)
      );
      if (foundPOSType && foundOption) {
        setSelectedValue(foundPOSType);
        setSelectedOption(foundOption);
        setselectedProductId();
        setSelectedComboId();
      }
    }
    if (history?.location?.state?.type === 'combo') {
      const foundPOSType = renderMappingOptions().find(
        (item) => item.name === 'Combos'
      );
      if (foundPOSType) {
        setSelectedValue(foundPOSType);
        setselectedProductId();
        setSelectedComboId(history?.location?.state?.combo_id);
        setSelectedValuePOS([]);
        setSelectedOption(null);
        // getCombos(initialRequestParams);
      }
    }
    if (history?.location?.state?.type === 'combo-item') {
      const foundPOSType = renderMappingOptions().find(
        (item) => item.name === 'Combo items group'
      );
      const foundOption = allCombos.find(
        (combo) => combo.id === history?.location?.state.combo_id
      );
      if (foundPOSType) {
        0;
        setSelectedValue(foundPOSType);
        setselectedProductId();
        setSelectedComboId();
        setSelectedValuePOS([]);
        setSelectedOption(foundOption);
      }
    }
  }, [history?.location, options, allCombos]);
  if (isRequestingPosMappingFields) return <LoadingBlueLarge />;
  return (
    <div>
      <Title>POS Mapping</Title>
      <Wrapper>
        <CardTitle>Which part of the menu would you like to map?</CardTitle>
        <MappingOptionsSelect>
          <Select
            options={renderMappingOptions()}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(option) => {
              setSelectedValue(option);
              setSelectedValuePOS([]);
              setSelectedOption(null);
            }}
            value={selectedValue}
            valueKey='id'
            required
            placeholder='Select the menu to map'
            // isSearchable={false}
          />
        </MappingOptionsSelect>
        {selectedValue.id === 1 && (
          <div>
            <CardTitle>Please select an Option/Sub-Option</CardTitle>
            <ItemOptionSelect>
              <Select
                options={renderOptionsModified(options)}
                getOptionLabel={(option) => `${option.name} - ${option.id}`}
                getOptionValue={(option) => option.id}
                onChange={(option) => {
                  setSelectedOption(option);
                }}
                value={selectedOption}
                valueKey='id'
                required
                placeholder='Select Option/Sub-Option'
              />
            </ItemOptionSelect>
          </div>
        )}
        {selectedValue.id === 5 && (
          <div>
            <CardTitle>Please select a combo</CardTitle>
            <ItemOptionSelect>
              <Select
                options={allCombos}
                getOptionLabel={(option) => `${option.name} - ${option.id}`}
                getOptionValue={(option) => option.id}
                onChange={(option) => {
                  setSelectedOption(option);
                }}
                value={selectedOption}
                valueKey='id'
                required
                placeholder='Select a combo'
              />
            </ItemOptionSelect>
          </div>
        )}
      </Wrapper>
      {selectedValue === -1 && (
        <NoOptionVariantsSelected
          text={'Please make a selection above to start POS Mapping'}
          customWidth
        />
      )}
      {selectedValue.id === 0 && (
        <ProductsMappingContainer
          selectedValue={selectedValue}
          selectedProductId={selectedProductId}
        />
      )}
      {selectedValue.id === 1 && selectedOption && (
        <ItemsMappingContainer
          items={itemsPosValues}
          selectedOption={selectedOption}
          getItemsPOSValues={getItemsPOSValues}
          variants={variants}
          optionPrices={optionPrices}
        />
      )}
      {selectedValue.id === 2 &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <CombosMappingContainer
            type={type(selectedValue)}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
            selectedComboId={selectedComboId}
            setSelectedComboId={setSelectedComboId}
          />
        )}
      {selectedValue.id === 5 &&
        selectedOption &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <ComboItemGroupMappingContainer
            type={selectedValue?.name}
            selectedOption={selectedOption}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
          />
        )}
      {selectedValue.id === 3 &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <OffersMappingContainer
            type={type(selectedValue)}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
          />
        )}
      {selectedValue.id === 4 &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <CouponsMappingContainer
            type={type(selectedValue)}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  posDiscountsMappingFields: state.posReducer.posDiscountsMappingFields,
  isShowingPosDiscount: state.posReducer.isShowingPosDiscount,
  isRequestingPosMappingFields: state.posReducer.isRequestingPosMappingFields,
  options: state.optionReducer.options,
  optionPrices: state.posReducer.optionPrices,
  variants: state.variantsReducer.allVariants,
  isRequestingItemsPosValues: state.posReducer.isRequestingItemsPosValues,
  itemsPosValues: state.posReducer.itemsPosValues,
  allCombos: state.posReducer.combos,
  appConfiguration: state.accountReducer.appMetaData.configuration,
});

export default connect(mapStateToProps, {
  getPOSDiscountsMappingFields,
  setSelectedValuePOS,
  getOptionPrices,
  getItemsPOSValues,
  getCombos,
  getComboItemGroup,
})(POSContainer);
