import { FieldArray, Formik } from 'formik';
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import { Modal } from 'merchant-portal-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PaginationControl from '../../../components/Pagination/PaginationControl';
import { paginate } from '../../../components/FilterModal/paginate';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  padding: 24px 24px 10px;
  font-size: 20px;
`;

const MessageWrapper = styled.div`
  padding: 5px 24px 0px 24px;
`;
const TableWrapper = styled.div`
  padding: 15px 30px 0px 30px;
`;
const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-gap: 20px;
  align-items: center;
  padding: 5px 30px;
  font-weight: ${(props) => (props.isHeader ? '600' : 'normal')};
  border-bottom: ${(props) =>
    props.isHeader ? '2px solid #000000' : '1px solid #d3d3d3'};
  border-left: ${(props) => (props.isHeader ? 'none' : '1px solid #d3d3d3')};
  border-right: ${(props) => (props.isHeader ? 'none' : '1px solid #d3d3d3')};
`;
const TD = styled.div`
  text-align: ${(props) => props.align || 'left'};
`;
const ModelContentWrapper = styled.div`
  width: 800px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ModalBody = styled.div`
  height: 60vh;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 1px #ebebeb;
`;
const PaginationWrapper = styled.div`
  padding: 25px 37px;
  display: flex;
  justify-content: right;
`;
const CreateOfferTypeModal = ({
  setIsCreateOfferTypeOpen,
  allOffersPositions,
  offerReorderRequestParams,
  updateOfferReorderingRequestParams,
  initiateCreateOffer,
  initiateEditOfferEngine,
}) => {
  const [offerType, setOfferType] = useState('blank');
  const [candidateOfferId, setCandidateOfferId] = useState();
  const [currentPaginatedPositions, setCurrentPaginatedPositions] = useState(
    []
  );

  useEffect(() => {
    return () => {
      updateOfferReorderingRequestParams({
        page_number: 1,
        page_size: '10',
        sorting_option: 'title-asc',
        search_string: '',
      });
    };
  }, []);
  const selectFromAnEsitingOffer = (e) => {
    const type = e.target.value;
    setOfferType(type);
    if (type === 'exsisting') {
      let paginatedList = paginate(
        allOffersPositions,
        offerReorderRequestParams.page_number,
        offerReorderRequestParams.page_size
      );
      setCurrentPaginatedPositions(paginatedList);
    } else {
      setCandidateOfferId(null);
    }
  };

  const filterPositionList = useCallback(() => {
    let paginatedList = paginate(
      allOffersPositions,
      offerReorderRequestParams.page_number,
      offerReorderRequestParams.page_size
    );
    setCurrentPaginatedPositions(paginatedList);
  }, [offerReorderRequestParams, allOffersPositions]);
  useEffect(() => {
    filterPositionList();
  }, [filterPositionList]);
  const closeOfferSortModal = () => {
    setIsCreateOfferTypeOpen(false);
  };

  const handleinitializeOffer = () => {
    if (candidateOfferId) {
      initiateEditOfferEngine(candidateOfferId, true);
    } else {
      initiateCreateOffer();
    }
    setIsCreateOfferTypeOpen(false);
  };
  const handleSelectingCandidateOffer = (item) => {
    setCandidateOfferId(item?.offer_id);
  };
  return (
    <Modal borderRadius>
      <ModelContentWrapper>
        <Fragment>
          <ModalTitle>
            <div style={{ fontWeight: 600 }}>Create Offer</div>
            <div
              onClick={closeOfferSortModal}
              style={{ cursor: 'pointer', justifySelf: 'center' }}
            >
              <i class='fa fa-times' />
            </div>
          </ModalTitle>
          <LineDivider />
          <MessageWrapper>
            <div style={{ fontWeight: 600 }}>
              Select an option to create offer from :
            </div>
            <RadioGroup
              aria-labelledby='radio-buttons-group-label'
              value={offerType}
              id='radio-buttons-group'
              name='radio-buttons-group'
              onChange={selectFromAnEsitingOffer}
            >
              <FormControlLabel
                value='blank'
                control={<Radio />}
                label='Create a NEW offer'
              />
              <FormControlLabel
                value='exsiting'
                control={<Radio />}
                label='Duplicate an offer (Select an existing offer to copy from)'
              />
            </RadioGroup>
          </MessageWrapper>
          {offerType === 'exsiting' && (
            <>
              <TableWrapper>
                <RowWrapper isHeader>
                  <TD>Offer Name</TD>
                  <TD>Selected offer</TD>
                </RowWrapper>
                {currentPaginatedPositions?.map((item, index) => (
                  <RowWrapper key={index}>
                    <TD>{item.name}</TD>
                    <TD align='center'>
                      <Radio
                        checked={item.offer_id === candidateOfferId}
                        onChange={() => handleSelectingCandidateOffer(item)}
                      />
                    </TD>
                  </RowWrapper>
                ))}
              </TableWrapper>
              <PaginationWrapper>
                <PaginationControl
                  response={{
                    ...currentPaginatedPositions,
                    page: offerReorderRequestParams.page_number,
                    page_size: offerReorderRequestParams.page_size,
                    count: allOffersPositions?.length,
                  }}
                  requestParams={offerReorderRequestParams}
                  pageInterval={[
                    { value: 10, label: '10' },
                    { value: 25, label: '25' },
                  ]}
                  type='offerReordering'
                />
              </PaginationWrapper>
            </>
          )}
          <ButtonsWrapper>
            <Button
              onClick={handleinitializeOffer}
              disabled={offerType !== 'blank' && !candidateOfferId}
            >
              Create Offer
            </Button>
          </ButtonsWrapper>
        </Fragment>
      </ModelContentWrapper>
    </Modal>
  );
};

export default CreateOfferTypeModal;
