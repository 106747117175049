import {
  LOAD_DETAIL_FEE_PAGE,
  LOAD_LIST_FEE_PAGE,
  GET_FEES_SUCCESS,
  CREATE_FEE_SUCCESS,
  PATCH_ACTIVE_SERVICEFEE,
  EDIT_FEE_SUCCESS,
  UPDATE_FEES_REQUEST_PARAMS,
  GET_FEES_OVERRIDE_SUCCESS,
  GET_SPECIFIC_OVERRIDE_SUCCESS,
  EMPTY_SELECTED_OVERRIDE,
  GET_SPECIFIC_FEE_SUCCESS,
  GET_REQUEST_FAILURE,
  UPDATE_FEES_OVERRIDE_LIST,
} from './feesTypes';
import { api } from '../../api';
import { createAlert } from '../Alert/actions';

export function loadServiceFeeDetailPage(isEditing, editingFee = null) {
  return {
    type: LOAD_DETAIL_FEE_PAGE,
    payload: { isEditing: isEditing, editingFee: editingFee },
  };
}

export function loadServiceFeeListPage() {
  return {
    type: LOAD_LIST_FEE_PAGE,
  };
}

export function updateRequestParams(requestParams) {
  return {
    type: UPDATE_FEES_REQUEST_PARAMS,
    payload: requestParams,
  };
}
// Controling Fees
export function toggleIsActiveFee(serviceFeeId, isActive) {
  return function (dispatch) {
    return api
      .patch(`/service-fees/${serviceFeeId}`, { is_active: isActive })
      .then(() => {
        dispatch({
          type: PATCH_ACTIVE_SERVICEFEE,
          payload: { id: serviceFeeId, isActive },
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Service fee updated successfully.',
          })
        );
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred trying to update the service fee.',
          })
        );
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}

export function getFees() {
  return function (dispatch) {
    // dispatch({ type: GET_FEES_REQUEST });
    return api
      .get('/service-fees?page_number=1&page_size=1000')
      .then((response) => {
        dispatch({ type: GET_FEES_SUCCESS, allFees: response.data.data });
      })
      .catch((error) => {
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}

export function createFee(formValues) {
  return function (dispatch) {
    return api
      .post('/service-fees', formValues)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Service fee successfully created.',
          })
        );
        dispatch({
          type: CREATE_FEE_SUCCESS,
          editingFee: response.data.data,
          isEditing: true,
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred trying to create service fee.',
          })
        );
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}
export function editFee(formValues, serviceFeeId) {
  return function (dispatch) {
    return api
      .patch(`/service-fees/${serviceFeeId}`, formValues)
      .then((response) => {
        dispatch({
          type: EDIT_FEE_SUCCESS,
          payload: { editingFee: response.data.data },
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Service fee updated successfully.',
          })
        );
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred trying to create service fee.',
          })
        );
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}
// Assigning locations
export function assignBusinessLocations(serviceFeeId, assinedBusinesses) {
  return function (dispatch) {
    return api
      .put(`/service-fees/${serviceFeeId}/businesses/assign`, assinedBusinesses)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message:
              'Business locations added to the service fee successfully.',
          })
        );
        api.get(`/service-fees/${serviceFeeId}`).then((response) => {
          dispatch({
            type: GET_SPECIFIC_FEE_SUCCESS,
            editingFee: response.data.data,
          });
        });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred trying to assign business locations.',
          })
        );
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}

// Locations Overrides
export function EmptyOverride() {
  return function (dispatch) {
    dispatch({
      type: EMPTY_SELECTED_OVERRIDE,
    });
  };
}
export function getAllOverrides(serviceFeeId) {
  return function (dispatch) {
    return api
      .get(
        `/service-fees/${serviceFeeId}/businesses?page_number=1&page_size=10`
      )
      .then((response) => {
        const overrides = response.data.data.filter(
          (business) => business.config
        );
        dispatch({
          type: GET_FEES_OVERRIDE_SUCCESS,
          allFeeOverrides: overrides,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}
export function getSpecificOverride(serviceFeeId, selectedBusinessId) {
  return function (dispatch) {
    return api
      .get(`/service-fees/${serviceFeeId}/businesses/${selectedBusinessId}`)
      .then((response) => {
        dispatch({
          type: GET_SPECIFIC_OVERRIDE_SUCCESS,
          selectedFeeOverride: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}
export function createFeeOverrides(formValues, serviceFeeId) {
  let selectedBusinessId = formValues.selectedBusinessId;
  delete formValues.selectedBusinessId;
  return function (dispatch, getState) {
    const allFeeOverrides = getState().feesReducer.allFeeOverrides;
    return api
      .patch(
        `/service-fees/${serviceFeeId}/businesses/${selectedBusinessId}`,
        formValues
      )
      .then((response) => {
        let updateedAllFeeOverrides = [...allFeeOverrides];
        const overrideIndex = allFeeOverrides.findIndex(
          (override) => override.business_id === response.data.data.business_id
        );
        if (overrideIndex !== -1) {
          updateedAllFeeOverrides[overrideIndex] = response.data.data;
        } else {
          updateedAllFeeOverrides.push(response.data.data);
        }
        dispatch({
          type: UPDATE_FEES_OVERRIDE_LIST,
          allFeeOverrides: updateedAllFeeOverrides,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Service fee override created/updated successfully.',
          })
        );
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred trying to create service fee.',
          })
        );
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}

export function deleteSpecificOverride(serviceFeeId, selectedBusinessId) {
  return function (dispatch, getState) {
    const allFeeOverrides = getState().feesReducer.allFeeOverrides;
    return api

      .patch(`/service-fees/${serviceFeeId}/businesses/${selectedBusinessId}`, {
        config: null,
      })
      .then(() => {
        let updateedAllFeeOverrides = [...allFeeOverrides];
        const overrideIndex = allFeeOverrides.findIndex(
          (override) => override.business_id === selectedBusinessId
        );
        updateedAllFeeOverrides.splice(overrideIndex, 1);
        dispatch({
          type: UPDATE_FEES_OVERRIDE_LIST,
          allFeeOverrides: updateedAllFeeOverrides,
        });
        // api.get(`/service-fees/${serviceFeeId}`).then((response) => {
        //   console.log('editingFee/afterDelete', response.data.data);
        // });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Service fee override deleted successfully.',
          })
        );
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error?.response?.data?.errors?.message || 'An error occurred trying to create service fee.',
          })
        );
        dispatch({ type: GET_REQUEST_FAILURE, error });
      });
  };
}
