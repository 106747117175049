import React from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import { Icon } from 'merchant-portal-components';
import PaginationControl from '../../../components/Pagination/PaginationControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import { TooltipIcon } from '../../../components/Icon';
import MiniToggleSwitch from '../../../components/ToggleSwitchSmall/ToggleSwitchSmall';
const OfferImage = styled.img`
  width: 100px;
  object-fit: contain;
  background-color: #d3d3d3;
`;

const TextSearch = styled.div`
  // padding: 20px;
`;

const PaginationWrapper = styled.div`
  padding: 25px 37px;
  display: flex;
  justify-content: right;
`;
const WrapperPopup = styled.div``;
const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  display: grid;
  grid-gap: 20px;
`;

const SortWrapper = styled.div`
  font-size: 12px;
  letter-spacing: -0.02px;
  color: #191818;
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;

const toCurrency = (num) => {
  if (typeof num == 'number') {
    return num.toFixed(2);
  } else return num;
};
const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: left;
  padding-left: 0px;
`;

const SortWrappers = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;
const OffersEngineList = ({
  allOffers,
  searchString,
  setSearchString,
  requestParams,
  getOffersEngine,
  setIsSortModalOpen,
  toggleOfferAvailability,
  initiateCreateOffer,
  initiateEditOfferEngine,
  requestingGetOffers,
  setIsCreateOfferTypeOpen,
}) => {
  const renderHeaders = () => {
    return (
      <TrHead>
        <Th align='left' />
        <Th
          align='left'
          // onClick={() => {
          //   handleSort({
          //     value: 'offer_details.title',
          //     sortMethod: sortByTypes,
          //     sortType: STRING,
          //   });
          // }}
          value='offer_details.title'
          sortingOption={requestParams.sorting_option}
          // isSortable='true'
        >
          Name
        </Th>
        <Th
          align='left'
          style={{ minWidth: '150px' }}
          // onClick={() => {
          //   handleSort({
          //     value: 'offer_details.start_date',
          //     sortMethod: sortByTypes,
          //     sortType: STRING,
          //   });
          // }}
          value='offer_details.start_date'
          sortingOption={requestParams.sorting_option}
          // isSortable='true'
        >
          Start Date
        </Th>
        <Th
          align='left'
          // onClick={() => {
          //   handleSort({
          //     value: 'offer_details.template_values.expiry_date',
          //     sortMethod: sortByTypes,
          //     sortType: DATE,
          //   });
          // }}
          value='offer_details.template_values.expiry_date'
          sortingOption={requestParams.sorting_option}
          // isSortable='true'
        >
          End Date
        </Th>
        <Th
          align='left'
          // onClick={() => {
          //   handleSort({
          //     value: 'offer_details.is_active',
          //     sortMethod: sortByTypes,
          //     sortType: BOOLEAN,
          //   });
          // }}
          value='offer_details.is_active'
          sortingOption={requestParams.sorting_option}
          // isSortable='true'
        >
          Active
        </Th>
      </TrHead>
    );
  };

  // render headers end

  // render rows start
  const renderRows = () => {
    if (allOffers.length > 0) {
      return allOffers.map((offer, index) => (
        <Tr
          onClick={() => initiateEditOfferEngine(offer?.offer_id)}
          key={index}
          style={{ cursor: 'pointer' }}
        >
          <Td>
            <OfferImage src={offer?.image_url} />
          </Td>
          <Td>{offer?.title}</Td>
          <Td>
            <div>{offer?.start_date}</div>
          </Td>
          <Td>{offer.end_date}</Td>
          <Td
            onClick={(event) => {
              event.stopPropagation();
            }}
            align='left'
            style={{ position: 'relative' }}
          >
            <MiniToggleSwitch
              checked={offer.is_active}
              onChange={(event) => {
                event.stopPropagation();
                toggleOfferAvailability(offer);
              }}
            />
          </Td>
        </Tr>
      ));
    } else {
      return (
        <Tr>
          <Td colSpan='6' padding='50px 0' align='center'>
            No matching data{' '}
          </Td>
        </Tr>
      );
    }
  };

  return (
    <>
      <WrapperPopup>
        <SortDiv>
          <SortWrappers onClick={() => setIsSortModalOpen(true)}>
            <i className='far fa-sort-amount-down' /> Reorder Offer Position on
            App/Web
            <Tooltip
              style={{ marginRight: 'auto' }}
              title='Edit the position of offers to change the order that they are presented in to the user'
            >
              <span>
                <TooltipIcon marginLeft />
              </span>
            </Tooltip>
          </SortWrappers>
        </SortDiv>
      </WrapperPopup>
      <Wrapper>
        <TableWithButtonRow>
          <TopDiv>
            <TextSearch>
              <TextField
                type='text'
                style={{ width: '100%' }}
                variant='outlined'
                value={searchString}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon className='fal fa-search' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Icon
                        className='fas fa-times-circle'
                        style={{ color: '#193053' }}
                        onClick={() => setSearchString('')}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder='Search offers'
              />
            </TextSearch>
            <Button onClick={() => setIsCreateOfferTypeOpen(true)}>
              Create Offer
            </Button>
          </TopDiv>
          <TableWrapper noShadow>
            {requestingGetOffers && <LoadingBlueLarge />}
            <Table noBorder>
              {!requestingGetOffers && <thead>{renderHeaders()}</thead>}
              {!requestingGetOffers && <TBody striped>{renderRows()}</TBody>}
            </Table>
          </TableWrapper>
        </TableWithButtonRow>
        {!requestingGetOffers && (
          <PaginationWrapper>
            <PaginationControl
              response={{
                ...allOffers,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: allOffers?.length,
              }}
              requestParams={requestParams}
              updateAction={getOffersEngine}
              pageInterval={[
                { value: 10, label: '10' },
                { value: 25, label: '25' },
                { value: 50, label: '50' },
              ]}
              type='offers'
            />
          </PaginationWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default OffersEngineList;
